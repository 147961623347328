@import '../../../utils/variables';

@font-face {
    font-family: 'Neue Helvetica Extended Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Extended Oblique'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 53 Extended Oblique.woff') format('woff');
}
@font-face {
    font-family: 'Neue Helvetica Extended';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Extended'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 53 Extended.woff') format('woff');
}
@font-face {
    font-family: 'Neue Helvetica Roman';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Roman'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 55 Roman.woff') format('woff');
}
@font-face {
    font-family: 'Neue Helvetica Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Italic'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 56 Italic.woff') format('woff');
}
@font-face {
    font-family: 'Neue Helvetica Condensed Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Condensed Oblique'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 57 Condensed Oblique.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Condensed';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Condensed'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 57 Condensed.woff') format('woff');
}
@font-face {
    font-family: 'Neue Helvetica Extended Ultra Light Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Extended Ultra Light Oblique'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 23 Ultra Light Extended Oblique.woff') format('woff');
}
@font-face {
    font-family: 'Neue Helvetica Extended Ultra Light';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Extended Ultra Light'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 23 Ultra Light Extended.woff') format('woff');
}
@font-face {
    font-family: 'Neue Helvetica Ultra Light';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Ultra Light'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 25 Ultra Light.woff') format('woff');
}
@font-face {
    font-family: 'Neue Helvetica Ultra Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Ultra Light Italic'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 26 Ultra Light Italic.woff') format('woff');
}
@font-face {
    font-family: 'Neue Helvetica Condensed Ultra Light Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Condensed Ultra Light Oblique'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 27 Ultra Light Condensed Oblique.woff') format('woff');
}
@font-face {
    font-family: 'Neue Helvetica Condensed Ultra Light';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Condensed Ultra Light'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 27 Ultra Light Condensed.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Extended Thin Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Extended Thin Oblique'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 33 Thin Extended Oblique.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Extended Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Extended Thin'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 33 Thin Extended.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Thin'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 35 Thin.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Thin Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Thin Italic'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 36 Thin Italic.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Condensed Thin Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Condensed Thin Oblique'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 37 Thin Condensed Oblique.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Condensed Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Condensed Thin'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 37 Thin Condensed.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Extended Light Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Extended Light Oblique'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 43 Light Extended Oblique.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Extended Light';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Extended Light'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 43 Light Extended.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Light';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Light'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 45 Light.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Light Italic'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 46 Light Italic.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Condensed Light Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Condensed Light Oblique'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 47 Light Condensed Oblique.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Condensed Light';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Condensed Light'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 47 Light Condensed.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Extended Medium Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Extended Medium Oblique'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 63 Medium Extended Oblique.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Extended Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Extended Medium'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 63 Medium Extended.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Medium'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 65 Medium.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Medium Italic'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 66 Medium Italic.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Condensed Medium Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Condensed Medium Oblique'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 67 Medium Condensed Oblique.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Condensed Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Condensed Medium'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 67 Medium Condensed.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Extended Bold Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Extended Bold Oblique'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 73 Bold Extended Oblique.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Extended Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Extended Bold'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 73 Bold Extended.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Bold'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 75 Bold.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Bold Italic'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 76 Bold Italic.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Condensed Bold Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Condensed Bold Oblique'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 77 Bold Condensed Oblique.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Condensed Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Condensed Bold'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 77 Bold Condensed.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Extended Heavy Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Extended Heavy Oblique'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 83 Heavy Extended Oblique.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Extended Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Extended Heavy'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 83 Heavy Extended.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Heavy'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 85 Heavy.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Heavy Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Heavy Italic'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 86 Heavy Italic.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Condensed Heavy Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Condensed Heavy Oblique'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 87 Heavy Condensed Oblique.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Condensed Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Condensed Heavy'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 87 Heavy Condensed.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Extended Black Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Extended Black Oblique'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 93 Black Extended Oblique.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Extended Black';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Extended Black'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 93 Black Extended.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Black';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Black'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 95 Black.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Black Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Black Italic'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 96 Black Italic.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Condensed Black Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Condensed Black Oblique'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 97 Black Condensed Oblique.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Condensed Black';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Condensed Black'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 97 Black Condensed.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Condensed Extra Black Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Condensed Extra Black Oblique'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 107 Extra Black Condensed Oblique.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Condensed Extra Black';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Condensed Extra Black'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 107 Extra Black Condensed.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Outline Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Helvetica Outline Bold'), url('#{$resources-path}/fonts/HelveticaNeue/Helvetica Neue LT 75 Bold Outline.woff') format('woff');
}