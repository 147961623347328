
.nasdaq{

    .newItemsMain {
       height: 100%;
       width: 100%;
       white-space: nowrap;
   }

   .newItemsMain .bv-slider > ul {
       text-align: center;
       min-width: 880px;
       min-height: 435px;
   }

   .newItemsMain .bv-slider > ul > li{
       box-shadow: none !important;
       margin: 20px 1.4% !important;
       width: 30.4% !important;
   }

   .newItemsMain .bv-slider .bv-slider-items-2 > li {
       width: 48% !important;
   }/*
   @media screen and (max-width: 900px) {
       .newItemsMain .bv-slider > ul > li {
           width: 47% !important;
       }
   }

   @media screen and (max-width: 700px) {
       .newItemsMain .bv-slider > ul > li {
           width: 92% !important;
       }
   }

   @media screen and (max-height: 700px) {
       .newItemsMain .bv-slider > ul > li {
           height: calc(100% - 200px) !important;
           width: 23%;
       }
   } */

   .newItemsList{
       height: 100%;
       width: 100%;
       /* background: #fff; */
       z-index: 1;
   /*	border-right: 1px solid #DDDDDD;*/
       float: left;
       display: inline-block;
   /*	padding-left: 100px;
       padding-right: 100px;*/
   }

   .newItemsGrid {
       /*border: 1px solid #D4D4D4;*/
       width: 100%;
       height: 100%;
       overflow: hidden;
   }

   .newItemsGrid:hover {
       overflow-y: scroll;
   }

   .newItemsGrid:hover li.sharedListItemCell {
       padding-right:10px;
   }

   .newItemsGrid li:hover {
       background: #F0F0F0;
   }

   .new-item-is-selected {
       background: #F0F0F0 !important;
   /*	color: #fff;*/
   }


   .newItemsMoreBtnRow {
       margin: 0px 11px 0 5px;
       float: right;
   }

   .newItemsMoreBtn {
       color:#FFFFFF;
       min-width:50px;
   /* 	background-color: #036EAA; */
       border-style:none;
       max-height: 45px;
       min-height: 35px;
       margin: 5px 0 0 5px;
   }

   .newItemsMoreBtn:disabled {
       background-color: #DDDDDD;
   }

   .newItemDetails {
       height: 99%;
       width: 100%;
       float: right;
       background: #fff;
       display: inline-block;
       z-index: 2;
   }

   li.newListItemCell {
        white-space: nowrap;
        border-bottom: 1px solid #D1D1D1;
        height:90px;
        padding-right: 18px;
        overflow: hidden;
   }

   /*li.sharedListItemCell:nth-child(odd) {
       background: #ECECEC;
   }*/

   .newListCellItem {
       margin: 5px 0px 0px 0px;
   }

   .newListLeftItems {
       float: left;
       width: 58%;
       text-align: left;
       margin: 5px 0 0 0;
       display: inline-block;
       font-size: 13px;
       max-width: 600px;
       overflow: hidden;
   }

   .newItemsListMsgInfo {
       width: 62%;
       /* text-align: right; */
       margin: 7px 0 0 0;
       display: inline-block;
       font-size: 12px;
       max-width: 600px;
       overflow: hidden;
   }

   .newListRightItems {
   /*	float: right;*/
       width: 55%;
       text-align: right;
       margin: 5px 0px 0px 5px;
       display: inline-block;
   }

   .newItemsListTopItems {
       width: 100%;
       display: inline-block;
       white-space: nowrap;
   }

   .newItemCreatedBy {
       font-weight: 600;
   }

   .newItemCreatedOn {
       font-size: 11px;
   }

   .newListFooter {
       width: 30%;
       text-align: left;
   }

   .newItemHeader {
       width: 100%;
       background: white;
       position: relative;
       min-height: 110px;
       border-bottom: 1px solid #DDDDDD;
   }

   .newItemRow {
       height: auto;
       padding: 5px 0 0 0;
       position: relative;
       display: flex;
   }

   .newItemHeaderLabel {
       text-align: right;
       width: 60px;
       float: left;
       position: relative;
   }

   .newItemHeaderValue {
       text-align: left;
       /*width: 90%;*/
       padding: 0 0 0 5px;
       font-weight: 700;
       float: right;
       white-space: normal;
   }

   .newItemComments {
       /* height: calc(100% - 254px); */
   /*	height: -moz-calc(100% - 254px);
        height: -webkit-calc(100% - 254px);
       height: -o-calc(100% - 254px);*/
       margin: 5px 0px 0 0px;
   /*	width: 100%;*/
       overflow: hidden;
       padding-right: 10px;
       padding-left: 10px;
       float: right;
       width: 70%;
       display: inline-block;
       height: 100px;
   /*	border-left: 1px solid #D1D1D1;*/
   }

   .newItemComments:hover {
       overflow-y: auto;
       padding-right: 10px;
   }

   .newItemsAttachmentRow {
       margin: 10px 0 0 0px;
       text-align: left;
       /* max-width: 338px; */
       overflow: hidden;
       white-space: nowrap;
       /* position: absolute; */
       /* border-top: 1px solid #DDDDDD; */
       padding-right: 10px;
       display: inline-block;
       /* width: 25%; */
       width: 40%;
       /* text-align: right; */
       /* margin: 5px 0px 0px 5px; */
       display: inline-block;
   /*	background: green;*/

   }

   .newItemsAttachmentRow:hover {
       overflow-x: auto;
   }

   .new-items-message-attachment {
   /* 	margin: 0 0px 0 0px;
       padding-bottom: 4px;
       width: 100%; */
       /* background: green; */
       height: 100%;
   }

   .new-items-attachment-image {
       width: 60px;
       height: 67px;
       margin: 0 0px 0px 0;
   }

   .new-items-attach-portal-name{
       color:#868585;
       font-size: 12px;
       text-align:center;
       width: 100%;
       text-overflow: ellipsis;
       overflow: hidden;
       white-space: nowrap;
       margin-top:5px;
       font-size: 12px;
       display: none;
   }

   .new-items-attach-name{
       text-align:center;
       width: 100%;
       text-overflow: ellipsis;
       overflow: hidden;
       white-space: nowrap;
       margin-top:5px;
       font-size: 16px;
   }


   .new-items-attachment-row-in-card {
       height: calc(100% - 100px);
       /* margin: 0px 0 0 0px; */
       text-align: center;
   /* 	width: 206px; */
   /*	overflow: hidden;
       white-space: nowrap;*/
       /* position: absolute; */
       /* border-top: 1px solid #DDDDDD; */
       /* padding-top: 20px; */
   /*	position: relative;*/
   /* 	background: red; */
   /*	height: 100%;*/
   }

   /*.new-items-attachment-row-in-card:hover {
       overflow-x: auto;
   }*/

   .new-items-attachment-list {
       height: 100%;
   }

   .new-items-attachment-image-container {
       height: calc(100% - -25px);
       width: 100%;
       margin-bottom: 30px;
       position: relative;
       cursor: pointer;
       box-shadow: 0px 0px 3px 0px #6f6f6f;
       border-radius: 4px;
       display: inline-block;
       vertical-align: middle;
   }

   .legacy .new-items-attachment-image-container{
       box-shadow: 0px 0px 10px 0px #888888;
       border-radius: 0;
   }

   /*.new-items-attachment-image-container:before {
       content: '';
       display: inline-block;
       height: 100%;
       vertical-align: middle;
       margin-right: -0.35em; /* Adjusts for spacing
   }*/

   .new-items-attachment-image-in-card {
       /* width: 182px; */
       /* height: 270px; */
       /* margin: 10px 0px 0px 0; */
       max-height: 100%;
       max-width: 100%;
       width: auto;
       /* position: relative; */
       /* box-shadow: 0px 0px 20px 0px #888888; */
       vertical-align: middle;
       display: inline-block;
   }

   .new-items-file-attachment-image {
       border: 1px solid #DDDDDD;
   }

   .newItemsMain .bv-slider-newItem  .remove-new-item-button{
    color: #aeaeae;
    font-weight: bold;
   display: none;
   font-size: 18px;
   }
   .newItemsMain.isDevice .bv-slider-newItem .remove-new-item-button{
    display: none !important;
   }

   .newItemsMain.notDevice .bv-slider-newItem .remove-new-item-button{
    display: block;
   }

   .remove-new-item-button[data-value='true'] {
    /* Attribute has this exact value */
    display: block ;
  }
  .remove-new-item-button[data-value='false'] {
    /* Attribute has this exact value */
    display: none !important;
  }
   .remove-new-item-button {
       width: 15px;
       height: 15px;
       position: absolute;
       cursor: pointer;
       top: 10px;
       right: 10px;
   }

   .remove-new-item-button > svg{
       fill: #aeaeae;
   }

   .remove-new-item-button:hover > svg{
       fill: #231f20;
   }
   .ngViewport.ng-scope {
       overflow-y: hidden;
   }

   .ngViewport.ng-scope:hover {
       overflow-y: auto;
   }


   /* User Avatar */

   .newItemsUserAvatar {
       margin: 15px 10px;
       /* height: 50px; */
       /* float: left; */
       /* white-space: nowrap; */
       display: inline;
   }


   .newItemsUserImage {
       text-align: center;
       float: left;
       width: 50px;
       height: 50px;
       position: relative;
       display: block;
       background: none;
       z-index: 1;
   }

   .newItemsUserListCircle {
       text-align: center;
       width: 50px;
       height: 50px;
       overflow: hidden;
       border-radius: 50%;
       box-shadow: rgb(204, 204, 204) 0px 0px 6px 1px;
       background: rgb(81, 81, 81);
       display: inline-block;
   }

   .newItemsUserFirstLetter {
       width: 50px;
       margin-top: 8px;
       color: white;
       font-weight: lighter;
       font-size: 25px;
       letter-spacing: 1px;
       display: block;
   }

   /************************************/

   .folderViewDialog .modal-dialog {
       min-width: 900px;
       overflow: hidden;
   }
    
   &.bv-mobile .folderViewDialog .modal-dialog .bv-modal-btn-close svg{
    display: block;
    fill: #fff;
   }
    &.bv-mobile .folderViewDialog .modal-dialog .bv-modal-btn-close {
    background: #454957;
    height: 44px;
   }

   /*********** Add to new Items *************/

   .addToNewItemsDialog .modal-dialog {
       width: 370px;
       overflow: hidden;
       position: absolute;
       top: 50%;
       left: 50%;
       transform: translate(-50%,-50%) !important;
       -webkit-transform: translate(-50%,-50%) !important;
       -ms-transform:  translate(-50%,-50%) !important;
       margin: 0;
   }

   .addToNewItemsDialog .bv-input-btn-0 {
     background-color: #000;
   }

   .add-new-items-main {
       min-height: 490px;
       padding: 20px;
       margin-top: -3px;
   }

   .attachmentLbl {
       border-bottom: 1px solid #e5e5e5;
       font-weight: 500;
       width: 100%;
       padding-bottom: 5px;
   }

   .add-new-items {
       width: 100%;
       height: 420px;
       overflow-x: hidden;
       overflow-y: hidden;
       margin: 10px 0 10px 0;
   }

   .add-new-items:hover {
       overflow-y: auto;
   }

   .add-new-list-item {
       white-space:nowrap;
       margin:10px 0 0 0;
   }

   .add-new-item-attachment-row {
       width: 100%;
       height: 60px;
       display: inline-block;
       overflow: hidden;
       margin: 10px 0 10px 0;
   /*	cursor: pointer;
   */	padding-right: 10px;
   }
   /*
   .add-new-item-attachment-row:hover {
       background-color: #f4f4f4;
   }*/
   .add-new-item-attachment-row:hover .drag-handle-image {
       display: block;
   }
   .add-new-item-attachment-row:hover .add-new-item-attachment-remove-btn
   {
       fill:#D3D3D3;
       visibility:visible;
   }

   .add-new-item-attachment-image {
       width: 50px;
       height: 60px;
       margin: 0 10px 0 0;
       cursor: pointer;
   }
   .add-new-item-attachment-image div,
   .add-new-item-attachment-image img{
       width: 45px;
       height: 55px;
   }
   .add-new-item-attachment-name {
       width: 200px;
   /*	line-height: 60px;
   */	text-overflow: ellipsis;
       overflow: hidden;
       white-space: nowrap;
       margin-top: 20px;
   }
   .add-new-item-attachment-name:hover {
       cursor: pointer;
       text-decoration: underline;
   }

   .add-new-item-attachment-remove {
       height: 100%;
   }

   .add-new-item-attachment-remove-btn {
       width: 17px;
       height: 17px;
       position: relative;
       cursor: pointer;
       margin-top: 22px;
       visibility:hidden;
   }
   .add-new-item-attachment-remove-btn:hover
   {
       visibility:visible !important;
       fill:black !important;
   }

   .add-new-item-expiry {
       width: 160px;
   }

   .expiryLbl {
       font-weight: 500;
       width: 100%;
       padding-bottom: 5px;
   }
   /******************************/



   /*** New Items Cards ***/


   .bv-newitems-cards{
       outline:0;
       font-size: 16px;
   }
   .bv-newitems-cards ul[rn-carousel]{
       width:33.3%;
       overflow:inherit;
   }
   .bv-newitems-cards ul[rn-carousel] li>div>div>ul,
   .bv-newitems-cards ul[rn-carousel] li>div{
       padding:10px;
   }
   .bv-newitems-cards ul[rn-carousel] li>div>div{
       /* padding: 10px; */
       /* border: 1px solid #ccc; */
       /* background: #F5F5F5; */
       /*box-shadow: 0px 0px 5px 1px #ddd;*/
       /* border-radius: 10px; */
       overflow: hidden;
   }

   .bv-newitems-cards ul[rn-carousel] li>div>div>title{
       display: block;
       font-size: 2em;
       font-weight: 500;
       line-height: 27px;
       margin-bottom:30px;
   }
   .bv-newitems-cards ul[rn-carousel] li>div>div>title:hover{
       text-decoration: underline;
       cursor:pointer;
   }
   .bv-newitems-cards ul[rn-carousel] li>div>div>label{
       display: block;
       font-size: 1em;
       line-height: 10px;
   }
   .bv-newitems-cards ul[rn-carousel] li>div>div>info{
       font-size: 0.9em;
       margin-bottom: 23px;
       display: block;
   }

   .bv-newitems-cards-module ul[rn-carousel] li>div{
       padding:5px;
   }
   .bv-newitems-cards-module ul[rn-carousel] li>div>div>title{
       font-size: 1.5em;
       line-height: 20px;
       margin-bottom: 15px;
       height: 41px;
       text-overflow: ellipsis;
       overflow: hidden;
   }
   .bv-newitems-cards-module ul[rn-carousel] li>div>div>info{
       font-size: 0.8em;
       margin-bottom: 13px;
   }



   /* .bv-newitems-cards ul[rn-carousel] li>div>div>type{
       font-size: 1.1em;
   }
   .bv-newitems-cards ul[rn-carousel] li>div>div>line{
       background: rgb(169, 109, 29);
       height: 6px;
       width: 100%;
       display: block;
   } */
   /* .bv-newitems-cards ul[rn-carousel] li>div>div>ul li{
       padding: 15px 0px;
   }
   .bv-newitems-cards ul[rn-carousel] li>div>div>ul li>title{
       display: block;
       font-size: 1.4em;
       font-weight: 500;
   }
   .bv-newitems-cards ul[rn-carousel] li>div>div>ul li>info{
       font-size: 0.9em;
   } */
   .bv-newitems-cards div[rn-carousel-indicators]{
       position: absolute;
       bottom: 20px;
       width: 100%;
       text-align: center;
   }
   .bv-newitems-cards div[rn-carousel-indicators] span{
       margin: 0 2px;
   }

   /***************************/


   .new-items-prev {
       position: absolute;
       top: 0;
       width: 100px;
       text-align: center;
       height: 100%;
   }

   .new-items-next {
       position: absolute;
       top: 0;
       width: 100px;
       right:0;
       text-align: center;
       height: 100%;
   }
   .newItemList{
       display: none;
   }

   .newItemListContent ul li {
       height: 57px;
       padding: 10px;
       border-bottom: 1px solid #eaeaeb;
       font-size:13.5px;
   }
   .newItemListContent ul li .item {
       display: inline-block;
       vertical-align: top;
   }
   .newItemListContent ul li .item span{
       display: block;
   }
   .newItemListContent ul li .item.type [icon-renderer]{
       width:25px;
       height:31px;
       overflow:hidden;
   }
   .newItemListContent ul li .item:nth-child(1) {
       margin-left: 10px;
   }
   .newItemListContent ul li .item:nth-child(2) {
       margin-left: 28px;
       width: 100%;
       max-width: 44%;
   }
   .newItemListContent ul li .item:nth-child(3) {
       width: 25%;
   }






     
  .carousel-prev {
    position: absolute;
    top: calc(50% - 45px);
    width: 46px;
    height: 45px;
    left: -50px;
  }
  
  .carousel-next {
    position: absolute;
    top: calc(50% - 45px);
    right: -20px;
    width: 50px;
    height: 45px;
     }

.bv-slider-newItem {
    margin: 0 33px 0 45px;
    height: 100%;
}
.bv-slider-newItem  button {

    background: transparent;
    color: black;
    border: none;
    font-size: 40px;
    width:100% ;
    height: 100%;
    display: block;
}

.bv-slider-newItem  button svg {


    width:100% ;
    height: 100%;
    
}

.newItemsMain.showPortal-name .bv-slider-newItem  .new-items-attach-portal-name{
    display: block;
}
  
  .v-middle, .ui-carousel .carousel-btn {
    display: block;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }
  


  
  .ui-icon-prev:before {
    content: "a";
  }
  
  .ui-icon-next:before {
    content: "b";
  }
  
  .ui-icon-dot:before {
    content: "c";
  }
  
  .ui-carousel {
    display: block;
    margin-bottom: 30px;
  //  min-width: 880px;
    min-height: 435px;
    height:100%;

  }
  
  .ui-carousel .carousel-wrapper {
    position: relative;
    height:100%;
  }


  .carousel-image-in-card img{
    height: 100%;
    max-width: 100%;
    width: auto;
    vertical-align: middle;
    display: inline-block;
   
    margin-left: auto;
    margin-right: auto;
    display: block;
    top: 50%;
    position: relative;
    transform: translateY(-50%);
    text-align: center;
}
.carousel-image-in-card {
    height: 100%;
    max-width: 100%;
    width: auto;
    vertical-align: middle;
    display: inline-block;
   
    margin-left: auto;
    margin-right: auto;
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
}
  

.carousel-image-in-card > div,
.carousel-image-in-card > div > svg,
.carousel-image-in-card boardbook > svg{
    width: 100%;
    height: 100%;
}
.carousel-image-in-card img{
    height: auto;
    max-height: 100%;
}

.carousel-image-in-card img.txt {
    object-fit: cover;
    height: 100%;
    width: 100%;
}
.carousel-image-in-card > div > div > svg{
    width: 100%;
    height: 100%;
}



  .ui-carousel .track-wrapper {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 100%;
  }
  
  .ui-carousel .track {
    position: relative;
    display: block;
    float: left;
    height: 100%;
    margin: 0 5px;
  }
  
  .ui-carousel .slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: block;
  }
  .ui-carousel .slide .carousel-item{
    width: 100%;
    float: left;
    padding: 25px 30px 35px 0;
    height:100%;
  }
  .ui-carousel .carousel-btn {
    position: absolute;
    z-index: 10;
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 20px;
    opacity: 0.75;
  }
  
  .ui-carousel .carousel-btn:hover {
    opacity: 1;
  }
  
  .ui-carousel .carousel-prev .carousel-btn {
    left: -25px;
  }
  
  .ui-carousel .carousel-next .carousel-btn {
    right: -25px;
  }
  
  .ui-carousel .carousel-disable {
    opacity: 0.5;
    display: none;
  }
  
  .ui-carousel .carousel-disable .carousel-btn:hover {
    opacity: 0.75;
  }
  
  .carousel-dots {
    position: absolute;
    bottom: -30px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }
  
  .carousel-dots li {
    position: relative;
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
  }
  
  .carousel-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 15px;
    height: 15px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }
  
  .carousel-dots li button:before {
    font-family: ui-carousel;
    font-size: 9px;
    line-height: 15px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 15px;
    height: 15px;
    content: "c";
    text-align: center;
    opacity: 0.25;
    color: black;
    -webkit-font-smoothing: antialiased;
  }
  
  .carousel-dots li.carousel-active button:before {
    opacity: 0.75;
  }
}