@import 'src/utils/variables';
.list-view-main{
        height: calc(100% - 50px);

        .listviewclose {
            display: none;
        }
}
listview{
    .bv-cards-content{
        overflow: hidden;
        position: absolute;

        > div{

            .bv-cards-title{
                font-size: 25px;
                font-family: $nasdaqFontSemibold;
                padding: 0 14px;
                color: $grayDarkest;
            }

            .bv-events-list{
                color: $grayDarker;
                padding: 0 8px;

                .meetingPortalNameStyle{

                    .event-bar{
                    height: 68px !important;
                    margin-top: 2px;
                    }
                   
                }
                .editOwner{
                    .bv-events-list-name {
                        width: 88%;
                   }
                }

                .bv-events-list-name{
                    font-size: 17.5px !important;
                }
                .bv-events-list-portalName{
                    font-size: 13px !important;
                }
                .bv-events-list-Edit{
                    width: 35px;
                    font-size: 11px;
                    cursor: pointer;
                    text-align: center;
                    padding: 2px 3px;
                    color: #0094B3;
                    border-radius: 3px;
                    border: 1px solid #0094B3;
                    position: absolute;
                    right: 0;
                    top: 25%;
                    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
                    background: #fff;
                }

                .bv-events-list-time{
                    line-height: 13px !important;
                    font-size: 13px;
                    color: $grayDark;
                }

                .scroll-list{
                    li{
                        margin-bottom: 12px !important;
                        padding-bottom: 8px;
                        border-bottom: 1px solid $grayLighter !important;

                        &:last-child{
                            border-bottom: none !important;
                        }

                        .event-active-bar-space{
                            margin-left: 12px;
                                width: 100%;
                        }

                        .event-bar{
                            height: 35px;
                            top: 0px;
                            left: 0px;
                            border-radius: 2px;
                            position: absolute;
                        }
                        .event-active-bar{
                            background: $nasdaqBlueHover;
                        }
                    }
                }



            }

            .bv-slider > ul > li{
                width:31.7%;
                margin: 20px 0.8%;

                >div {
                    padding: 14px 0 !important;
                }

                &.selectedCardShadow > div{
                    border-radius: 6px;
                }
            }

            .bv-calendar-line{
                padding-top: 0;
                height: 15px;

                div{
                    background: $grayLighter;
                    height: 1px;
                }
            }

        }
    }
}