@import 'src/utils/variables';

.nasdaq{

	.folderViewDialog {
		& .modal-dialog{
		& .documentsList{
			&.hideTreeClass{
				width:98% !important;
			}
		
		& .document-list-container{
				    height: calc(100% - 38px);	
				    ul{
					    &.document-list-ul{
					    	padding-bottom: 25px;
					    }
				    }
			}
		
		}
			
		}
	}
    [state="home.email"] .bvhome .rightMenu{
        float: right;
        position: static;
        line-height: normal;
    }

     [state="home.email"] .bvhome .rightMenu>ul>li{
        display: inline-block;
        padding: 12px 10px 13px 10px;
        margin: 0;
        position: static;
        
    }
     [state="home.email"] .bvhome .rightMenu li bt .iconText{
        color: #fff;
        font-size: 11.5px;
        font-weight: 200;
        position: static;
    }

     [state="home.email"] .bvhome .rightMenu > ul > li:last-child{
       background-color:#0094B3;
    }

    div[state="home.dashboard"] ~ .meeting-details-react .modal-dialog {
      top:112px !important;
     }
     
     @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) 
     {
         div[state="home.dashboard"] ~ .meeting-details-react .modal-dialog 
         {
          top:92px !important;
         }
     }
    
    div[state="home.adsearch"], div[state="home.reports.useractivity"], div[state="home.reports.userchanges"], div[state="home.reports.documentlist"], div[state="home.reports.documentactivity"], div[state="home.reports.documentpermissions"], div[state="home.reports.meetinglist"], div[state="home.reports.retentionpolicies"], div[state="home.reports.memberattendancetracking"], div[state="home.reports.groupattendancetracking"], div[state="home.reports.userbriefcasereport"], div[state="home.reports.syncedinformationreport"], div[state="home.reports.passwordexpiry"], div[state="home.reports.folderpermissions"],div[state="home.reports.userdetails"] {
        [home-sidebar]{
            display: none;
        }
        .bv-content{
            transition: none;
            margin-left: 0 !important;
        }

        .bv-middle-bar .search-box{
            display: block;
        }
    }

    [state="home.adsearch"] .bv-search-bar form{
        border-left: 1px solid #A6A7AB;
        width: 95% !important;
    }
	.rightClickMenu {
		min-height:100px;
	}
    .bvhome{
        position: relative;
        height: 100%;
        display: block;
        padding-top: $headerHight;
        // padding-bottom: 35px;

        header{
            height: $headerHight;
            display: block;
            margin-top: -$headerHight;
            position: absolute;
            width: 100%;
        }

        sidebar{
            position: relative;
            display: block;
            height:100%;
        }

        main{
            position: relative;
        }

        footer{
            height: 35px;
            background: $nasdaqBlue;
            clear: both;
            display: table-caption;
            caption-side: bottom;
            z-index: 2;
            bottom: 0;
            position: absolute;
            height: 0 !important;

            table,
            .team-space-box{
                display: none;
            }
        }

        [home-sidebar]{
        /* 	background:#fff; */
            height: 100%;
            width: 240px;
            float: left;
            z-index: 1;
            display: block;
            font-family: $nasdaqFontRegular;
        /*	box-shadow: -42px 0px 38px -50px #000 inset;*/

            .iconText{
                opacity: 1;
            }
        }

        .expand-button{
            cursor: pointer;
            float: left;
            padding: 10px 20px;
            margin: -10px -5px -10px -20px;

            .rect1,
            .rect2,
            .rect3{
                height: 2px;
                border-radius: 5px;
                background-color: #ffffff;
                margin: 5px 0;
            }

            .rect1{
                width: 20px;
            }
            .rect2{
                width: 16px;
            }
            .rect3{
                width: 12px;
            }
        }

        [home-sidebar]::before{
            content: "";
            //border-right: 1px solid $grayLight;
            position: absolute;
            width: 100%;
            background: $sidebarBackgroundColor;
            top: 44px;
            bottom: 0;
        }

        .shadowBox {
            position: absolute;
            top: 0;
            /* right: -20px; */
            left: 250px;
            width: 20px;
            height: 100%;
        /*	box-shadow: -5px 0px 40px -3px rgba(0,0,0,0.4);
            -webkit-box-shadow: 6px 0px 50px 0px rgba(0,0,0,0.4);
            -moz-box-shadow: -5px 0px 50px 0px rgba(0,0,0,0.4);*/
            z-index: 2;
        }

        [home-right-sidebar]{
            float: right;
            top: 0px;
            position:relative;
            z-index: 3;
            width: 58px;
            background: rgb(255, 255, 255);
        /* 	box-shadow: 42px 0px 38px -50px #000 inset; */
        }

        .bv-content{
            display: block;
            /* height: 100%; */
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 0px;
            right: 0px;
            z-index:2;
            background:#fff;
        /* 	margin-right: 58px; */
            margin-left: 240px;
        }

        .expandHigh>.nav{
            left: 0px;
        }
        .expandLow>.nav{
            left: 160px;
        }
        [home-sidebar].expandLow{
            width: 60px;
            .alldocuments {
                padding: 0 22px !important;
                border-top: 1px solid $sidebarItemBottomBorderColor !important;
               }
            .bv-menu .selected .alldocuments{
                padding-left: 18px !important;
            }
            .media-files {
                display:none;
               }
            .media-height-bt {
                height: 55px !important;
                }
            .media-height-li {
                height: 61px !important;
                }
            .boardvantage,
            .iconText{
                opacity: 0;
            }
        }
        [home-sidebar].expandHigh{
            width: $sidebarWidth;
        }
        [home-sidebar].expandHighSettings{
            width: 609px;
        }
        .bv-content.expandLow{
            margin-left:60px;
        }
        .bv-content.expandHigh{
            margin-left: $sidebarWidth;
        }
        .bv-content.expandHighSettings{
            margin-left: 609px;
        }
        .bv-content.bv-transition>content>.bv-transclude-content{
            overflow: hidden;
        }

        [home-right-sidebar].expandHigh{
            width: 240px;
        }
        
        [home-right-sidebar].expandLow{
            width: 70px;
        }

        .teamUserProfile.expandHigh{
            right:240px;
        }
        .expandHigh .portalUserList{
            width: 100%;
        }

        .bv-mask-sidebar{
            position: absolute;
            height: $middlebarHeight;
            width: 240px;
            background: $grayDarkestHeader !important
            /* z-index: 3; */
        }

        .bv-mask-transition{
            position: absolute;
            top: 0px;
            height: $middlebarHeight;
            z-index: 2;
           /*  left: 250px; */
        }

        ndaqlogo{
            width: 68px;
            height: 68px;
            display: block;
            margin: 0 auto;
            background: url(#{$resources-path}/assets/images/ndaqlogo.png);
        }

        .bv-menu{

            li:not([state-name="home.newitems"]):not([state-name="home.signatures"]).selected bt [icon-renderer] svg,
            li.selected bt [icon-renderer]::before{
                color: #fff;
                fill: #fff;
            }

            li[state-name="home.surveys"].selected bt{
                [icon-renderer]{
                    stroke: #fff !important;
                }

                [icon-renderer] circle{
                    fill: transparent;
                }

                [icon-renderer] circle.center{
                    fill: #fff;
                }
            }

            // li[state-name="home.signatures"]:not(.selected) bt{
            //     [icon-renderer] svg rect{
            //         fill: $grayLighter;
            //     }
            // }
            li[state-name="home.signatures"].selected bt{
                [icon-renderer] svg path,
                [icon-renderer] svg polygon,
                [icon-renderer] svg rect{
                    fill: #fff;
                }
            }

            li[state-name="home.newitems"].selected bt{
                [icon-renderer] svg path.newitems-cls-1{
                    fill: #fff;
                }
            }
            li[state-name="home.newitems"]:not(.selected) bt{
                [icon-renderer] svg path.newitems-cls-2{
                    fill: $sidebarBackgroundColor;
                }
            }
        }



        /* BV styles */
        bv-row.bv-wrapper{
            height:58px;
        }
        bv-cell.bv-sidebar{
        /* 	background: #FAFAFA; */
            width: 240px;
            box-shadow: -7px 0px 39px -4px #ccc inset;
            /* box-shadow: 2px 2px 10px 0px #ccc; */
        /* 	padding: 12px 0px; */
        }
        bv-cell.bv-sidebar wrapper{
            border:1px solid transparent;
        }


        /* start right side bar */
        bv-cell.home-right-sidebar{
        /* 	background: #FAFAFA; */
            width: 70px;
            box-shadow: -7px 0px 39px -4px #ccc inset;
            /* box-shadow: 2px 2px 10px 0px #ccc; */
        /* 	padding: 12px 0px; */
        }
        bv-cell.home-right-sidebar wrapper{
            border:1px solid transparent;
        }

        /*  end  */
        bv-cell.bv-wrapper{
            width: 1px;
            min-width: 1px;
        }
        .bv-content content{
            /* display: table; */
            position: relative;
            display:block;
            padding-top: $middlebarHeight;
            margin-left: 0px;
            min-width: 280px;
        }
        bv-row.bv-header{
            height: 100px;
        }
        .bv-content .bv-main-box{
            box-shadow: 2px 2px 10px 0px #ccc;
        /* 	background: #fff; */
            display: table-cell;
            position: relative;
            height:100%;
        }
        .bv-content .bv-main-box>div{
            overflow-y: auto;
            width: 100%;
            height:100%;
        /* 	position: relative; */
        /* 	top: 0px;
            bottom: 0px;  */
        }
        .bv-footer-menu{
            position: relative;
            float:right;
            top: 6px;
            margin-right:20px;
        }
        .bv-footer-menu li{
            display: inline;
            margin: 0px 0px 0px 35px;
            font-size: 11.2px;
            font-weight: 200;
            /*letter-spacing: 1.5px;*/
        }
        .bv-header-menu{
            position: relative;
            float: right;
            top: 18px;
        }
        .bv-header-menu li{
            display:inline;
            margin:0 20px;
            font-size: 1.11em;
            font-weight: 200;
            letter-spacing: 1.5px;
        }
        .bv-header-menu li a{
            color: #515151;
        }
        .bv-company-logo{
            width: 68px;
            height: 68px;
        /*    margin: 0px 23px; */
            display: table;
            float: left;
        /*	border: 1px solid #e5e5e5;*/
        }
        .bv-company-logo-mobile{
            display: none;
            margin: 0;
            width: 100%;
            height: 80px;
            // background: #474A53;
            padding: 6px;

            > div {
                height: 68px;
                margin: 0px auto;
            }
        }
        .bv-company-logo img{
            /* height: 60px; */
            height: 90px;
        /* 	width: 160px; */
            width: 184px;
        }
        /* .bv-company-logo>div{
            display: table-cell;
            vertical-align: middle;
        } */
        .bv-company-logo>div>img,
        .bv-company-logo-mobile>div>img{
            display: block;
            margin: 0 auto;
        }
        .filter-mobile-modules{
            display: none;
        }
        .bv-menu{
            position: absolute;
            overflow-y: hidden;
            overflow-x: hidden;
            bottom: 0px;
            top: $middlebarHeight;
            /*padding: 10px 0px 0px 0px;*/
            /*z-index:2;*/
            width: 100%;
        }

        // .expandLow.bv-transition .bv-menu{
        //     width: inherit;
        // }
        /*.expandLow.bv-transition .bv-menu:hover{
            overflow-y: hidden;
        }*/
        .bv-menu:hover{
            overflow-y: auto;
        }

        /*
        .bv-menu bt,
        .bv-topmenu bt{
            color: #515151;
            font-size: 20px;
        }
        */
        /* .bv-menu bt:active{
            background: red;

            }
         */
        .bv-topmenu bt{
            /* color:#fff; */
        }
        .bv-menu bt{
            height: 55px;
            display: table-cell;
            vertical-align: middle;
            padding: 0px 22px;
            white-space: nowrap;
            border-right: 1px solid transparent;

            svg{
                pointer-events: none;
            }
        }
        .bv-menu bt:hover {
            background-color : $sidebarBackgroundHoverColor;
            //border-right: 1px solid $grayLight;
        }
        .bv-menu .selected bt:hover{
            border-right: 1px solid transparent;
            background: none;
        }

        .bv-menu .selected bt{
            padding: 0px 22px 0px 18px;
        }

        .bv-menu li{
            height: $sidebarItemHeight;
            display: table;
            width: 100%;
            border-right: 1px solid transparent;
            font-size: 13.5px;
            letter-spacing: 0.1px;
            line-height: 32px;
            text-transform:uppercase;
            /*margin: 0px 0px 20px 0px;*/
        }
        .bv-menu li > bt{
            border-top: 1px solid $sidebarItemBottomBorderColor;
        }
        .bv-menu li.selected{
            border-left: 4px solid $blue;
        }
        .bv-menu li a>svg{
            display: inline-block;
            position: relative;
            top: 4px;
            left: -3px;
            width: 35px;
            height: 25px;
        }
        .bv-topmenu{
            /* border-bottom:2px solid #515151; */
        /* 	margin: 23px 0px 30px;	 */
            top: 9px;
            position: absolute;
            opacity:1;
            margin-left: 3px;
        }
        .bv-topmenu li{
            width: 79px;
            height: 30px;
            display: inline-block;
            text-align: center;
        }
        .bv-topmenu li bt{
            font-size: 13px;
            /*font-weight: 400;*/
            display: inline-block;
            height: 100%;
            white-space: nowrap;
            font-size: 10px;
            margin-top: 5px;
            margin-left: 0px;
            padding: 7px 4px;
            color: $grayLighter;
            font-size: 10.6px;
        }
        .bv-topmenu li bt.active{
            border-bottom: 4px solid;
            border-bottom-color: #fff;
            color: #fff;
        }
        .bv-topmenu li bt:hover{
        /*	font-weight: 500;
            border-bottom: 6px solid
        */
        }
        .bv-setting-menu{
          
            // width: 230px;
        }
        .bv-search-bar{
            position: relative;
            white-space: nowrap;
            overflow: hidden;
            height: 68px;
            margin-right: 90px;

            &.admin{
                /*margin-right: 230px;
                float: right;*/
            }

            &.disabled{
                border-left: 0px;
                border-right: 0px;

                form{
                   display: none;
                }
            }

            form{
                width: 100%;
                width: 111px;
                display: block;
                float: right;
                height: 68px;
                position: relative;

                label > span{
                    top: -1px;
                    margin-left: 43px;
                }

                &:focus,
                &:focus-within,
                &.not-empty{
                    border-left: 1px solid #A6A7AB;
                    width: 95%;
                }

                img{
                    left: 15px;
                }

                input{
                    padding-left: 42px;
                    margin: 0;
                }
            }
        }

        .bv-search-bar input{
            height: 68px;
            background: transparent;
            border: none;
            width: 100%;
            font-size: 17px;
            font-weight: 200;
            letter-spacing: 1px;
            padding-left: 5px;
            margin-left: 42px;
        }

        .bv-search-bar img{
            width: 16px;
            position: absolute;
            left: 20px;
            top: 28px;
        }

        .bv-company-name{
            color: #515151;
            font-size: 1.6em;
            font-weight: 300;
            letter-spacing: 1.7px;
            position: relative;
            top: 3px;
            left: 20px;
        }
        .bv-team-space {
            margin-left: 60px;
            margin-right: 50px;
            border: 1px solid #A6A7AB;
            position: relative;
            width: 200px;
            border-radius: 3px;
            top: 20px;
            height: 28px;
            float: left;
        }

        .bv-team-space-label:hover {
            cursor:pointer;
        }

        .bv-team-space .bv-team-space-dropdown {
            max-height: 400px;
            overflow-x: hidden;
            overflow-y: auto;
            /*position: fixed;*/
            top: 23px;
            left: -194px;
            width: inherit;
        }

        .bv-team-space > div,
        .bv-team-space-footer{
            position: relative;
            left: 0px;
        /*	color: #515151;
        */	font-size: 12px;
            font-weight: 200;
            letter-spacing: 0.2px;
            display: flex;
            display: -webkit-flex;
            padding: 4px 10px;
        }
        .bv-team-space > div > div{
            max-width: 542px;
            min-width: 180px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            float: left;
        }

        .bv-team-space-footer>div{
            display: inline;
        }
        [home-header] .dropdown>bt,
        .bv-team-space-footer .dropdown>bt{
            display: inline-block;
            margin-left: -8px;
            position: relative;
            top: -1px;
            border-top: 6px solid $logoColor;
            border-right: 5px solid transparent;
            border-left: 5px solid transparent;
            pointer-events: none;
        }
        .bv-team-space bt svg,
        .bv-team-space-footer bt svg{
            width: 18px;
            height: 12px;
        }
        .bvTitleExtended{
            height: 90px !important;
            padding: 33px 10px 0px !important;
        }
        .tab-content{
            display:none;
            position:absolute;
        }
        .settingsMenu{
            clear: right;
            float: right;
            font-size: 12px;
            height: 100%;
        }
        .settingsMenu > ul > li{
            padding: 0 15px;
            display: block;
            float: left;
            height: 68px;
            border-left: 1px solid #A6A7AB;
            cursor: pointer;

            &:hover{
               background: #F4F4F5; 
            }

            &.admin{
                width: 140px;
                padding: 0;
                text-align: center;

                span{
                    color: $nasdaqBlue;
    
                    a{
                        color: $nasdaqBlue;
                    }
                }
                
                bt{
                    display: block;
                    top: 23px;
                    position: relative;
                    font-size: 14px;

                    .icon{
                        margin:0 auto;
                        height: 22px;
                        width: 22px;
                        display: block;
                    }
                }
            }
            &.support{
            	    padding: 10px 20px;
    				line-height: 56px;
            
            	& .support-icon{
	           		 &:before{
	            	color: rgb(71, 74, 83);
			        font-size: 24px;
	            	}
		      }
            }
            

            &.user-profile{
                padding: 18px 30px 18px 27px;
                position: relative;

                div.user-image{
                    position: relative;
                    width: 32px;
                    height: 32px;

                    &:before{
                        font-family: "SSGlyphish";
                        position: absolute;
                        font-weight: 400;
                        content: "👤";
                        color: #96979c;
                        left: 0;
                        top: -2px;
                        font-size: 32px;
                    }
                    img{
                        width: 34px;
                        height: 34px;
                        top: -1px;
                        left: -1px;
                    }
                }

                .dropdown{
                    top: 26px;
                    right: 15px;
                    outline: none;
                    position: absolute;
                }

                .dropdown-menu{
                    left: -300px;
                    top: 50px;
                    width: 310px;
                    border-radius: 7px;
                    max-height: none;

                    li{
                        height: 50px;
                        cursor: pointer;
                        color: black;
                        
                        &:not(:first-child):hover{
                            background: #f5f5f5;    
                        }

                        &:not(:last-child){
                            border-bottom: 1px solid rgba(213, 214, 216, 0.5);
                        }

                        &:first-child{
                            bt{
                                span:first-child{
                                    color: $gray;
                                }
                                span:last-child{
                                    color: $grayDark;
                                }
                            }
                        }

                        bt{
                            color: $grayDarkest;
                            padding: 15px 20px;
                            
                            &:hover{
                                background: none;
                            }
                        }
                    }
                }
            }
        }
        
        .settingsBtnIcon{
            fill: #515151;
        }
        /* .bv-content content>div{
            display: table-caption;
        } */
        .rightMenu{
            position: absolute;
            right: 5px;
            top: 18px;
            right: 20px;
            top: 4px;
            line-height: 45px;
            height: 40px;
        }

       
        .rightMenuExtended{
            position: absolute;
            right: 5px;
            top: 18px;
        }
        .rightMenu>ul{
            max-height: 40px
        }
       
        .rightMenu>ul>li{
            display: inline;
            margin: 0px 0px 0px 20px;
        }
      
        .rightMenu svg{
            width: 20px;
            height: 20px;
            display: inline;
            padding-top:3px;
        }
       

        .rightMenu li bt .iconText, .rightMenu li a .iconText{
            color: #fff;
            font-size: 11.5px;
            font-weight: 200;
            top: -6px;
            position: relative;
        }
       

        .rightMenu .dropdown-menu{
            border-radius:0px !important;
            top: 15px;
            left: -165px;
            width: inherit;
            max-height: 275px; 
        }
        .rightMenuExtended{
            top: 56px !important;
        }

        footer .dropdown-menu{
            left: -112px;
            min-width: 131px;
            top: inherit;
            bottom:25px;
        }
        
        .dropdown-menu >li>div.linkCompanyDiv{
        	height: 50px;

			& .link-company-logo{
					float: left;
				    width: 30%;
			}
			& .link-company-name{
				float: left;
			    width: 60%;
			    text-align: center;
			    text-overflow: ellipsis;
			    white-space: nowrap;
			    overflow: hidden;
			    margin: 20px 0 0 5px;
			}
        	
        	&:hover{
        		background: #f5f5f5;
        	}
        	
        	&:not(:last-child){
             border-bottom: 1px solid rgba(213, 214, 216, 0.5);
            }
            
            .company-text{
            	white-space: nowrap;
            	overflow: hidden;
            	text-overflow: ellipsis;
            	margin-left:10px; 
            	margin-top:10px; 
            	width: 90px;
            	float:left;
            }
            .no-img-company-text{
            	float:left;
            	width:90%;
            	text-align:center;
            	margin-top:10px;
            	white-space: nowrap;
            	overflow: hidden;
            	text-overflow: ellipsis;
            }
        }
        
        .dropdown-menu >li.linkCompanyli{
        	
        }
        
        .homeheader-switch-company{
        	float:left; 
        	cursor:pointer;
        	padding-right: 20px;
        }
        
        .dropdown-menu >li.linkCompanyli >span::before{
			content: '✓' !important;
    		font-family: 'SSStandard';
    		font-size: 15px;
    		width: 18px;
  			height: 25px;
    		color: #00bce3;
    		float: right;
    		margin-right: 20px;
    		margin-top: 8px;
		}        
        .homeheader-switch-company .dropdown-menu#linkCompanyul{
        	position: fixed;
        	left: 0;
        	top: 70px; 
        	width: 220px;
        }

        .dropdown-menu>li>bt {
            display: block;
            padding: 3px 20px;
            clear: both;
            font-weight: 400;
            line-height: 1.42857143;
            color: #333;
        }
        .bv-team-space .dropdown-menu > li > bt{
            text-overflow: ellipsis;
            overflow: hidden;
            width: 200px;
        }
        .dropdown-menu>li>bt:focus, .dropdown-menu>li>bt:hover {
            color: #262626;
            text-decoration: none;
            background-color: #f5f5f5;
        }

         .bv-module-setting-btn{
            float: left;
            height: 35px;
            width: 35px;
            border: 0;
            color: #fff;
            cursor: pointer;
            position: relative;
            margin: 0 2px 0 0;
        /*	background: #707070;*/
            border-radius: 4px;
        }

        .bv-module-setting-menu {
            height: 25px;
            width:100px;
            display: table-cell;
            /*vertical-align: middle;*/
            padding: 0px 0px;
            white-space: nowrap;
            /*color: #515151;
            font-size: 1.3em;
            letter-spacing: 0.5px;
            font-weight: 600;*/
        }

        .bv-module-setting-menu a:hover{
            cursor: pointer;
            /*background:#d1d1d1;*/
        }
        .bv-module-setting-menu li{
            height: 25px;
            display: table;
            width: 100%;
            margin: 5px 0px;
        }
        .bv-module-setting-menu li a div{
            display: inline-block;
            position: relative;
            top: 4px;
            left: -3px;
            width: 35px;
            height: 25px;
        }

        .progress{
            width: 100%;
            margin: 0;
            position: absolute;
            left: 0;
            right: 0;
            display: none;
            z-index: 1;
            height: 5px;
            top:45px;
        }

        .progress .progress-bar{
            background-color: #E00F0F;
        }
        .progress .progress-bar span{
            position: absolute;
            left:45%;
            color: rgb(5, 255, 246);
        }

        .expandMenu{
            display: none;
        }
        .bv-mobile-menu{
            display: none;
        }
    }

    .searchModalDialog{
        height:100%;
        margin-top:-80px;
    }

    .searchResultsMain {
        overflow: hidden;
        padding: 20px;
    }

    /*
    .bv-left-shadow {
        box-shadow: -2px 6px 15px 0px rgba(0,0,0,0.2);
        -webkit-box-shadow: -2px 6px 20px 0px rgba(0,0,0,0.2);
    }
    */

    .bv-right-shadow {
        box-shadow: -2px 6px 15px 0px rgba(0,0,0,0.2);
        -webkit-box-shadow: 2px 6px 20px 0px rgba(0,0,0,0.2);
    }

    .topSettingsForMenuForLogoInExtendedUi {
        top:155px !important;
    }


    .bvMaskTransitionExtended{
        height: 90px !important;
    }

    container.bvhome>content{
        display: table-row-group;
    }

    .bv-scroll-list > ul,
    .bv-scroll-list > .scroll-list-items .scroll-list > li
    {
        height: 40px;
    }
    .bv-scroll-list > ul{
        margin-right: 15px;
        overflow-y: hidden;
    }
    .bv-scroll-list > ul > li{
        font-weight: 500;
    }
    .bv-scroll-list > .scroll-list-items .scroll-list > li{
        border-bottom: 1px solid #ccc;
    }
    .bv-scroll-list > .scroll-list-items .scroll-list > li:last-child{
        border-bottom: inherit;
    }
    .bv-scroll-list > .scroll-list-items .scroll-list > li:hover{
        /*background: rgba(0,0,0,0.04);*/
        cursor:pointer;
    }
    .bv-scroll-list > .scroll-list-items .scroll-list > li.drag{
        border-bottom: inherit;
    }
    .bv-scroll-list > ul,
    .bv-scroll-list > .scroll-list-items .scroll-list{
        position: relative;
    }
    .bv-scroll-list > ul > li,
    .bv-scroll-list > .scroll-list-items .scroll-list > li > ul > li
    {
        display: block;
        position: absolute;
        line-height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .bv-scroll-list > .scroll-list-items{
        overflow-y: hidden;
        overflow-x: hidden;
        padding-right: 15px;
    }

    .bv-scroll-list > .scroll-list-items:hover{
        overflow-y: overlay;
        overflow-y: auto;
    }

    .bv-scroll-list .bv-scroll-col{
        white-space: nowrap;
    }

    .bv-scroll-list > filter{
        width: 100%;
        display: block;
        margin: 15px 0px;
    }
    .bv-scroll-list > filter span{
        font-size: 16px;
        font-weight: 500;
    }
    .bv-scroll-list > filter input{
        height: 30px;
        width: 100%;
        border: 0;
        background: #EFEFEF;
        padding: 0px 5px;
    }


    .bv-recycle-content{
        padding: 20px;
    }

    .bv-recycle-content .bv-scroll-list > .scroll-list-items{
        height: 250px;
    }

    /**
    * These styles determine what menu items will be disabled while in offline , when in the desktop client
    */
    &.jup-dc-offline-mode{

        li[state-name="home.surveys"],
        li[state-name="home.signatures"],
        li[state-name="home.approvals"],
        li[state-name="home.meetings"],
        li[state-name="home.reports"],
        li[state-name="home.dashboard"]{
            cursor: not-allowed;
        }

        li[state-name="home.surveys"] > bt,
        li[state-name="home.signatures"] > bt,
        li[state-name="home.approvals"] > bt,
        li[state-name="home.meetings"] > bt,
        li[state-name="home.reports"] > bt,
        li[state-name="home.dashboard"] > bt {
            pointer-events: none;
        }

        li[state-name="home.surveys"] .iconText,
        li[state-name="home.signatures"] .iconText,
        li[state-name="home.approvals"] .iconText,
        li[state-name="home.meetings"] .iconText,
        li[state-name="home.reports"] .iconText,
        li[state-name="home.dashboard"] .iconText,
        li[state-name="home.surveys"] .badge1,
        li[state-name="home.signatures"] .badge1,
        li[state-name="home.approvals"] .badge1,
        li[state-name="home.meetings"] .badge1,
        li[state-name="home.reports"] .badge1,
        li[state-name="home.surveys"] .bv-module-row-icon-div,
        li[state-name="home.signatures"] .bv-module-row-icon-div,
        li[state-name="home.approvals"] .bv-module-row-icon-div,
        li[state-name="home.meetings"] .bv-module-row-icon-div,
        li[state-name="home.reports"] .bv-module-row-icon-div,
        li[state-name="home.dashboard"] .bv-module-row-icon-div {
            opacity:0.2;
        }
        /* End */

        .userFilterDiv {
            display:none;height:0px;
        }

        .userInviteDiv {
            display:none;height:0px;
        }

        .inbox-setting-btn {
            opacity: 0.2;
            pointer-events: none;
            cursor: not-allowed;
        }

        .rightMenu li[state-name="InboxCompose"] {
            cursor: not-allowed;
        }

        .rightMenu li[state-name="InboxCompose"]> bt {
            pointer-events: none;
        }

        .rightMenu li[state-name="InboxCompose"] .iconText {
            opacity:0.2;
        }
    }

    &.jup-other-offline-mode{
        .bv-menu{

            li[state-name="home.surveys"],
            li[state-name="home.approvals"],
            li[state-name="home.dashboard"] {
                cursor: not-allowed;
            }

            li[state-name="home.surveys"] > bt,
            li[state-name="home.approvals"] > bt,
            li[state-name="home.dashboard"] > bt {
                pointer-events: none;
            }

            li[state-name="home.surveys"] .iconText,
            li[state-name="home.approvals"] .iconText,
            li[state-name="home.dashboard"] .iconText,
            li[state-name="home.surveys"] .badge1,
            li[state-name="home.approvals"] .badge1,
            li[state-name="home.surveys"] .bv-module-row-icon-div,
            li[state-name="home.approvals"] .bv-module-row-icon-div,
            li[state-name="home.dashboard"] .bv-module-row-icon-div {
                opacity:0.2;
            }

        }
    }

    &.jup-offline-mode{

        .settingsMenu li[state-name="portalAdmin"]{
            cursor: not-allowed;
        }

        .settingsMenu li[state-name="portalAdmin"] > a{
            pointer-events: none;
            opacity:0.2;
        }

        .bv-footer-menu li[state-name="home.recycle"],
        .bv-footer-menu li[state-name="home.preferences"]{
            cursor: not-allowed;
        }

        .bv-footer-menu li[state-name="home.recycle"]> a,
        .bv-footer-menu li[state-name="home.preferences"]> a {
            pointer-events: none;
            opacity:0.2;
        }


        .bv-menu{

            li[state-name="home.documents"],
            li[state-name="home.taskmanagement"],
            li[state-name="home.email"],
            li[state-name="home.directory"],
            li[state-name="home.dashboard"] {
                cursor: not-allowed;
            }

            li[state-name="home.documents"] > bt,
            li[state-name="home.taskmanagement"] > bt,
            li[state-name="home.email"] > bt,
            li[state-name="home.directory"] > bt,
            li[state-name="home.dashboard"] > bt {
                pointer-events: none;
            }

            li[state-name="home.documents"] .iconText,
            li[state-name="home.taskmanagement"] .iconText,
            li[state-name="home.email"] .iconText,
            li[state-name="home.directory"] .iconText,
            li[state-name="home.documents"] .bv-module-row-icon-div,
            li[state-name="home.taskmanagement"] .bv-module-row-icon-div,
            li[state-name="home.email"] .bv-module-row-icon-div,
            li[state-name="home.directory"] .bv-module-row-icon-div,
            li[state-name="home.dashboard"] .bv-module-row-icon-div {
                opacity:0.2;
            }

        }

        .rightClickMenu a[menu-name="edit"],
        .rightClickMenu a[menu-name="rename"],
        .rightClickMenu a[menu-name="pdf"],
        .rightClickMenu a[menu-name="viewas"],
        .rightClickMenu a[menu-name="move"],
        .rightClickMenu a[menu-name="present"],
        .rightClickMenu a[menu-name="editcollate"],
        .rightClickMenu a[menu-name="permission"],
        .rightClickMenu a[menu-name="addToFavorites"],
        .rightClickMenu a[menu-name="addToUserFavorites"],
        .rightClickMenu a[menu-name="boardbook"],
        .rightClickMenu a[menu-name="editboardbook"],
        .rightClickMenu a[menu-name="delete"],
        .rightClickMenu a[menu-name="share"],
        .rightClickMenu a[menu-name="editcollate"],
        .rightClickMenu a[menu-name="addToNewItems"],
        .rightClickMenu a[menu-name="download"],
        .rightClickMenu a[menu-name="versionTracker"],
        .rightClickMenu a[menu-name="copy"],
        .rightClickMenu a[menu-name="cut"],
        .rightClickMenu a[menu-name="paste"],
        .rightClickMenu a[menu-name="properties"],
        .rightClickMenu a[menu-name="send"],
        .rightClickMenu a[menu-name="removeFromBriefCase"],
        .rightClickMenu a[menu-name="removeFromNewItems"],
        .rightClickMenu a[menu-name="createShortCut"],
        .rightClickMenu a[menu-name="unhidefolder"],
        .rightClickMenu a[menu-name="hidefolder"],
        .rightClickMenu a[menu-name="rules"],
        .rightClickMenu a[menu-name="setFollowers"],
        .rightClickMenu a[menu-name="copylink"],
        .rightClickMenu a[menu-name="replace"],
        .rightClickMenu a[menu-name="auditTrail"],
        .rightClickMenu a[menu-name="secureMessage"],
        .rightClickMenu a[menu-name="follow"],
        .rightClickMenu a[menu-name="stopFollow"],
        .rightClickMenu a[menu-name="deleteAllAnnotations"],
        .rightClickMenu a[menu-name="alert"],
        .rightClickMenu a[menu-name="report"],
        .rightClickMenu a[menu-name="saveAsTemplate"],
        .rightClickMenu a[menu-name="preFillSurvey"],
        .rightClickMenu a[menu-name="reopen"]
        {
            opacity:0.2;
            pointer-events: none;
        }

        .rightClickMenu bt[menu-name="edit"],
        .rightClickMenu bt[menu-name="rename"],
        .rightClickMenu bt[menu-name="pdf"],
        .rightClickMenu bt[menu-name="viewas"],
        .rightClickMenu bt[menu-name="move"],
        .rightClickMenu bt[menu-name="present"],
        .rightClickMenu bt[menu-name="editcollate"],
        .rightClickMenu bt[menu-name="permission"],
        .rightClickMenu bt[menu-name="addToFavorites"],
        .rightClickMenu bt[menu-name="addToUserFavorites"],
        .rightClickMenu bt[menu-name="boardbook"],
        .rightClickMenu bt[menu-name="editboardbook"],
        .rightClickMenu bt[menu-name="delete"],
        .rightClickMenu bt[menu-name="share"],
        .rightClickMenu bt[menu-name="editcollate"],
        .rightClickMenu bt[menu-name="addToNewItems"],
        .rightClickMenu bt[menu-name="download"],
        .rightClickMenu bt[menu-name="versionTracker"],
        .rightClickMenu bt[menu-name="copy"],
        .rightClickMenu bt[menu-name="cut"],
        .rightClickMenu bt[menu-name="paste"],
        .rightClickMenu bt[menu-name="properties"],
        .rightClickMenu bt[menu-name="send"],
        .rightClickMenu bt[menu-name="removeFromBriefCase"],
        .rightClickMenu bt[menu-name="removeFromNewItems"],
        .rightClickMenu bt[menu-name="createShortCut"],
        .rightClickMenu bt[menu-name="unhidefolder"],
        .rightClickMenu bt[menu-name="hidefolder"],
        .rightClickMenu bt[menu-name="rules"],
        .rightClickMenu bt[menu-name="setFollowers"],
        .rightClickMenu bt[menu-name="copylink"],
        .rightClickMenu bt[menu-name="replace"],
        .rightClickMenu bt[menu-name="auditTrail"],
        .rightClickMenu bt[menu-name="secureMessage"],
        .rightClickMenu bt[menu-name="follow"],
        .rightClickMenu bt[menu-name="stopFollow"],
        .rightClickMenu bt[menu-name="deleteAllAnnotations"],
        .rightClickMenu bt[menu-name="alert"],
        .rightClickMenu bt[menu-name="report"],
        .rightClickMenu bt[menu-name="saveAsTemplate"],
        .rightClickMenu bt[menu-name="preFillSurvey"],
        .rightClickMenu bt[menu-name="reopen"]
        {
            opacity:0.2;
            pointer-events: none;
        }

        .rightMenu li[state-name="NewMeeting"],
        .rightMenu li[state-name="ManageMeetings"],
        .rightMenu li[state-name="ManageSurveys"],
        .rightMenu li[state-name="NewSurvey"],
        .rightMenu li[state-name="NewApproval"],
        .rightMenu li[state-name="ManageApprovals"],
        .rightMenu li[state-name="ApprovalEdit"],
        .rightMenu li[state-name="ApprovalAlert"],
        .rightMenu li[state-name="ApprovalDelete"] {
            cursor: not-allowed;
        }

        .rightMenu li[state-name="NewMeeting"]> bt,
        .rightMenu li[state-name="ManageMeetings"] > bt,
        .rightMenu li[state-name="ManageSurveys"] > bt,
        .rightMenu li[state-name="NewSurvey"] > bt,
        .rightMenu li[state-name="NewApproval"] > bt,
        .rightMenu li[state-name="ManageApprovals"] > bt,
        .rightMenu li[state-name="ApprovalEdit"] > bt,
        .rightMenu li[state-name="ApprovalAlert"] > bt,
        .rightMenu li[state-name="ApprovalDelete"] > bt {
            pointer-events: none;
        }
        .rightMenu li[state-name="NewMeeting"] .iconText,
        .rightMenu li[state-name="ManageMeetings"] .iconText,
        .rightMenu li[state-name="ManageSurveys"] .iconText,
        .rightMenu li[state-name="NewSurvey"] .iconText,
        .rightMenu li[state-name="NewApproval"] .iconText,
        .rightMenu li[state-name="ManageApprovals"] .iconText,
        .rightMenu li[state-name="ApprovalEdit"] .iconText,
        .rightMenu li[state-name="ApprovalAlert"] .iconText,
        .rightMenu li[state-name="ApprovalDelete"] .iconText {
            opacity:0.2;
        }

        .remove-new-item-button {
            pointer-events: none;
        }

        .remove-new-item-button > svg{
            cursor: not-allowed;
            opacity:0.2;
        }

        .bv-conf-list-event-icon-div {
            pointer-events: none;
        }
        .bv-conf-list-event-icon-div > span > svg{
            cursor: not-allowed;
            opacity:0.2;
        }

        .bv-conf-list-event-icon-div > button{
            pointer-events: none;
        }

        .bv-conf-list-event-start {
            cursor: not-allowed;
            opacity:0.2;
        }

        .bv-conf-list-event-start > button {
            pointer-events:none;
        }

        .bv-conf-details-attandee-response {
            pointer-events: none;
        }
        .bv-conf-details-attandee-response > span {
            cursor: not-allowed;
            opacity:0.2;
        }

        .bv-support-center  {
            cursor: not-allowed;
            opacity:0.2;
        }

        .bv-support-center > a{
            pointer-events: none;
        }

        /* Inbox Offline */

        .bv-modal-btn-reply,
        .bv-modal-btn-replyall,
        .bv-modal-btn-forward,       
        {
            opacity:0.2;
            pointer-events: none;
            cursor: not-allowed;
        }

		.bv-mobile-compose-message .bv-mobile-menu{
            display: none;
        }
        
        .bv-messages-mobile .dropdown-menu [ng-repeat="option in inboxMobileMenu"]{
            pointer-events: none;
            cursor: not-allowed;
            opacity:0.2;
        }

        // .bv-jup-status
        // {
        //     font-size: 13px;
        //     display: inline-block;
        //     position: absolute;
        //     height: 20px;
        //     top: 65px;
        //     right: 20px;
        // }

        .bv-jup-sync-status-div .jup-sync-icon-div {
            cursor: not-allowed;
            opacity: 0.2;
        }

        /* Surveys offline */

        .survey-changeDueDate input,
        .survey-moveToClosed,
        .survey-addParticipant,
        .survey-lock-usr,
        .survey-admin-status-remove-usr-right,
        .survey-sendAlert {
            cursor: not-allowed;
            pointer-events: none;
            opacity: 0.2;
        }

    }

    .bv-jup-status
    {
        font-size: 11px;
        display: block;
        position: absolute;
        left: 207px;
        width: 100px;
        top: 4px;
    }

    .jup-offline-icon {
        display: inline-block; width: 13px; height: 16px;
        position: relative;
        top: 3px;
    }

    .jup-online-icon {
        display: inline-block; width: 13px; height: 16px;
        position: relative;
        top: 1px;
    }

    .jup-offline-span
    {
        display:inline-block;
        color:#aeaeae;
    }

    .jup-online-span
    {
        display:inline-block;
    }


    .bv-jup-sync-status-div
    {
        height: 35px;
        /*width: 250px;
        max-width: 250px;
        position: absolute;*/
        display: inline-block;
        /*border: 1px solid red;*/
        float: left;
        margin-right: 5px;
    }

    .bv-jup-sync-status-div .jup-sync-icon-div {
        height: 23px;
        width: 25px;
        /*margin-top: 8px;*/
        margin-left: 10px;
        display: inline-block;
        position: relative;
        top: 8px;
        cursor: pointer;
    }

    .bv-jup-sync-status-div .jup-sync-status-span {
        font-size: 10px;
        /*position:absolute;
        top:9px;
        left:48px;*/
        display: inline-block;
    }

    .ngdialog .bv-dialog-title{
        font-size: 1.5em;
        font-weight: bold;
        margin: 0px;
        padding: 0;
        color: #515151;
        margin-bottom: 30px;
    }
    .ngdialog ul{
        margin:0;
        padding:0;
    }
    .ngdialog ul li{
        margin:inherit;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .ngdialog titl{
        display: inline-block;
        width: 110px;
        text-align: right;
    }
    .ngdialog main,
    .ngdialog guests,
    .ngdialog attachments,
    .ngdialog response,
    .ngdialog buttons{
        display: block;
        padding: 5px 0px;
        font-weight: 100;
    }
    .ngdialog attachments titl{
        vertical-align:top;
        padding: 5px 0px;
    }
    .ngdialog main span,
    .ngdialog guests ul,
    .ngdialog response span,
    .ngdialog attachments ul{
        margin: 0px 0px 0px 20px;
    }
    .ngdialog attachments li>span,
    .ngdialog guests li span{
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .ngdialog attachments,
    .ngdialog guests{
        max-height: 300px;
        overflow-y: auto;
    }

    .ngdialog attachments li{
        margin: 10px 0px;
    }
    .ngdialog attachments li>div{
        display: inline-block;
        height: 39px;
    }
    .ngdialog attachments li img{
        width: 30px;
    }
    .ngdialog attachments li>span{
        position: relative;
        left: 10px;
        margin-right: 10px;
        vertical-align: top;
    }
    .ngdialog buttons{
        position: relative;
        height: 40px;
        text-align:right;
    }
    .ngdialog buttons .bv-dialog-button{
        /* position: absolute; */
        border: 0px solid;
        /* background: #036EAA; */
        height: 30px;
        width: 100px;
        /* color: #fff; */
        right: 0;
    }
    .ngdialog response img{
        margin: 0px 5px;
    }
    .ngdialog .eventsListDialog ul{
        width:100%;
        margin-bottom: 20px;
        padding: 0px 20px;
        max-height: 300px;
        overflow-y: auto;
        display: block;
    }
    .ngdialog .eventsListDialog li{
        border-bottom: 1px solid #515151;
        line-height: 35px;
    }
    .ngdialog .eventsListDialog li:last-child{
        border:none;
    }
    .ngdialog .eventsListDialog li:hover{
        background: rgba(0,0,0,0.04);
        cursor:pointer;
    }
    .ngdialog .eventsListDialog div{
        float: right;
        margin-top: -2px;
    }
    .ngdialog .eventsListDialog div>span{
        font-size: 0.65em;
    }

    .bv-textFit
    {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .bv-modules-settings-title
    {
        position: absolute;
        top: 14px;
        left: 25px
    }
    .bv-module-main-div { height: calc(100% - 70px);margin-top:8px;width:100% }

    .bv-module-div0 { height:53px;width:100%; color:#000000; display:inline-block; }
    .bv-module-div1 { height:calc(100% - 109px);width:100%; display:inline-block; }
    .bv-module-div2 {
        position: absolute;
        right: 20px;
        bottom: 20px;
    }

    .bv-module-div0-header1      { height:20px;display:inline-block; width:100%;}
    .bv-module-div0-header1-div1 { float:left;display:inline-block;margin-left:290px;text-align:center;}
    .bv-module-div0-header1-div2 { float:right;display:inline-block;text-align:right;margin-right:61px;	}
    .bv-module-div0-header2      { height:15px;width:100%;margin-left:230px;}

    .bv-module-div0-header2 .bv-module-row-visible-all-div{
        width: 68px;
        margin:0;
    }
    .bv-module-div0-header2 .bv-module-row-visible-admin-div{
        width: 159px;
        margin:0;
    }
    .bv-module-div0-header2 .bv-module-row-landing-browser-div{
        width: 100px;
        margin:0;
    }
    .bv-module-div0-header2 .bv-module-row-landing-app-div {
        width: 60px;
        margin:0;
    }

    .bv-module-ul {
        height:100%;width:100%;
    }

    .moduleSortingHandle {
        position: relative;
        float: left;
        display: inline-block;
        top: 4px;
        padding-right: 17px;
        padding-left: 0px;
    }

    .bv-module-row:hover .drag-handle-image {
        display: block;
    }

    .bv-module-row {
        height: 65px;
        width: 100%;
        display: inline-block;
        background-color: #F4F4F4;
        padding: 18px 0 0 11px;
        margin-bottom: 15px;
        position: relative;
    }

    .bv-module-row-icon-div {
        width: 35px;
        height: 31px;
        float: left;
        margin: 0px 10px 0 0;
        padding-top: 6px;
        width: 20px;
        line-height: 20px;
        display: inline-block;
        font-size: 17px;

        &.signatures svg rect{
            fill: #fff;
        }

        &.surveys svg{
            stroke: #000;
        }

        &.new.items .newitems-cls-2{
            fill: #fff;
        }
    }

    .bv-modules-list {
        width: 100.6%;
    }
    .bv-modules-list::after{
        content: "";
    }

    .bv-module-icon-calendar {
        margin-top: 2px;
    }

    .bv-module-row-check-all-icon-div {
        height:35px; width:35px; padding-top: 5px;
    }
    .bv-module-row-check-admin-icon-div {
        height:35px; width:35px; padding-top: 5px; margin-left: 72px;
    }
    .bv-module-row-option-broswer-icon-div {
        height:35px;
        width:35px;
        padding-left: 90px;
        padding-top: 5px;
    }
    .bv-module-row-option-app-icon-div {
        height:35px;
        width:35px;
        padding-left: 55px;
        padding-top: 5px;
    }

    .bv-module-row-name-div{
        width: 177px;
        height: 30px;
        float: left;
        display: inline-block;
        color: #515151;
        font-size: 20px;
        margin-right: 20px;
    }
    .bv-module-row-visible-all-div{
        width:30px;
        height:30px;
        float:left;
        display:inline-block;
        text-align:center;
        align:center;
    }
    .bv-module-row-visible-admin-div{
        max-width:160px;
        height:30px;
        float:left;
        margin-left:10px;
        text-align:center;
        align:center;
    }
    .bv-module-row-landing-browser-div{
        height:30px;
        float:left;
        margin-left: 9px;
        display:inline-block;
        text-align:center;
        align:center;
    }
    .bv-module-row-landing-app-div{
        height:30px;float:left;margin-left:23px;display:inline-block;
        text-align:center;align:center;
    }

    .bv-module-row-icon-td {
        width:30px;
    }

    /*.bv-module-row-icon-div {
        margin-top:4px;height:30px;width:30px;
    }*/

    .bv-module-row-checkbox-td {
        width:405px;font-size:12px;font-weight:normal;color: #515151;
    }

    .bv-module-row-handle-td {
        width:25px;
    }

    .bv-module-row-browser-column {
        border-radius:3px;float:left;
        background-color:#E6E6E6;
        padding-top:4px;padding-bottom:5px;padding-left:5px;padding-right:5px;
        height:28px;width:220px;
    }

    .bv-module-row-jup-column {
        border-radius:3px;float:right;
        background-color:#E6E6E6;
        padding-top:4px;padding-bottom:5px;padding-left:5px;padding-right:5px;
        height:28px;width:178px;
    }

    .bv-module-3-line-div {
        width: 8px;
        height: 8px;
        background: url(#{$resources-path}/assets/images/3lines.png);
        /*cursor: pointer;*/
        cursor: move;
        margin-right: 5px;
    }

    #drag-drop-coveredup
    {
        width: 260px;
        height: 40px;
        position: absolute;
        background-color: rgba(0,0,0,0.04);
        text-overflow: clip;
        overflow: hidden;
        font-size: 18px;
        padding: 6px 10px 10px 10px;
        color: #333;
    }

    .bv-support{
        .bv-modal-body > div{

            div{
                min-height: 22px;

                > ul{
                    margin-left: 70px;
                }
            }
        }
    }

    .bv-support-font
    {
        padding-right: 5px;
        min-height: 20px;
    }
    .bv-support-underline {
        color: #333;
    }
    .bv-support-underline:link {
        text-decoration: underline;
    }

    .bv-support-underline:visited {
        text-decoration: underline;
    }

    .bv-support-underline:hover {
        text-decoration: underline;
    }

    .bv-support-underline:active {
        text-decoration: underline;
    }

    .supportEmailStyle{
        word-wrap :break-word;
    }

    .titleIcon,
    .titleIconMobile{
        width: 25px;
        height: 25px;
        float: left;
        position: relative;
        cursor: pointer;
        top: 3px;
    }
    .titleIconMobile{
        display: none;
    }

    .backIcon
    {
        width: 25px;
        height: 25px;
        float: left;
        top: 9px;
        left: 5px;
        position: relative;
    }

    .bv-signatures ul li{
        height:125px !important;
    }
    .searchResultsDialog .modal-dialog {
        min-width: 700px;
    /*	overflow: hidden;*/
    }

    .searchInputBox {
        width: 100%;
        margin-bottom: 20px;
    }

    .searchInputText {
    /*	padding: 0px 5px 0px 5px;
        border: 1px solid #EEE;*/
        width: 541px;
    /*	background: #EEE;
        line-height: 30px;
        font-size: 16px;*/
    }

    .item-list-table {
        height: 350px;
        margin: 0 0 10px 0;
        overflow-y: hidden;
        overflow-x: hidden;
        font-size: 16px;
        /*padding: 0 0px 0 15px;*/
    }

    .item-list-table:hover {
        overflow: auto;
    }

    .item-list-table table {
        width: 100%;
        table-layout: fixed;
    }

    .item-list-table tr:first-child {
        border-bottom: none !important;
    }

    .item-list-table tr {
        height: 40px;
        cursor: pointer;
        border-bottom: 1px solid #bbbbbb;
    }
    .item-list-table td {
        padding-right: 10px;
    }

    .item-list-table .column1 {
        width: 20%;
    }

    .item-list-table .column2 {
        width: 52%;
    }

    .item-list-table .column3 {
        width: 28%;
    }

    .item-list-table .column4 {

    }

    .item-list-table .column5 {

    }

    /*.searchResults tr:nth-child(even) {
        background: #EEE;
    }*/

    .bv-transclude-content{
    /* 	overflow-y: auto; */
    /* overflow: hidden; */
        position:absolute;
        background: #fff;
        /* display:inline-block; */
    }
    .bv-transclude-content:hover{
        overflow-y: auto;
    }

    .bv-show-right-sidebar{
        display:block;
    }
    .expandMenu{
        width: 30px;
        height: 30px;
        position: absolute;
        right: 16px;
        top: 9px;
        cursor: pointer;
    }
    .expandMenuExtended{
        top: 33px;
    }
    .expandMenuForModuleSettings{
        width:30px;
        height:30px;
        position:absolute;
        left:9px;
        top:17px;
        cursor:pointer;
    }
    .expandMenuRight{
        width: 30px;
        height: 30px;
        position: absolute;
        left: 15px;
        top: 16px;
        cursor:pointer;
    }

    .badge1 {
       position:relative;
       position: absolute;
       top: 43px;
       left: 43px;
    }
    .selected .badge1{
        left: 39px;
    }

    .badgeN {
       margin-left: 45px;
       top: 17px;
       position: absolute;
    }
    .badgeNExtended{
        top: 33px;
    }

    .badgeN[data-badge]:after {
       content:attr(data-badge);
       position:absolute;
       top:-10px;
       left:-11px;
       font-size:.7em;
       font-family: SourceSansPro;
       font-size: 12px;
       font-weight: 600;
       background:red;
       color:#4a3b00;
       width:18px;
       height:14px;
       text-align:center;
       line-height:13px;
       border-radius:22px;
       box-shadow:0 0 1px #333;
    }

    .badge1[data-badge]:after {
       content: attr(data-badge);
       position: absolute;
       top: -10px;
       left: -11px;
       font-family: SourceSansPro;
       font-size: 12px;
       font-weight: 600;
       background: $badgeColorBackground;
       border: 1px solid $badgeColorBackgroundBorder;
       color: #fff;
       width: 18px;
       height: 14px;
       text-align: center;
       line-height: 13px;
       border-radius: 22px;
       box-shadow: 0 0 1px #333;
    }

    .selected{
        background: $grayDarker;
    }

    .bv-section-nav-item-div-active {
        background-color: #FFF;
        padding: 15px;
        height: 45px;
        width: 100%;
        text-decoration: none;
    }

    .modulesButton,
    .usersButton,
    .groupsButton
    {
        position: relative;
        height: 35px;
        width: 35px;
    /* 	background-color: #707070; */
        color: #fff;
        font-size: 25px;
        text-align: center;
        cursor: pointer;
        display: inline-block;
        margin: 13px 0px 0px 5px;
        left: 5px;
    }

    .moduleSettingsButton{
        position: relative;
        height: 27px;
        width: 27px;
        color: #fff;
        font-size: 25px;
        text-align: center;
        cursor: pointer;
        display: inline-block;
        margin-right: 67px;
        margin-top: 13px;
        float: right;
    }

    .moduleBarButton {
        height: 35px;
        width: 27px;
        cursor: pointer;
        display: inline-block;
        top: 13px;
        /*position: relative;
        margin: 0px 7px;*/
    }

    /* .modulesButton svg,
    .usersButton svg,
    .groupsButton svg{
        fill: #fff;
    } */
    .modulesButtonExtended{
        margin: 29px 0px 0px 5px;
    }
    .usersButtonExtended{
        margin: 29px 0px 0px 5px;
    }
    .groupsButtonExtended{
        margin: 29px 0px 0px 5px;
    }
    .usersButton svg,
    .groupsButton svg,
    .modulesButton svg
    {
        width: 30px;
        height: 30px;
        top: 4px;
        position: relative;
    }

    .animate-show{
        opacity:1;
    }
    .animate-show.ng-hide{
        opacity:0;
    }
    .bvTabExtended .nav{
        z-index: 5;
        top: 0px;
        /* box-shadow: 0px 0px 19px -4px #000 inset; */
        height:30px;
    }
    .bvTabExtended .nav-tabs > li > a{
        height:30px;
        line-height: 15px;
    }

    .bvTabExtendedLeft .nav{
        left:0px !important;
    }
    .bvContentExtendedScreen{
        height: calc(100% + 85px) !important;
    }
    .bvContentNormalScreen{
        height: 100%;
    }
    .bv-overflow-sliding{
        /* overflow:hidden;
        display:block;
        height: 100%; */
    }
    .showTest{
        display:none;
    }
    .showing{
        position: absolute;
        top: 60px;
        display: block;
    }

    .bv-menu-box{
        height: $middlebarHeight;
        background: $sidebarBackgroundColor;
        //box-shadow: -1px 0px 6px -1px rgba(0, 0, 0, 0.81) inset;
        font-family: $nasdaqFontLight;
        font-weight: 500;
        border-bottom: 1px solid $sidebarItemBottomBorderColor;
        padding: 9px 21px;
        white-space: nowrap;
        /*box-shadow: -42px 0px 38px -50px #000 inset;*/

        .boardvantage{
            font-size: 15px;
            letter-spacing: -0.1px;
            color: #d5d6d8;
            display: inline-block;
            padding: 4px 0px;
            opacity: 1;
        }
    }
    .extended{
        height:90px;
    }

    .bv-select-teamspace {
        position: absolute;
        left: 30%;
        top: -5px;
    }
    .bv-user-id-title {
        position: absolute;
        bottom: -10px;
        right: 0px
    }

    /* bv item list */

    /* .bv-item-list-dialog  .modal-dialog{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) !important;
        -webkit-transform: translate(-50%,-50%) !important;
        -ms-transform:  translate(-50%,-50%) !important;

        margin: 0;
        height:5px;
        max-height:150px;
        max-width:350px;
    }
     */
    .bv-alert-item-list-dialog  .modal-dialog {
       position: absolute;
        top: 50%;
        left: 50%;
        /*transform: translate(-50%,-50%) !important;
        -webkit-transform: translate(-50%,-50%) !important;
        -ms-transform:  translate(-50%,-50%) !important;
        */
        margin: 0;
        max-width:350px;
        height:500px;
     }

    .bv-alert-item-list-dialog  .modal-dialog .bv-item-list-main > div {
        height: 480px;
        width: 100%;
     }

    .bv-item-list-main {
        padding: 0px 20px 20px 20px;
        /*padding: 20px;*/
        font-size: 16px;
        /*padding-top: 15px;*/
    }

    .bv-item-list {
        margin-bottom: 10px;
        margin-right: 10px;
    }


    .bv-item-list-action-menu {
        text-align: center;
        font-size: 16px;
        height: 40px;
        display: table;
        width: 100%;
        margin: auto;
    }

    .bv-item-list-action-menu li {
        display: inline;
        float: left;
        height: 30px;
        width: 50%;
        text-align: center;
        margin-top: 10px;
    }

    .bv-item-list-action-menu li a {
        display: inline-block;
        height: 30px;
        text-align: center;
        color: black;
    }

    .bv-item-list-action-menu li a:hover {
        cursor: pointer;
    }

    .bv-item-list-action-menu-selected {
        border-bottom: 4px solid;
        opacity: 1;
        cursor: pointer;
    }

    .bv-item-list-item {
        max-height: 40px;
        overflow: hidden;
    }

    .bv-item-list-grid {
        height: 350px;
        overflow-x: hidden;
        overflow-y: hidden;
        padding-right: 10px;
        margin-top: 10px;
        font-size: 16px;
        line-height: 40px;
    }

    .bv-item-list-grid:hover {
        overflow-y: auto;
    }

    .bv-item-type-header {
        font-size: 16px;
        font-weight: 500;
        /*border-bottom: 1px solid #808080;*/
    }

    .bv-item-list-ul1 {
        height: 350px;
        position: absolute;
        display: block;
        overflow-x: hidden;
        overflow-y: auto;
        width: calc(100% - 40px);
    }

    .bv-item-list-ul1-li
    {
        line-height:32px;
        display:block; width:100%;
    }

    /*.bv-item-list-ul1:hover {overflow-y:auto;}*/
    /*.bv-item-list-ul1 > li { display:block; width:100%; line-height:30px;}*/

    .bv-item-list-ul1 .bv-item-list-ul2 {height: 40px; line-height: 40px;}
    .bv-item-list-ul1 .bv-item-list-ul2 > li { display:block; line-height:40px; height:40px;}

    .bv-item-list-ul2-li-name-heading-span1 {display:inline;}
    .bv-item-list-ul2-li-name-heading-span2 {display:inline;position:absolute; left: 170px;}

    .bv-item-list-ul1 .bv-item-list-ul2-li-name         { line-height:40px; height:40px !important; display:block; position:absolute; width: calc(100% - 45px) !important; }
    .bv-item-list-ul1 .bv-item-list-ul2-li-name > div   { width: 100%; text-overflow:ellipsis; overflow:hidden; white-space:nowrap;}
    .bv-item-list-ul1 .bv-item-list-ul2-li-checkbox     {
        line-height:40px;
        height:40px !important;
        display:block;
        position:relative;
        width:50px !important;
        /*left:260px !important; */
        float: right;
    }
    .bv-item-list-ul1 .bv-item-list-ul2-li-checkbox-div {
        line-height: 22px;
        height: 22px;
        width:22px;
        float:right;
        /*margin-right:20px; */
        padding-top:10px; }

    .bv-item-list-name {
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 92%;
    }

    .bv-item-list-check-div {
        display: inline-block;
        float: right;
        margin: 10px 0px 0px 0;
    }

    .bv-item-checkbox input[type="checkbox"] {
        display:none;
    }
    .bv-item-checkbox input[type="checkbox"] + label {
        display:inline-block;
        width:27px;
        height:27px;
        margin:-1px 4px 0 0;
        vertical-align:middle;
        background:url(#{$resources-path}/assets/images/checkbox.png) left top no-repeat;
        cursor:pointer;
    }
    .bv-item-checkbox input[type="checkbox"]:checked + label {
        margin: 3px 1px 0 0;
        background:url(#{$resources-path}/assets/images/checkbox_on.png) left top no-repeat;
    }

    .bv-item-small-close-svg {
        display:inline-block;
        width:27px;
        height:27px;
        margin:-1px 4px 0 0;
        vertical-align:middle;
        cursor:pointer;
        fill:#000000;
        position:relative;
    }

    .bv-item-small-close-svg > svg {
        fill: #000 !important;
    }

    .bv-item-small-close-svg{
        opacity: 0.5;
    }
    .bv-item-small-close-svg:hover{
        opacity: 1;
    }

    .bv-modal-btn-delete{
        width: 20px;
        height: 20px;
        fill: #000000;
        position: absolute;
        top: 8px;
        cursor: pointer;
        visibility:hidden;
    }
    .bv-modal-btn-delete:hover
    {
        fill: #000000 !important;
        visibility:visible;
    }

    &.jup-desktop-client{
      .bv-active-windows{
        background: rgba(0,0,0,0.3) repeat scroll 0 0;
        border: 2px solid rgba(0,0,0,0.4);
        display: none;
        max-width: 157px;
        height: 33px;
        position: relative !important;
        left: inherit !important;
      }

      footer{
        bottom: 35px;
        .bv-jup-sync-status-div{
          svg{
            fill: rgb(34, 115, 165);
          }
          .jup-sync-status-span{
            color: rgb(34, 115, 165) !important;
          }
        }
      }
    }

    .bv-active-windows{
        background: #bebebe repeat scroll 0 0;
        border: 2px solid #727272;
        display: none;
        position: absolute;
        left: 250px;
        max-width: 157px;
        width: 157px;
        height: 35px;
        top: -40px;
    }

    .bv-active-windows > span {
        color: #FFFFFF;
        height: 35px;
        padding: 7px 7px 0 7px;
        position: relative;
        top: 3px;
        font-size: 13px;
    }

    .bv-active-windows .bv-document-title {
        max-width: 110px;
        min-width: 30px;
        width: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        text-align: center;
        vertical-align: -3px;
        outline: 0 none;
        cursor: default;
    }

    .bv-active-windows > div {
        background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
        border: medium none;
        color: #ffffff;
        height: 23px;
        width:	23px;
        outline: 0 none;
        margin-left: 10px;
    }

    .active-windows-remove{
        position: absolute;
        right: 25px;
        top: 0;
        border: 0;
    }

    .bv-active-windows-list{
        margin: 20px;

        .scroll-list-items{
          max-height: 400px;
          padding: 0;

          .scroll-list{
            padding-right: 5px;
          }
        }

        .mCSB_outside + .mCSB_scrollTools{
          right: 0;
        }
    }

    .bv-active-window-item{
        height: 32px !important;
        margin-top: 4px;
        position: relative;

        > div:first-child{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding-right: 25px;
          top: 2px;
          position: relative;
        }
    }
    .bv-active-window-item:hover .bv-modal-btn-delete
    {
        fill:#CCCCCC;
        visibility:visible;
    }


    .bv-modal-btn-minimize{
        width: 23px;
        height: 23px;
        fill: #fff;
        right: 6px;
        position: absolute;
        top: 22px;
        cursor: pointer;
    }

    .bv-active-windows{

        .bv-modal-btn-close {
            top: 8px;
            width: 15px;
            height: 15px;
            right: 5px;
            padding: 0px;
            background: transparent !important;

            &:hover{
              svg{
                fill: #000;
              }  
            }

            svg{
                width: 15px;
                height: 15px;
                fill: #fff;
            }
        }
    }

    /* #bvModuleMenuDiv > ul{
        width: 250px;
    }
     */

    .bv-menu-box .bv-mobile-teamspace{
        display: none;
    }

    /*****       ******/

    .challange-questions .bv-dropdown .dropdown-menu{
        width: 100%;
        overflow-x: auto;
    }

    .challange-questions .bv-dropdown .dropdown-menu li {
        display: table-row-group;
    }

    .challange-questions .bv-dropdown > .btn {
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 25px;
    }
    .bv-last-sync{
        padding: 6px 20px 5px;
        border-bottom: 1px solid #eee;
        color: #ccc;
        font-size: 9px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 203px;
    }

    .connectedIconClass,
    .notAvailableIconClass,
    .goOfflineIconClass{
        fill: #bfbfbf;
    }
    .connectedIconClass.active,
    .notAvailableIconClass.active{
        fill: #2273a5;
    }
    .offlineSearchDisabledBtn{
        cursor: not-allowed !important;
        opacity: 0.2;
    }

    .bv-trash-content > .bv-scroll-list,
    .bv-trash-content > .bv-scroll-list > .scroll-list-items{
        height: 100%;
        min-height: 100%;
    }
    .bv-trash-content .bv-input-btn{
        float: right;
        margin-right: 20px;
        display: none;
    }

    .bv-trash-content .bv-scroll-list > ul > li,
    .bv-trash-content .bv-scroll-list > .scroll-list-items .scroll-list > li > ul > li{
        line-height: inherit;
        padding-left: 15px;
        font-size: 14px;
    }
    .bv-trash-content .bv-scroll-list > ul{
        height: 28px;
        background: #eee;
        margin-right: 0px;
    }
    .bv-trash-content .bv-scroll-list > ul > li{
        font-weight: inherit;
        height: 28px;
        padding-top: 5px;
        font-size: 12px;
        font-weight: 500;
    }

    .bv-trash-content .bv-scroll-list > .scroll-list-items .scroll-list > li{
        border-bottom: inherit;
        cursor:default;
    }
    .bv-trash-content .bv-scroll-list > .scroll-list-items .scroll-list > li:nth-child(even){
        background: rgb(247, 249, 250);
    }

    .bv-trash-content .bv-scroll-list > .scroll-list-items .scroll-list > li.checked{
        background: #dbdde1;
    }

    .bv-trash-content .bv-scroll-list > .scroll-list-items .scroll-list > li:hover{
        background: #ebeef0;
    }
    .bv-trash-content .bv-scroll-list > ul{
        display: none;
    }
    .bv-trash-content .bv-scroll-list > ul > li:hover{
        background: #ddd;
    }
    .bv-trash-content .bv-scroll-list > ul > li:last-child:hover{
        background: inherit;
    }
    .bv-trash-content .bv-scroll-list > ul > li:hover .sort-direction{
        display: block;
    }
    .bv-trash-content .bv-scroll-list > ul > li:last-child{
        text-align: right;
        padding-right: 40px;
    }
    /* .bv-trash-content .bv-scroll-list > ul > li.active{
        background: #ddd;
    } */
    .bv-trash-content .bv-scroll-list > .scroll-list-items .scroll-list > li > ul > li{
        margin-top: 9px;
    }
    .bv-trash-content .bv-scroll-list > .scroll-list-items{
        padding-right: 0px;
    }
    .bv-trash-content .bv-scroll-list > ul > li .sort-direction{
        width: 12px;
        height: 12px;
        stroke: #000;
        float: right;
        top: 9px;
        right: 5px;
        position: absolute;
        display: none;
    }

    .bv-trash-content .bv-scroll-list > ul > li .sort-direction.reverse{
        transform: rotateZ(180deg);
        -webkit-transform: rotateZ(180deg);
    }
    .bv-trash-content .bv-scroll-list > ul > li.active .sort-direction{
        display: block;
    }
    .bv-trash-content .mCSB_outside+.mCSB_scrollTools{
        right: 0;
    }

    .bv-trash-content .bv-svg-check-false,
    .bv-trash-content .bv-svg-check-true{
        float: right;
        top: 2px;
        position: relative;
        right: 15px;
    }

    .bv-trash-content .bv-scroll-list > ul .bv-scroll-col .bv-svg-check-false,
    .bv-trash-content .bv-scroll-list > ul .bv-scroll-col .bv-svg-check-true{
        position: absolute;
        top: 6px;
    }


    .bv-restoring-progress .modal-dialog{
        width: 450px;
    }
    .bv-restoring-progress .bv-input-btn-box button:last-child{
        display: none;
    }

    .bv-restoring-progress .bv-modal-body > div > div > span{
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }
    .bv-restoring-progress .progress{
        height: 24px;
        border-radius: 0;
        box-shadow: inherit;
        background: #fff;
        border: 1px solid #ddd;
        margin: 5px 0 19px;
    }
    .bv-restoring-progress .progress:last-child{
        margin: 0;
    }
    .bv-restoring-progress .progress-bar{
        line-height: 23px;
        transition: width .2s ease;
        -webkit-transition: width .2s ease;
    }
    .bv-confirm.bv-create-user-dialog .bv-confirm-content{
        white-space: pre-line;
    }
    .bv-badge-three-digits:after{
        /* font-size: 17px; */
        width: 26px !important;
        border-radius: 9px !important;
    }
    .bv-badge-four-digits:after{
        /* font-size: 17px; */
        width: 30px !important;
        border-radius: 9px !important;
    }
    .media-files {
     display: table-caption;
     padding: 15px 10px 5px 22px;
     border-top: 1px solid #d5d6d880;
     color:#96979c;
    }
    .media-height-bt {
    height: 50px !important;
    }
    .media-height-li {
    height: 50px !important;
    }
    .alldocuments {
        padding-left: 42px !important;
        border-top:none !important;
       }
    .bv-menu .selected .alldocuments{
        padding-left: 38px !important;
    }
    .cookie-consent-banner{
        min-height: 76px;
        background: #ffffff;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 999;
        box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
        box-sizing: border-box;
        .content{
            padding: 26px;
            float: left;
            width: calc(100% - 130px);
            color: #1D1E25;
            font-size: 14px;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        }
        .btn-wrap{
            padding: 15px 0 0;
            float: right;
            width: 130px;
            .acceptBtn{
                box-sizing: border-box;
                justify-content: center;
                align-items: center;
                gap: 7px;
                width: 60px;
                height: 48px;
                background: #0094BD;
                border: 1px solid #0094BD;
                border-radius: 3px;
                color: #ffffff;
                font-weight: 400;
            }
            .close-btn{
                box-sizing: border-box;
                justify-content: center;
                align-items: center;
                gap: 7px;
                width: 60px;
                height: 48px;
                background: #ffffff;
                border: none;
                border-radius: 3px;
                color:#000000;
                font-weight: 400;
            }
        }
    }
}
@media screen and (max-width: 360px){ 
    .nasdaq.bv-mobile .meetings-wrapper .bvhome [home-sidebar].expandHigh{
        width: 0 !important;
    }
  
}
@media (max-width: 768px){ 
    .nasdaq{
			       & .expandLow{
			       		& #calendar-container{
			       			#calendar-box{
			       				& .fc-content-skeleton {
			       					tbody {
			       						tr{
			       							&:first-child {
			       								td {
			       									a.fc-day-grid-event{
			       												    margin-left: 12px;
			       										}
			       								}
			       							}
			       						}
			       					}
			       				}
			       			}
			       		}
			       }
		       
		       } 
  
}
@media screen and (min-width:768px) and (max-width: 1280px) and (orientation: portrait) {
			
		        .nasdaq.bv-mobile .meetings-wrapper .bvhome [home-sidebar].expandHigh{
		                width: 60px !important;
					    position: absolute;
					    left: 0;
					    z-index: 9;
		        }
		        
		         .nasdaq.bv-mobile .meetings-wrapper .bvhome [home-sidebar].expandLow{
		               
					    position: absolute;
					    left: 0;
					    z-index: 9;
		        }
		       
		       .nasdaq.bv-mobile .meetings-wrapper .bvhome .bv-content.expandLow{
		            margin-left:0;
		            transform: translateX(0px);
				    -webkit-transform: translateX(0px);
				    margin-left: 0px;
				    /* transition: 200ms all; */
				    width: calc(100%);
		            & .bv-title > div > div >span{
		            	    margin-left: 250px;
		            }
		        }
		      
		}