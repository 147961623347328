@import '../../../utils/variables';

@font-face {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: normal;
    src: local('Helvetica'), url('#{$resources-path}/fonts/Helvetica/Helvetica.woff') format('woff');
}

@font-face {
    font-family: 'Helvetica Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Helvetica Oblique'), url('#{$resources-path}/fonts/Helvetica/Helvetica-Oblique.woff') format('woff');
}

@font-face {
    font-family: 'Helvetica Compressed';
    font-style: normal;
    font-weight: normal;
    src: local('Helvetica Compressed'), url('#{$resources-path}/fonts/Helvetica/helvetica-compressed-5871d14b6903a.woff') format('woff');
}

@font-face {
    font-family: 'Helvetica Rounded Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Helvetica Rounded Bold'), url('#{$resources-path}/fonts/Helvetica/helvetica-rounded-bold-5871d05ead8de.woff') format('woff');
}

@font-face {
    font-family: 'Helvetica Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Helvetica'), url('#{$resources-path}/fonts/Helvetica/Helvetica-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Helvetica Bold Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Helvetica Bold Oblique'), url('#{$resources-path}/fonts/Helvetica/Helvetica-BoldOblique.woff') format('woff');
}

@font-face {
    font-family: 'Helvetica Light';
    font-style: normal;
    font-weight: normal;
    src: local('Helvetica Light'), url('#{$resources-path}/fonts/Helvetica/helvetica-light-587ebe5a59211.woff') format('woff');
}
