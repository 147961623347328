@import 'src/utils/classes';

* { 
  -ms-overflow-style: scrollbar; 
} 

.dashboard-bg {
& .bv-height-100  {
& .bv-transclude-content {
   background: #f8f8f8 !important;
  -webkit-overflow-scrolling: touch;
}
}
}
.scroll-hidden {
overflow:hidden;
}
html{
    margin: 0;
	padding:0;
	height: 100%;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	overflow:hidden;
	font-size: 16px;
	letter-spacing: 0.6px;
	font-weight: 400;
	color: #000;
	-ms-touch-action: manipulation;
    touch-action: manipulation;
}
.inJBnT #cke_ck-editor{ border: 1px solid #d1d1d1 !important;}
body{
    margin: 0;
    padding:0;
    height: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    overflow:hidden;
    font-size: 16px;
    letter-spacing: 0.6px;
    font-weight: 400;
    color: #000;
    -ms-overflow-style: none; 
    
    &.ie{
    	& #viewFullAgenda {
    		& .meeting-agenda-list-wrapper {
    			& .meeting-agenda-list-left {
    				& .preview-list-wrapper {
    					ul{
    						&.ListWrap{
    								height: calc(100% - 85px);
    							}
    					}
    				}
    			} 
    		}
    	}
    }
    a.fc-more{
    	pointer-events: inherit;
    }
    .manage-templates-wrap{
    	& .bv-middle-bar{
    		    margin: 0;
    	}
    }
	.virtual-meetings-configuration{
		& .bv-middle-bar{
			margin: 0;
		}
		.vm-cards-wrapper{
			height: 200px;
			padding: 13px;
		}
		.vm-user-info{
			padding: 20px 0 20px 150px;
			border-top: 1px solid #d7d7d7;
			position: relative;
			font-size: 16px;
			& .vm-user-avatar{
				border-radius: 5px;
				position: absolute;
				top: 25px;
				left: 25px;
			}
		}
		& .vm-connect-card{
			width: 300px;
			box-sizing: border-box;
			border-radius: 5px;
			margin: 10px 20px 0 10px;
			background: #FFFFFF;
			border: 2px solid #E6E6E6;
			box-shadow: 0px 1px 1px rgba(129, 129, 129, 0.14), 0px 2px 1px rgba(129, 129, 129, 0.12), 0px 1px 3px rgba(129, 129, 129, 0.2);
			border-radius: 6px;
			float: left;
			& .vm-card-row{
				padding: 2px;
				&.border-t-b{
					border-top: 1px solid #E6E6E6;
					border-bottom: 1px solid #E6E6E6;
					padding: 0;
				}
				&.logo{
					padding: 20px 0;
					text-align: center;
				}
				&::after {
					display: block;
					content: "";
					clear: both;
				}
			}
			& .column-one{
				float: left;
				width:55%;
			}
			& .column-two{
				float: right;
				width:44%;
			}
			& .vm-title{
				font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
				font-style: normal;
				font-size: 14px;
				line-height: 15px;
				text-align: left;
				color: #000000;
				margin: 17px;
				text-overflow: ellipsis;
				overflow: hidden;
                max-height: 30px;
			}
			& .vm-name{
				font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
				font-style: normal;
				font-size: 14px;
				line-height: 15px;
				text-align: left;
				color: #000000;
				margin: 10px;
				text-overflow: ellipsis;
				overflow: hidden;
                max-height: 30px;
				& label{
					font-size: 14px;
					margin: 0;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
                    font-weight: 400;
				}
				& span{
					font-size: 14px;
					margin: 0;
					white-space: nowrap;
					color:#A8A8A8;
					overflow: hidden;
					text-overflow: ellipsis;
                    font-weight: 400;
				}
			}
			& .vm-status{
				align-items: center;
				padding: 2px 0;
				background: #D1D1D1;
				border-radius: 14px;
				line-height: 18px;
				text-align: center;
				margin: 10px;
				font-size: 12px;
				font-weight: 500;
				display: inline-block;
                width: 53px;
				&.active{
					background: #2ECA61;
					color:#FFFFFF;
				}
			}
			& .vm-connect{
				padding: 5px 10px;
				background: #0092BC;
				border: 1px solid #0092BC;
				box-sizing: border-box;
				border-radius: 3px;
				margin: 7px 10px 7px 7px;
				text-align: center;
				color: #fff;
				cursor: pointer;
                font-size: 14px;
                float: right;
				&:hover{
					background: #35b8de;
				}
			}
		}
	}
	.agenda-templates-wrap{
		    height: calc(100% - 44px)!important;
	    	& .bv-middle-bar{
	    		margin: 0;
	    	}
	}
    .view-full-agenda-wrap{
    	height:100%;
    	& .bv-middle-bar{
    		    margin: 0;
    	}
    	& .bv-scrollbar{
    		    overflow: hidden !important;
    	}
    }
    #viewFullAgenda{
    	margin:0px 0 0 0;
    	    overflow: hidden;
    	ul{
    		&.tabs{
			    
			    position: relative;
			    display: block;
			    top: 0;
			    left: 0;
			    border-bottom: 3px solid #c6c6ca;
			    padding: 0 20px;
			    width:100%;
			    float: left;
			    li{
			    	margin-bottom: -3px;
				    border-bottom: 3px solid transparent;
				    font-size: 11.5px;
				    width: 80px;
				    text-align: center;
			        float: left;
		            position: relative;
					display: block;
					line-height: 0px;
				    padding: 12px 15px;
				    color: #96979c;
				    background: none;
				    font-size: 11.5px;
				    cursor: pointer;
					&.active{
						border-bottom: 3px solid #00bce3;
						color: #00bce3;
    					background: none;
					}
			    }
    		}
    	}
    	.agenda-container{
    		height: 100%;
		    float: left;
		    width: 100%;
    	}
    	.pdf-container{
    		height: calc(100% - 30px);
		    float: left;
		    width: 100%;
		     background: #6b6d75;
		    
		    & .PdfViewerWrap{
		    	height: 100%;
		    }
    	}
    	
		 .ss-list-icon{
		     font-family: "SSStandard";
			  color: #484b54;
			      font-size: 18px;
			  transition: transform 0.25s ease;
			  display: inline-block;
			  width: 100%;    
			  float: left;			  
		}
    	.ss-close-icon{
	    	font-family: "SSStandard";
		    color: #484b54;
		    font-size: 20px;
		    display: inline-block;
		    cursor: pointer;
	    }
    	.meeting-agenda-list-wrapper{
    		 float: left;
   			 width: 100%;
   			 height:100%;
   			 
   			 &.showpane-wrapper{
   			 	& .meeting-agenda-list-left{
   			 		    margin-left: -313px;
   			 	}
   			 	& .meeting-agenda-list-right{
   			 		    margin-left: 0;
   			 	}
   			 }
   			 
   			 & .toggle-button-wraper{
   			 	float: left;
			    width: 30px;
			    height: 100%;
			    background: #eaeaeb;
			    position: relative;
			    z-index: 10;
			    & .icon-wraper{
			    	    float: left;
					    background: #fff;
					    width: 100%;
					    padding: 4px 0 0 5px;
					    cursor: pointer;
			    }
   			 }
   			 
    		& .meeting-agenda-list-left{    			
				float: right;
				width: 342px;
				height: 100%;
				z-index: 1;
				margin-left: 30px;
				position: absolute;
				transition: all .5s ease-in-out;
				left: 0px;
				background:#ffffff;
				overflow: hidden;
				  &:after {
				    visibility: hidden;
				    display: block;
				    content: "";
				    clear: both;
				    height: 0;
				  }
				  & .preview-list-wrapper{
				      height: 100%;
    				padding-bottom: 50px;
    				& .preview-list-title{
    					    width: 100%;
						    float: left;
						    display: block;
						    padding: 10px;
						    background: #00bce3;
						    color: #ffffff;
						    -webkit-letter-spacing: 0.4px;
						    -moz-letter-spacing: 0.4px;
						    -ms-letter-spacing: 0.4px;
						    letter-spacing: 0.4px;
						    position: relative;
						    & .close-icon-wrapper{
							    background: #0094b3;
							    background: #0094b3;
							    float: right;
							    position: absolute;
							    right: 0px;
							    top: 0;							    
							    padding: 8px 14px;
    							height: 100%;
    							cursor: pointer;
    							& .ss-close-icon{
    								line-height: 30px;
    							}
						     }
    				}
    				
    				ul{
    					&.ListWrap{
    						    border-top: 1px solid #ccc;
							    list-style-type: none;
							       float: left;
							       width:100%;
							    padding: 0;
							    border-left: 1px solid #ccc;
							    overflow: auto;
    							height: calc(100% - 43px);
    							padding-bottom: 100px;
							    li{
							    	border-right: 4px solid #ffffff;
								    border-bottom: 1px solid #ccc;
								    padding: 10px 10px 10px 30px;
								    margin-left: -20px;
								    position: relative;
								    cursor: pointer;
								    
								   &.second-level{
								   	padding-left: 45px;
								   }
								    &.third-level{
								   	padding-left: 55px;
								   }
								    
								    &.active{
								    	border-right: 4px solid #00bce3;
								    }
								    &:after {
										    visibility: hidden;
										    display: block;
										    content: "";
										    clear: both;
										    height: 0;
										}
									& .ItemNameDetails{
										display: block;
									    width: 70%;
									    float: left;
									    
									    & .ItemOrder{
									    	  font-weight: 700;
											    display: block;
											    float: left;
											    margin-right: 5px;
											    max-width: 35px;
											    text-align: center;
											    font-size: 14px;
									    }
									    & .nameText{
									    	    display: block;
											    width: calc(100% - 40px);
											    float: left;
											    font-size: 14px;
											    font-weight: bold;
									    }
									    & .AgendauserName{
									    	    display: block;
											    width: 100%;
											    float: left;
											    font-size: 12px;
											    color: #2ba6c0;
											    cursor: pointer;
											    padding: 3px 0 0 0;
									    }
									    &  .detailText {
											    display: block;
											    width: 100%;
											    float: left;
											    padding: 3px 0 0 0;
											    font-size: 12px;
											    color: #46474c;
											}
									}
									& .ItemTimeDetails{
										    display: block;
										    width: 30%;
										    float: right;
										    
										    & .AgendaItemTimes{
										    	display: block;
											    width: 100%;
											    float: left;
											    font-size: 12px;
											    text-align: center;
										    }
										    & .DiscussionPill{
										    	display: block;
											    width: 100%;
											    float: left;
											    border: 1px solid #d2d4d7;
											    border-radius: 10px;
											    text-transform: uppercase;
											    font-size: 9px;
											    font-weight: bold;
											    margin-top: 10px;
											    text-align: center;
											    padding: 3px 0;
											    color: #acadb0;
											    cursor: pointer;
											    &:empty {
												    display: none;
												}
										    }
										    
										    & .CeoThoughtsPill{
										    	display: block;
											    width: 100%;
											    float: left;
											    background: #00bce3;
											    border-radius: 10px;
											    text-transform: uppercase;
											    font-size: 9px;
											    font-weight: bold;
											    margin-top: 10px;
											    padding: 3px 0 3px;
											    text-align: center;
											    color: #ffffff;
											    cursor: pointer;
											    &:empty {
												    display: none;
												}
										    }
									}
										
							    }
    					}
    				}
				  }
				
    		}
    		& .meeting-agenda-list-right{
    			height: 100%;
			    margin-left: 372px;
			    background: #6b6d75;
			    overflow-y: auto;
			    -webkit-transition: all 0.5s linear;
			    transition: all 0.5s linear;
			    & .PdfViewerWrap{
			    	height: 87%;
			    	overflow: hidden;
			    }
			    & .documentMessage{
			    	display: block;
				    text-align: center;
				    margin-top: 18%;
				    color: #fff;
				    font-size: 16px;
			    }
    		}
    	}
    }
	&.bv-viewer-open{
		.modal-backdrop.in {
    		z-index: 99 !important;
		}		
	}
	& .bv-event-presentation-overflow{
		z-index: 99 !important;
	}
    &.nasdaq{
    .cbe-footer{
        float: right !important;
        width: auto !important;
        padding: 0 !important;
        border: none !important;
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        button{
            position: inherit !important;
            background-color: transparent !important;
        }
    }
    .bc-green{
        background-color: #19b772 !important;
    }
    & .dashboard-wrapper{
       & .bv-middle-bar .bv-title>div>div>span{
            white-space: normal;
            overflow: unset;
            text-overflow: unset;
        }
    }

	& .meetings-wrapper{
		& .main-content-wrap{
			overflow-y: hidden!important;
			height: 100%!important;
		}
	}
	& .view-full-agenda-wrap{
		& .main-content-wrap{
			overflow-y: hidden!important;
			height: calc(100% - 44px) !important;
		}
    }
    & .manage-templates-wrap{
		& .main-content-wrap{
			overflow-y: hidden!important;
			height: calc(100% - 44px) !important;
        }
        
        & .bv-scrollbar{
            overflow-x: hidden;
        }
	}
		& .virtual-meetings-configuration{
			& .main-content-wrap{
				overflow-y: hidden!important;
				height: calc(100% - 44px) !important;
			}

			& .bv-scrollbar{
				overflow-x: hidden;
			}
		}
    	.document-wrapper, .approval-list-container{
    	& .mCSB_scrollTools{
    	   width: 18px;
    		& .mCSB_draggerContainer{
    		& .mCSB_dragger{
    		& .mCSB_dragger_bar{
    			width:8px;
    			background: #B6B7BA;
    			    margin-left: 5px;
    		}
    			
    		}
    			& .mCSB_draggerRail{
    				    background: #ffffff;
					    width: 14px;
					    display: block;
					        border-radius: 0px;
    			}
    		}
    	}
    }
    .document-wrapper{
        & .mCSB_scrollTools{
             & .mCSB_draggerContainer{
                & .mCSB_dragger{       
                    & .mCSB_dragger_bar{
                        margin-left: 7px;
                    }
                    
                }
                
            }
         }
    }
		.tooltip-bv
		{
    			position: relative;
		}
		
		.tooltip-bv{
		
			&:before{
		    		border: solid;
		    		border-color: #333 transparent;
		    		border-width: 6px 6px 0 6px;
		    		bottom: 20px;
		    		content: "";
		    		left: 15%;
		    		position: absolute;
		    		z-index: 99;
				opacity:0;
				transition: opacity 0ms;
			}
		
			&:after{
	    		    background: #333;
			    background: rgba(0, 0, 0, 0.8);
			    border-radius: 5px;
			    -moz-border-radius: 5px;
			    -webkit-border-radius: 5px;
			    bottom: 26px;
			    color: #fff;
			    content: attr(title-bv);
			    left: -40px;
			    padding: 5px 15px;
			    position: absolute;
			    z-index: 98;    
			    font-size: 8px;
			    white-space: normal;
				opacity:0;
				transition: opacity 0ms;
			}
			
			&:hover{
				&:after,
				&:before{
					opacity: 1;
					transition: opacity 2000ms;
				}
			}
		}
		
        b{
            font-weight: 500 !important;
        }
        ul{
            list-style: none;
            margin:0;
            padding:0;
        }
        a,
        a:link,
        a:hover,
        a:active,
        a:visited{
            /* color: #fff; */
            text-decoration:none;
            outline: none;
        }
        bt{
            cursor: pointer;
        }

        svg{
          display: block;
        /*   width:100%;
          height:100%; */
        }

        input{
            outline: none;
        }

        input[type=password]::-ms-reveal,
        input[type=password]::-ms-clear
        {
            display: none;
        }

        textarea {
            outline: none;
        }

        img {
            -webkit-user-drag: none;
        }

        .noselect{
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        .modal{
            overflow: hidden !important;
        }
        .modal-content{
            border: inherit;
            box-shadow: inherit;
            border-radius: inherit;
            -webkit-box-shadow: inherit;
            box-shadow: inherit;
            border-radius: 5px;
        }
        .modal-dialog{
            -webkit-box-shadow: 0 5px 15px rgba(0,0,0,.5);
            box-shadow: 0 5px 15px rgba(0,0,0,.5);
        }
        /* BV styles */
        bv-table{
            display:table;
        }
        bv-row{
            display: table-row;
        }
        bv-cell{
            display: table-cell;
        }
        .bv-height-100{
            height:100%;
        /* 	min-height:100%;	 */
        }
        .bv-width-100{
            width:100%;
        }
        .bv-width-10{
            width: 10%;
        }
        .bv-width-20{
            width: 20%;
        }
        .bv-width-30{
            width: 30%;
        }
        .bv-width-50{
            width: 50%;
        }
        .bv-width-60{
            width: 60%;
        }
        .bv-width-70{
            width: 70%;
        }
        .bv-bottom-10{
            margin-bottom:10px;
        }
        .bv-bottom-20{
            margin-bottom:20px;
        }
        .bv-top-10{
            margin-top:10px;
        }
        .bv-padding-15{
            padding:15px;
        }
        .bv-padding-20{
            padding:20px;
        }
        .bv-padding-5{
            padding:5px;
        }
        .bv-margin-left-30{
            margin-left: 30px;
        }
        .bv-svg-20{
            width: 22px;
            height: 20px;
        }
        .bv-svg-16{
            width: 18px;
            height: 16px;
        }
        .bv-svg-12{
            width: 14px;
            height: 12px;
        }
        .cursor-pointer:hover{
            cursor: pointer;
        }

        .bv-scrollbar{
            -webkit-overflow-scrolling: touch;
        /* 	-webkit-tap-highlight-color:rgba(0,0,0,0); */
        }
        .bv-scrollbar{
            overflow:auto;
        }
        .bv-position-relative{
            position: relative;
        }
        .bv-scrollbar::-webkit-scrollbar{
            background-color: rgba(214, 214, 214, 0.34);
            width: 6px;
            height:6px;
            border-radius: 10px;
        }
        .bv-scrollbar::-webkit-scrollbar-thumb{
            width: 6px;
            height:6px;
            /*background-color: #D5D5D5;*/
            background-color: #A5A2A2;
            border-radius: 10px;
        }
        .bv-scrollbar::-webkit-scrollbar-thumb:hover{
            background-color:#A5A2A2;
        }
        .bv-overflow-auto{
            overflow:auto;
            overflow-x: hidden;
        }
        .bv-overflow-hidden{
            overflow:hidden;
        }
        .font-weight-300{
            font-weight: 300;
        }
        .bv-font-weight-400{
            font-weight: 400;
        }
        .bv-font-weight-500{
            font-weight: 500;
        }
        .bv-display-block {
            display: block;
        }
        .bv-display-none {
            display: none;
        }

        [ng-color-picker] ul {
            padding: 0;
            margin: 0;
            width: 100%;
            display: table;
        }

        [ng-color-picker] li {
            width:20px;
            height: 30px;
            box-sizing:border-box;
            display: table-cell;
        }

        [ng-color-picker] li.selected {
            border: 1px solid #333;
        }
        .bv-modal-header {
          min-height: 16.42857143px;
          border-bottom: 1px solid #e5e5e5;
          padding: 5px 15px 5px 15px;
        }

        .bv-modal-header .close {
          margin-top: -2px;
        }
        .bv-modal-title {
            margin: 2px 0px 0px;
            color: #fff;
            font-size: 19px;
            letter-spacing: 0.6px;
            width: calc(100% - 20px);
            overflow: hidden;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-family: $nasdaqFontRegular;
        }
        .bv-modal-body {
          position: relative;
          height: calc(100% - 35px);
          /* padding: 5px 15px 5px 15px; */
         /*  overflow-y: auto; */
        }

        .bv-modal-footer {
          padding: 5px 15px 5px 15px;
          margin-top: 7px;
          text-align: center;
          border-top: 1px solid #e5e5e5;
        }

        .bv-modal-btn-close{
            right: 0px;
            position: absolute;
            top: 0px;
            cursor: pointer;
            background: $grayDarker;
            width: 49px;
            height: 50px;
            padding: 19px;
            border-top-right-radius: 5px;
        }
        .bv-modal-btn-close:hover{
            background: $grayDark;
        }

        .bv-modal-btn-close > svg{
            width: 11px;
            height: 11px;
            fill: #000;
        }


        .bv-modal-btn-back{
            position: absolute;
            float: left;
            top: -4px;
            padding: 17px 90px;
            left: -80px;
        }
        .bv-modal-btn-back  .bv-button-feedback{
            top: 4px;
            z-index: 1;
            left: 6.4px;
        }
        .bv-modal-btn-back > svg{
            height: 20px;
            width: 20px;
            fill: #fff;
        }

        .bv-modal-header-small{
          min-height: 27px;
          padding: 5px 10px;
        }

        .bv-modal-header-small .bv-modal-btn-close > svg{
            fill: #000;
            height: 15px;
            width: 15px;
        }

        .bv-input-label {
            font-size: 14px;
        /*	font-weight: 500;*/
            margin-bottom: 7px;
        }

        .bv-heading-label {
            font-size: 16px;
            font-weight: 500;
        }
        .bv-input-btn-small {
            min-width: 70px !important;
        }

        .bv-background-orange {
            background-color: #FF8100 !important;
        }

        .bv-background-green {
            background-color: #22a63e !important;
        }

        .bv-input-btn {
            min-width: 100px;
            height: 28px;
            border: none;
            margin: 0px 0px 0px 7.5px;
        /* 	font-family: Arial; */
            font-weight: 500;
            font-size: 12px;
            letter-spacing: 0.6px;
            line-height: 22px;
            color: #fff;
            background-color: #000;
            outline: none !important;
            white-space: nowrap;
            overflow: hidden;
        }

        .bv-input-text {
            padding: 0px 5px 0px 10px;
            border: #e5e5e5;
            background: #e5e5e5;
            height: 26px;
            letter-spacing: 0.6px;
        }
        .bv-input-text.comments {
	      	height: 100%;
	    }
        .bv-input-text:focus,
        .bv-input-text:active {
            -webkit-box-shadow:inset 0px 0px 0px 2px #99b2cc;
            -moz-box-shadow:inset 0px 0px 0px 2px #99b2cc;
            box-shadow:inset 0px 0px 0px 2px #99b2cc;
        }

        .cke_chrome {
            -moz-box-shadow: none;
            -webkit-box-shadow: none;
            box-shadow: none;
            border: none;
        }

        .bv-input-help {
            white-space: nowrap;
        }

        .bv-input-help-label {
            white-space: pre-wrap; /* This will take in IE and IE doesn't understand white-space: initial"; */
            white-space: inherit; /* This will overwrite above one in other than IE */
            color: #808080;
            display: inline-block;
            max-width: 97%;
            font-size: 14px;
        }

        .bv-input-btn-large{
            min-width: 120px;
        }

        .bv-input-btn-conf-black-small
        {
            color:#FFFFFF;
            min-width:65px;
            background-color: #000000;
            border-style:none;
            max-height: 23.7px;
            min-height: 23.7px;
        }

        .bv-input-btn-conf-stop-sharing
        {
            color:#FFFFFF;
            min-width:50px;
            background-color: #000000;
            border-style:none;
            max-height: 17px;
            min-height: 17px;
            margin: 1px 2px 3px 0px;
        }

        .bv-input-btn-conf-make-presenter {
            min-width: 97px;
            max-height: 17px;
            min-height: 17px;
            border: none;
            margin: 0px 2px;
        }

        /* .bv-input-btn-blue
        {
            color:#FFFFFF;
            min-width:125px;
            background-color: #3596BD;
            border-style:none;
            max-height: 45px;
            min-height: 35px;
        } */

        .bv-input-btn-blue:disabled
        {
            background-color:#D5D5D5;
        }
        /* .bv-input-btn-0{
            background-color: #000000;
            color:#FFFFFF;
        } */
        /* .bv-input-btn-black
        {
            color:#FFFFFF;
            min-width:125px;
            background-color: #000000;
            border-style:none;
            max-height: 45px;
            min-height: 35px;
        }
         */
        .bv-blue-msg-dialog-body {
            width:415px;
            position: relative;
            background-color: #3596BD;
            text-align: center;
            color: #FFF;
            font-size: 18px;
            font-weight: normal;
            padding: 15px 0px 5px;
        }
        .bv-blue-msg-dialog-content {
            position: relative;
            background-color: #3596BD;
            background-clip: padding-box;
            border: 1px solid rgba(0, 0, 0, 0.2);
            outline: medium none;
        }
        .bv-blue-msg-dialog-footer {
            width:415px;
            background-color: #3596BD;
            margin-top: 0px;
            text-align: center;
            padding: 5px 0px 15px;
        }

        .bv-modal-window-compose-new-message .modal-dialog {
            min-width: 1000px;
            width: 1000px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50.1%) !important;
            -webkit-transform: translate(-50%,-50.1%) !important;
            -ms-transform:  translate(-50%,-50.1%) !important;
            margin: 0;
        }
        .bv-support .modal-dialog {
            min-width: 390px;
            width: 390px;
        }

        .jup-desktop-client .bv-support .modal-dialog {
            min-width: 480px;
            margin: 40px auto;
        }
        .jup-desktop-client .bv-support .resetBtn {
            text-align:center;
        }

        .bv-modal-window-compose-new-message-recipents .modal-dialog {
            min-width: 450px;
        }
        .bv-modal-window-repository-rules .modal-dialog {
            min-width: 500px;
            max-height: 230px
        }
        .bv-modal-window-repository-rules-userSelection .modal-dialog {
            min-width: 710px;
            max-height: 230px
        }
        .bv-modal-window-repository-rename-selected-item .modal-dialog {
            min-width: 380px;
            max-height: 130px
        }
        .bv-text-align{
            text-align:center;
        }
        .bv-modal-blue-header {
            background-color: $grayDarkest !important;
            min-height: 50px;
            padding: 11px 12px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        .bv-modal-background{
          background: url('../assets/images/dust.png') repeat scroll 0% 0% transparent;
        }

        /* Context menu */
        .bv-context-menu{
            display: block;
            position: fixed;
            z-index: 1000;
            float: left;
            min-width: 160px;
            padding: 5px 0;
            margin: 2px 0 0;
            font-size: 14px;
            list-style: none;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ccc;
            border: 1px solid rgba(0, 0, 0, .15);
            border-radius: 4px;
            -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
            box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
        }

        .bv-context-menu ul{
            margin: 0px 0px 0px 0px;
            position: relative;
            line-height: 30px;
        }

        .bv-context-menu ul li {
            height: 30px;
            display: block;
            border:none;
            padding: 0px 0px 0px 17px;
        }
        .bv-context-menu ul li a{
            display:block;
            color: #515151;
        }
        .bv-context-menu ul li:hover{
            background: rgba(0,0,0,0.04);
            cursor: pointer;
        }

        .bv-left-float-checkbox
        {
            background: url("../assets/images/checkbox.png") no-repeat;
            min-width: 27px;
            max-width: 27px;
            min-height: 27px;
            max-height: 27px;
            float: left;
            margin-top: 10px;
            margin-left: 4px;
        }

        .bv-left-float-checkbox.checked {
            background: url("../assets/images/checkbox_on.png") no-repeat;
            min-width: 27px;
            max-width: 27px;
            min-height: 27px;
            max-height: 27px;
            float: left;
            margin-top: 10px;
            margin-left: 4px;
        }

        .bv-select-user {
            background-color: white;
            min-height: 280px;
            max-height: 280px;
            overflow-x: hidden;
            overflow-y:auto;
        }

        .bv-select-user-img {
            min-width: 35px;
            max-width: 35px;
            min-height: 35px;
            max-height: 35px;
            margin-top: 4px;
            float:left;
        }

        .bv-select-user-name {
            padding-top: 12px;
            padding-left: 0px;
            float: left;
            min-width: 150px;
            min-height: 35px;
        }

        .bv-select-user-search {
            min-width:320px;
            overflow-x: hidden;
            overflow-y: hidden;
        }

        .bv-select-user-blue
        {
            color:#FFFFFF;
            min-width:65px;
            background-color: #3596BD;
            border-style:none;
            max-height: 23.7px;
            min-height: 23.7px;
            border-radius: 4px;
        }

        .bv-cursor-pointer
        {
            cursor: pointer;
        }

        .bv-cursor-not-allowed
        {
            cursor: not-allowed;
        }

        .bv-left {
            float:left;
        }

        .bv-right {
            float:right;
        }

        .bv-red-color{
            color:red;
        }
        .bv-green-color{
            color:green;
        }
        .bv-orange-color{
            color:orange;
        }

        .bv-input-btn-box{
            text-align:center;
            padding: 0px 20px 20px 20px;
            position: relative;
            z-index: 1;
            background: #fff;
            text-align: right;
            width: 100%;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        .bv-permissions .modal-dialog{
            width: 600px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50.1%) !important;
            -webkit-transform: translate(-50%,-50.1%) !important;
            -ms-transform:  translate(-50%,-50.1%) !important;
            margin: 0;
        }

        .bv-permissions .bv-table-drowing-lines{
            height: 300px;
            width: 100%;
        }
        .bv-permissions .bv-table-drowing-lines li{
          height: 100%;
          position: absolute;
          border-right: 1px solid #DFDFDF;
        }
        .bv-permissions .bv-table-drowing-lines li:first-child{
          width: 80px;
        }
        .bv-permissions .bv-table-drowing-lines li:last-child{
           width: 310px;
            left: 80px;
        }


        .bv-permissions .bv-item-list-grid{
            /*height: 315px;*/
            height: 270px;
            overflow-y: auto;
        }
        .bv-permissions .bv-item-list-grid .bv-item-list-ul1 { height:305px;}
        .bv-permissions .bv-item-list-grid .bv-item-list-ul1 .bv-item-list-ul2-li-checkbox     { line-height:40px; height:40px !important; display:block; position:absolute; width:50px !important; left:210px !important; }


        .bv-permissions [bv-item-list]{
            padding: 10px;
            /*height:inherit;*/
        }

        .bv-permissions .bv-item-list-main{
            height: calc(100% - 47px);
            padding: 5px 10px;
        }

        .bv-permissions .bv-item-list-main > div{
            height: 100%;
        }

        .bv-permissions .bv-item-list-main > div > div:last-child{
            height: 100% !important;
        }

        .bv-permissions .bv-dialog-folder-list {
            padding: 10px 0px 10px 0px;
            box-shadow: none;
        }
		.bv-dialog-folder-list .mCS-dark {
			right: 0px !important;
		}

        .bv-permissions .bv-dialog-folder-list .shadowBox {
            height: 100%;
            position: absolute;
            right: -7px;
            width: 7px;
            /*border: 1px solid;*/
            z-index: 1;
            top: 0px;
        }

        .bv-permissions .bv-dialog-user-list .shadowBox {
            height: 100%;
            position: absolute;
            left: -7px;
            width: 7px;
            /*border: 1px solid;*/
            z-index: 1;
            top: 0px;
        }

        .bv-permissions .bv-dialog-user-list .bv-input-btn-box{
            background: inherit;
            position: absolute;
            bottom: 0;
        }
        .bv-permissions .bv-dialog-user-list{
            padding: 0px;
            box-shadow: none;
           
        }
        .bv-permissions .bv-heading-label{
            font-size: 15px;
        }
        .bv-permissions .bv-item-list-name{
            font-size: 16px;
            font-weight: inherit;
                
        }
        .bv-permissions .bv-tree-node-selected-chooser{
            background:#dbdde1;
        }
        .bv-permissions .bv-dropdown .btn .caret{
            top: 5px;
        }
        .bv-permissions .treeNode{
            height: 49px;
        }
        .bv-permissions .treeNode:hover {
            background: #eaedee;
            cursor: pointer;
        }
        .bv-permissions .folder-attachment-radio{
            display: none;
        }
        .bv-permissions .treeNode .treeImage{
            margin-left: 15px;
        }
        .bv-permissions .treeNode .folder-attachment-treeNode-name-div{
            float: none;
        }
        .bv-permissions [folder-tree-selection]{
            height: 100%;
            width: 100%;
            overflow: auto;
        }

        .bv-permission-screen{
            z-index: 2;
            background: #fff;
            position: relative;
            padding: 10px;
            &.permission-uploader {
                padding: 8px;
                padding-right: 12px;
            }
        }
        .bv-permission-screen label{
            font-weight: 500;
            font-size: 0.9em;
            margin: 0;
            display: inline;
        }
        .bv-permission-screen > span{
            font-size: 16px;
            font-weight: bold;
            line-height: 60px;
            padding-left: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            display: block;
        }
        .bv-permission-screen .bv-dialog-folders-btn{
            width: 10px;
            height: 10px;
            background: #ccc;
        }
        .bv-permission-screen .bv-search-users{
            position:relative;
            float: right;
            margin-bottom: -5px;
            margin-top: -10px;
        }
        .bv-permission-screen .bv-search-users img{
            width: 15px;
            left: 5px;
            top: 5px;
            position: absolute;
        }
        .bv-permission-screen .bv-search-users input{
            padding-left: 22px;
        }

        .bv-permission-screen bv-cell{
            padding:0px 5px;
        }
        .bv-permission-screen bv-row{
            background: #EBEBEB;
        }
        .bv-permission-screen bv-row.title{
            background: #E0E0E0;
        }
        .bv-permission-screen bv-cell:last-child{
            width:20px;
        }
        .bv-permission-screen bv-cell:last-child div{
            cursor:pointer;
        }
        .bv-permission-screen .dropdown-menu{
            max-height: 310px;
            min-width: 150px;
            overflow-y: auto;
            overflow-x: hidden;
            white-space: nowrap;
            border-radius:0px !important;
        }
        .bv-permission-screen [icon-renderer] img{
            width:15px;
        }

        .bv-permission-screen .dropdown-menu{
            padding: 2px 0px;
        }
        .bv-permission-screen .dropdown-menu div[icon-renderer]{
            display: inline-block;
            top: -2px;
            position: relative;
            height: 15px;
        }
        .bv-permission-screen .bv-dialog-title-box{
            height: 20px;
            padding: 0px 5px;
        }

        .bv-permission-screen .bv-search-title-box{
            height: 32px;
            padding: 3px 5px;
        }

        .bv-permission-screen .bv-dialog-users-icon{
            width: 20px;
            height: 20px;
            float: right;
            cursor:pointer;
        }

        .bv-permission-screen .bv-dialog-user-btn,
        .bv-permission-screen .bv-dialog-folder-btn{
            position: relative;
            cursor: pointer;
        }
        .bv-permission-screen .bv-dialog-folder-btn{
            float: left;
            padding-left: 20px;
        }
        .bv-permission-screen .bv-dialog-user-btn{
            float: right;
            padding-right: 20px;
        }

        .bv-permission-screen .bv-dialog-user-btn svg,
        .bv-permission-screen .bv-dialog-folder-btn svg{
            width: 17px;
            height: 20px;
            top: 0px;
            position: absolute;
            cursor: pointer;
        }

        .bv-permission-screen .bv-dialog-user-btn svg{
            right: 0;
        }
        .bv-permission-screen .bv-dialog-folder-btn svg{
            left: 0;
        }

        /* .bv-permission-screen .bv-dialog-users-btn.opened{
            transform: rotate(180deg);
        } */
        .bv-permission-screen .dropdown-menu span{
            padding-left: 20px;
        }
        .bv-permission-screen .dropdown-menu li:hover{
            background: rgba(0,0,0,0.04);
            cursor: pointer;
        }
        .bv-permission-screen .bv-table-css{
            font-size: 0.8em;
            line-height: 21px;
            position: absolute;
            top: 0;
            width: 100%;
        }

        .bv-permission-screen .bv-table-css > ul > li{
            margin: 0px 10px;
            height: 27px;
            border-bottom: 1px solid #DFDFDF;
            font-size: 15px;
            font-weight: 500;
        }
        .bv-permission-screen .bv-table-css .bv-table-title-col-1{
            width: 55px;
        }
        .bv-permission-screen .bv-table-css .bv-table-title-col-2{
                width: 280px;
            left: 85px;
        }
        .bv-permission-screen .bv-table-css .bv-table-title-col-3{
           width: 140px;
           left: 390px;
        }

        .bv-permission-screen .bv-table-css > ul:first-child{
            height: 30px;
        /* 	border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc; */
            position: relative;
        }
        .bv-permission-screen .bv-table-css > div > ul{
            position: relative;
            padding: 15px 0px;
        }

        .bv-permission-screen .mCSB_container{
            overflow: inherit;
        }

        .bv-permission-screen {
            .bv-permissions-list {
                position: relative;
                padding: 15px 0px;
                padding-bottom: 105px;
            }

            .bv-permissions-list > li {
                height: 42px;
                font-size: 14px;
            }

            .bv-permissions-list-container {
                width: 97%;
            }

            &.permission-uploader {
                .bv-permissions-list {
                    padding: 5px 0px;
                    padding-bottom: 105px;
                }

                .bv-permissions-list > li {
                    border-top: 1px solid #DFDFDF;
                    padding-top: 16px;
                    height:50px;
                }

                .bv-permissions-list-container {
                    width: 100%;
                }
            }
        }

        .bv-permissions-list {
            position: relative;
            padding: 15px 0px;
        }

        .bv-permission-screen .bv-table-css > ul,
        .bv-permission-screen .bv-table-css > div{
            height: calc(100% - 40px);
            overflow-y: auto;
            overflow-x: hidden;
        /* 	border-bottom: 1px solid #ccc; */
        }
        /* .bv-permission-screen .bv-table-css > ul > li{
            height: 27px;
        } */
        /* .bv-permission-screen .bv-table-css > ul > li:last-child{
            height: 23px;
            border-bottom: 1px solid #ccc;
        } */

        .bv-permission-screen .bv-table-css > .bv-scrollbar{
            width: 97%;
        }

        .bv-permission-screen .bv-table-css > ul:first-child > li,
        .bv-permission-screen .bv-table-css > div > ul > li > ul > li,
        .bv-permission-screen .bv-permissions-list > li > ul > li {
        /* 	border-left: 1px solid #ccc; */
            display:block;
        /* 	padding-left: 3px;
            height: 22px; */
            position:absolute;
        /* 	background: #F2F2F2; */
            border-right: inherit;
            margin: 0px 10px;
        }
        .bv-permission-screen .bv-table-css.bv-table-3-cols div > ul > li > ul > li{
            background: #F7F6E5;
        }
        .bv-permission-screen .bv-table-css .bv-table-col-2{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .bv-permission-screen .bv-table-css > div > ul > li{
            height: 42px;
            font-size: 16px;
        }
        


        .bv-permission-screen .bv-table-css > ul:first-child > li:last-child,
        .bv-permission-screen .bv-table-css > div > ul > li > ul > li:last-child{
        /* 	border-right: 1px solid #ccc; */
        }
        .bv-permission-screen .bv-table-css.bv-table-3-cols .bv-table-col-1{
            width: 39px;
        }
        .bv-permission-screen .bv-table-css.bv-table-3-cols .bv-table-col-2{
            width: 219px;
            left: 35px;
        }
        .bv-permission-screen .bv-table-css.bv-table-3-cols .bv-table-col-3{
            width: 76px;
            left: 254px;
        }
        .bv-permission-screen .bv-table-css.bv-table-4-cols .bv-table-col-1{
            width: 55px;
        }
        .bv-permission-screen .bv-table-css.bv-table-4-cols .bv-table-col-2{
              width: 280px;
            left: 85px;
        }
        .bv-permission-screen .bv-table-css.bv-table-4-cols .bv-table-col-3{
               width: 140px;
    			left: 390px;
    			    margin: 0;
        }
        .bv-permission-screen .bv-table-css.bv-table-4-cols .bv-table-col-4{
            width: 18px;
               left: 535px;
        }

        .bv-permission-screen .bv-table-css div[icon-renderer]{
            top: 4px;
            left: 5px;
            position: relative;
        }
        .bv-permission-screen .bv-table-css select{
            width: 97%;
            border: 1px solid #ccc;
        }
        .bv-permission-screen .bv-table-css select.empty{
            border: 1px solid red;
        }
        .bv-permission-screen .bv-table-css .bv-dialog-remove-btn{
            text-align: center;
            cursor: pointer;
        }
        .bv-permission-screen .bv-table-css .bv-dialog-remove-btn svg{
            height: 18px;
        }
        .bv-permission-screen .bv-table-css .bv-dialog-remove-btn svg.icon polygon{
            transform: scale(0.8);
            -webkit-transform: scale(0.8);
        }


        .bv-permission-screen .bv-permission-apply-to-subfolders {
            position: absolute;
            bottom: -30px;
            margin: 0px 10px;
            display: inline-block;
        }
        .bv-permission-screen .bv-permission-apply-to-subfolders > div{
            width: 24px;
            height: 20px;
            position: relative;
            display: inline-block;
            top: 3px;
            cursor: pointer;
        }
        .bv-permission-screen .bv-permission-apply-to-subfolders > label{
            font-size: 13px;
        }
        .bv-permission-screen .bv-dropdown > .btn{
            padding-top: 0;
            padding-bottom: 0;
            margin-top: -5px;
            font-size: 14px;
            font-weight: inherit;
            background: #fff;
            color: #000;
            -webkit-transition: inherit;
            -moz-transition: inherit;
            -o-transition: inherit;
            transition: inherit;
        }
        .bv-permission-screen .bv-dropdown > .btn.empty{
            border: 1px solid red;
        }

        .bv-permission-screen .bv-dropdown > .btn .caret{
            opacity: 0;
        }
        .bv-permission-screen .bv-dropdown > .btn:hover{
            background: inherit;
        }
        .bv-permission-screen .bv-dropdown.open > .btn{
            background: #DFDFDF;
        }
        .bv-permission-screen .bv-dropdown > .btn:hover .caret,
        .bv-permission-screen .bv-dropdown.open > .btn .caret{
            opacity: 1;
        }
        .bv-permission-screen .bv-dropdown > .btn:active,
        .bv-permission-screen .bv-dropdown > .btn:focus{
            box-shadow: inherit;
        }
        .bv-permission-screen .dropdown-menu{
          box-shadow: inherit;
          top: 20px;
          border-top: inherit;
          border-left: inherit;
          font-size: 16px;
        }
        .bv-permission-screen .dropdown-menu li{
            height: 30px;
        }
        .bv-permission-screen .dropdown-menu li a{
            padding-left: 5px;
        }

		%sort-arrow-base {
	  		content: "";
	        display: inline-block;
	        margin-left: 4px;
	        position: relative;
	        top: -1px;
	        border-right: 4px solid transparent;
	        border-left: 4px solid transparent;
		}
		
		
        	.sortheader{
        		cursor: pointer;
        		user-select: none;
        		&.sort-up::after{
			        @extend %sort-arrow-base;
			        border-top: 6px solid #000;
		        }
		        &.sort-down::after{
			        @extend %sort-arrow-base;
			        border-bottom: 6px solid #000;
		        }
		        &.sortable::after{
			        @extend %sort-arrow-base;
			        border-top: 6px solid $gray;
		        }
        	}
        
        
        .bv-dialog-user-list .treeNode{
            padding:0;
        }
        .bv-dialog-user-list .tab-pane > div[data-tree-id="mytree"]{
            padding:0;
        }
        .bv-dialog-user-list div[data-tree-id="mytree"]{
            padding-left: 10px;
        }
        .bv-dialog-user-list .treeNode span{
            padding-left: 3px;
        }

        .bv-dialog-user-list,
        .bv-dialog-folder-list {
            position: absolute;
            top: 0px;
            z-index: -1;
            /* margin-left: -360px; */
            /* margin-left: 360px; */
            width: 300px;
            height: calc(100% + 48px);
            background: #fff;
            /* box-shadow: 0 5px 15px rgba(0, 0, 0, .5); */
            padding: 10px;
            box-shadow: 90px 0px 50px -100px #000 inset;
            overflow: hidden;
        }
        .bv-dialog-folder-list{
            box-shadow: -90px 0px 50px -100px #000 inset;
        }
        .bv-dialog-folder-list .bv-tree-node,
        .bv-dialog-folder-list ul li{
            cursor: pointer;
        }
        .bv-dialog-folder-list.show{
            margin-left: -300px;
        }
        .bv-dialog-user-list.show{
            margin-left: 600px;
        }
        .bv-dialog-user-list span{
            font-weight: 500;
            font-size: 0.9em;
        }
        .bv-dialog-user-list .nav-tabs{
            border:0;
        }
        .bv-dialog-user-list .nav-tabs > li > a{
            padding: 5px;
            border-radius: 0px;
            border: 0;
        }
        .bv-dialog-user-list .tab-pane{
            position: absolute;
            overflow: auto;
            height: calc(100% - 72px);
            left: 10px;
            right: 10px;
            margin: 10px 0px;
        }
        .bv-dialog-user-list div[icon-renderer]{
            display:inline-block;
            top: 3px;
            position: relative;
        }
        .bv-dialog-user-list div[icon-renderer] img{
            width: 15px;
            padding:0;
        }
        .bv-dialog-user-list a[tab-heading-transclude]{
            font-weight: 400 !important;
        }
        .bv-dialog-user-list .nav-tabs > li > a{
            line-height: 14px;
        }

        .bv-dialog-close-x {
            color: #fff !important;
            text-decoration: none !important;
            font-weight: 100;
            display: block;
            font-size: 35px;
            opacity: .8;
            padding: 0 !important;
            background: none !important;
            margin-top: -10px;
            -webkit-appearance: none;
            cursor: pointer;
            border: 0;
            line-height: 1;
            text-shadow: 0 1px 0 #fff;
        }

        .bv-dialog-action-buttons {
            position:absolute;
            bottom:20px;
            right:20px;
            margin: 0 !important;
        }

        .bv-sup-red-astric {
            color: #F00;
            font-size: 16px;
            font-weight: 500;
            display: inline-block;
            vertical-align: top;
        }

        .bv-sup-red-astric-small {
            color: #F00;
            font-size: 10px;
            font-weight: 500;
            display: inline-block;
        }

        .bv-required-notice-label {
            color: #808080;
            font-style: italic;
            font-size: 11px;
            display: inline-block;
            max-width: 97%;
        }

        [bv-item-list] {
             height: 450px;
        }

        .bv-preferences .bv-modal-body > div,
        .bv-challenge-questions .bv-modal-body > div,
        .bv-change-password .bv-modal-body > div,
        .bv-virtual-meeting-configuration .bv-modal-body > div,
        .bv-support .bv-modal-body > div{
            padding: 20px;
        }

        .bv-challenge-questions .modal-dialog{
            width: 355px;
            height: 308px;
        }

        .bv-change-password .modal-dialog{
            width: 403px;
        }

        .bv-virtual-meeting-configuration .modal-dialog{
            display: inline-block;
            width: 691px;
        }

        .bv-support{
            .bv-modal-body > div > div{
                margin-bottom: 20px;
            }

            .bv-support-center{
                margin-bottom: 5px !important;
                display: inline-block;
            }

            .bv-knowledge-center{
                float: right;
                margin: 0;
            }
        }

        .bv-dropdown{
            width: 100%;
            position: relative;
        }
        .bv-dropdown > .btn{
            width: 100%;
            text-align: left;
            border-radius: 0;
            color: #000;
            height: 26px;
            background: rgba(224, 221, 221, 0.38);
            outline: none !important;
            font-size: 16px;
            font-weight: inherit;
            border: none;
            line-height: 11px;
            margin: 0;
        }

        .bv-dropdown.open > .btn{
            background: rgba(224, 221, 221, 0.38);
            color: #000;
        }
        .bv-dropdown > .btn:hover{
            background: #E5E5E5;
            color: #000;
            box-shadow: none;
        }
        .bv-dropdown .btn .caret{
            right: 10px;
            position: absolute;
            top: 10px;
            border-top: 8px solid #000;
            border-right: 7px solid transparent;
            border-left: 7px solid transparent;
        }
        .bv-dropdown .dropdown-menu{
            left: inherit !important;
            left: 0px;
            width: 100%;
        }

        .bv-dropdown .dropdown-menu > li > a{
            color: #000;
            font-size: 16px;
        }
        .bv-dropdown .dropdown-menu > li > a:hover{
            background-color: #2273a5;
            color: #fff;
        }

        .bv-change-password .bv-login-input-text{
            background-color: #ccc;
            box-shadow: inherit;
        }

        .bv-preferences{
            .preferences-menu li{
                margin: 0;
                padding-bottom: 10px;
            }
            .preferences-menu li:last-child{
                padding: 0;
            }
            a{
                color: #515151;
            }

            .modal-dialog{
                width: 430px;
            }

            .follow-followsme{
                display: block;
            }

        }
        
        .bv-confirm .modal-dialog{
            width: 335px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50.1%) !important;
            -webkit-transform: translate(-50%,-50.1%) !important;
            -ms-transform:  translate(-50%,-50.1%) !important;
            margin: 0;
        }

	
		 .bv-confirm-signature-edit .modal-dialog{
            width: 400px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50.1%) !important;
            -webkit-transform: translate(-50%,-50.1%) !important;
            -ms-transform:  translate(-50%,-50.1%) !important;
            margin: 0;
        }
        
        .invite-user-width .modal-dialog{
        		width: 435px;
        }
        
        .bv-confirm .bv-confirm-content{
            /*min-height: 82px;*/
            font-size: 16px;
            width: 100%;
            margin: 0 auto;
            padding: 25px 30px 25px 30px;
            font-weight: 500;
            text-align: center;
            word-wrap: break-word;
            max-height: 60vh;
            overflow: auto;
        }
        .bv-confirm .bv-input-btn-box{
            text-align: center;
            padding: 18px 20px;
        }
        
        .bv-confirm-custom-button .modal-dialog{
            width: 457px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50.1%) !important;
            -webkit-transform: translate(-50%,-50.1%) !important;
            -ms-transform:  translate(-50%,-50.1%) !important;
            margin: 0;
        }
		
		.bv-confirm-custom-button-signature .modal-dialog{
            width: 500px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50.1%) !important;
            -webkit-transform: translate(-50%,-50.1%) !important;
            -ms-transform:  translate(-50%,-50.1%) !important;
            margin: 0;
        }
        
        .bv-confirm-custom-button .bv-confirm-content{
            font-size: 16px;
            width: 100%;
            margin: 0 auto;
            padding: 25px 10px 25px 10px;
            font-weight: 500;
            text-align: center;
            white-space: pre;
        }
        
        .bv-confirm-custom-button .bv-confirm-content.bv-confirm-bankId{
            font-size: 14px;
            white-space: initial;
           	font-weight: 400;
        }
        
        .bv-confirm-message{
        	white-space: normal;
        }
        
        .bv-confirm-custom-button.signature-commit-dialog{
          .bv-confirm-content{
            white-space: pre-wrap;
          }  
        }

        .bv-recycle{
        /* 	top: inherit;
            left: inherit; */
        }
        .bv-recycle .modal-dialog{
            /* margin: 0;
            width: 650px;
            position: fixed;
            bottom: 55px;
            right: 10px; */
            position: absolute;
            width: 650px;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50.1%) !important;
            -webkit-transform: translate(-50%,-50.1%) !important;
            margin: 0;
            height:30px;
        }
        /* .bv-recycle-back{
            position: inherit;
        } */

        .bv-show-message .bv-confirm-content{
            padding-bottom: 30px;
            padding-top: 30px;
        }

        .bv-copy-link .modal-dialog {
            width: 550px;
        }
        .bv-copy-link .bv-modal-body > div{
            padding: 20px;
            /*height: 110px;*/
            height: 70px;
        }

        .bv-create-folder {
            .bv-modal-body {
                .bv-create-folder-content {
                    padding: 20px;
                    height: 300px;
                }

                .bg-create-folder-content-auto-height {
                    height: auto;
                }

                input {
                    border-radius: 0;
                }
            }

            .modal-dialog {
                width: 850px;
                position: absolute; margin: 0; top: 50%; left: 50%; transform: translate(-50%,-50%) !important; -webkit-transform: translate(-50%,-50%) !important; -ms-transform:  translate(-50%,-50%) !important;
            }

            .view-permissions-button {
                margin-left: 20px;
            }

            .bv-textFitButton {
                cursor: pointer;
                border-color: #35abc3;
                border-radius: 5px;
                border-width: 2px;
                border-style: solid;
                padding: 5px;
                color: #35abc3;
                font-weight: 700;
                white-space: nowrap;
            }

            .bv-create-folder-permissions {
                display: block;
                width: 50%;
                position: absolute;
                top: 0;
                left: 50%;
                height: 100%;
                border-left: 1px solid #DFDFDF;

                .bv-dialog-title-box {
                    margin-bottom: 20px;
                    font-weight: 700;
                    padding: 10px;
                    height: 28px;
                }

                .bv-dialog-search-box{
                    border-top: 1px solid #DFDFDF;
                    padding-top: 8px;
                    padding-bottom: 2px;
                }

                .itemListFilterInput {
                    background: #ffffff;
                    height: 34px;
                }

                .bv-dialog-close-btn {
                    width: 14px;
                    float: right;
                    cursor: pointer;

                    .icon {
                        visibility: visible;
                    }
                }

                .bv-pemission-overlay-list {
                    height: calc(100% - 86px);

                    .bv-table-css >div {
                        height: 100%;
                    }
                }

                .btn .caret {
                    top: 4px !important;
                }

                .bv-table-title-col-2, .bv-table-col-2 {
                    width: 235px !important;
                    left: 35px !important;
                }

                .bv-table-title-col-3, .bv-table-col-3 {
                    width: 110px !important;
                    left: 270px !important;

                    &.search-user {
                        left: 295px !important;
                    }
                }

                .bv-table-title-col-4, .bv-table-col-4 {
                    width: 18px !important;
                    left: 376px !important;
                }
            }
        }

        .bv-rename {
            .bv-create-folder-content{
                height: auto!important;
                display: block!important;
                padding: 20px;
            }
            .bv-modal-body {
             .form-label{
                 font-size: 12px;
                 text-transform: capitalize;
             }  
             .bv-input-text{
                border: 1px solid #B6B7BA;
                box-sizing: border-box;
                border-radius: 5px;
                height: auto!important;
                padding: 5px 10px;
                background: #fff!important;
                -webkit-box-shadow: none!important;
                box-shadow: none!important;
             }
            }

            .modal-dialog {
                width: 640px;
                position: absolute; margin: 0; top: 50%; left: 50%; transform: translate(-50%,-50%) !important; -webkit-transform: translate(-50%,-50%) !important; -ms-transform:  translate(-50%,-50%) !important;
            }
        }
        .bv-slider{
            height: 100%;
            position: relative;
            overflow-x: hidden!important;
        }
        .bv-slider > ul {
            height: 100%;
            white-space: nowrap;
            overflow-x: hidden;
            overflow-y: hidden;
            margin: 0px 45px;
            padding: 0 0.1%;
        }
        .bv-slider > ul > li{
            display: inline-block;
            height: calc(100% - 40px);
            width: 31.2%;
            margin: 20px 2%;
            box-shadow: 0px 0px 3px 0px #6f6f6f;
            border-radius: 6px;
            position: relative;
        }
        .bv-slider > ul > li > div{
            position: absolute;
            width: 100%;
            height: 100%;
        }

        .bv-slider > ul > li > card{
            position: relative;
            display: block;
            width: 80%;
            margin: 0 auto;
            height: 100%;
            box-shadow: 0px 0px 15px -3px #000;
        }

        /* .bv-slider .bv-slider-items-4 > li {
            width: 26%;
        }

        .bv-slider .bv-slider-items-2 > li {
            width: 46%;
        }

        .bv-slider .bv-slider-items-1 > li {
            width: 97% !important;
        }*/

        .bv-slider > div {
            position: absolute;
            top: calc(50% - 45px);
            width: 40px;
            height: 50px;
            padding-top: 5px;
            /*fill: #E5E5E5;*/
        }
        .bv-slider > div > svg {
            fill: #111111;
        }
        .bv-slider > div:hover {
            cursor: pointer;
            fill: #aaa;
        }
        .bv-slider > div.small{
            width: 12px;
            height: 18px;
            top: calc(50% - 18px);
        }

        .bv-slider > div.bv-slider-left-btn{
            left: 10px;
        }
        .bv-slider > div.bv-slider-right-btn{
            right: 10px;
        }

        .bv-arrow-left {
            width: 0;
            height: 0;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
            border-right:20px solid #fff;
        }
        .bv-arrow-right {
            width: 0;
            height: 0;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
            border-left:20px solid #fff;
        }

        /*
        .bv-item-list-check-div {
            height:35px; width:35px; padding-left: 6px; padding-top: 5px; margin-left: 21px;
        }*/


        .bv-item-remove-button {
            display: inline-block;
            width: 17px;
            height: 17px;
            margin: 0px 0px 0 0;
            vertical-align: middle;
            cursor: pointer;
            fill: #000000;
            position: relative;
        }

        .grayFilter {
          -webkit-filter: grayscale(0.5);
          filter: grayscale(0.5);
        }

        .bv-default-background-color-blue
        {
            background-color: #2273a5;
        }

        .bv-default-background-color-gray
        {
            background-color: #BBBBBB !important;
        }

        .bv-default-background-color-gray-dark
        {
            background-color: #808080;
        }

        .bv-default-background-color-gray-light
        {
            background-color: #E5E5E5;
        }

        .bv-default-background-color-gray-lighter
        {
            background-color: #F4F4F4;
        }

        .bv-default-background-color-black
        {
            background-color: #000000;
        }

        .bv-background-color-red
        {
            background-color: #AB0808;
        }

        .bv-background-color-green
        {
            background-color: #016100;
        }
        .bv-default-fill-color-blue
        {
            fill: #2273a5;
        }

        .bv-default-fill-color-blue-plusIcon{
            cursor:pointer;
        }
        .bv-default-fill-color-blue-plusIcon #inner_circle,
        .bv-default-fill-color-blue-plusIcon:hover #plus_symbol{
            fill: #ffffff;
        }
        .bv-default-fill-color-blue-plusIcon #outer_circle,
        .bv-default-fill-color-blue-plusIcon #plus_symbol{
            fill:#2574A7;
        }
        .bv-default-fill-color-blue-plusIcon:hover #inner_circle{
            fill:#2574A7 !important;
        }
        .bv-default-fill-color-blue-plusIcon:hover #inner_circle,
        .bv-default-fill-color-blue-plusIcon:hover #plus_symbol{
          transition: 200ms;
          transition-timing-function: ease-in-out;
          -webkit-transition: 200ms;
          -webkit-transition-timing-function: ease-in-out;
        }
        .bv-mobile .bv-default-fill-color-blue-plusIcon:hover #inner_circle{
            fill: #ffffff !important;
        }
        .bv-mobile .bv-default-fill-color-blue-plusIcon:hover #plus_symbol{
            fill: #2574A7;
        }

        .bv-default-fill-color-gray
        {
            fill: #BBBBBB;
        }

        .bv-default-fill-color-gray-dark
        {
            fill: #808080;
        }

        .bv-default-fill-color-gray-light
        {
            fill: #E5E5E5;
        }

        .bv-padding-10 {
            padding-left:10px;
            padding-right:10px;
        }

        .bv-padding-top
        {
            padding-top:17px
        }
        .bv-default-fill-color-black
        {
            fill: #000000;
        }

        .bv-default-fill-color-white
        {
            fill: #FFFFFF;
        }

        .bv-default-font-color-red
        {
            color: #F00;
        }

        .bv-default-font-color-blue
        {
            color: #2273a5;
        }

        .bv-default-font-color-gray
        {
            color: #BBBBBB;
        }

        .bv-default-font-color-gray-dark
        {
            color: #808080;
        }

        .bv-default-font-color-gray-light
        {
            color: #E5E5E5;
        }
        .bv-default-font-color-black
        {
            color: #000000;
        }

        .bv-admin-auth .modal-dialog{
            width: 540px;
        }
        .bv-admin-auth .modal-dialog .bv-modal-body > div{
            height: 350px;
        }
        .bv-admin-auth-select > span{
            font-weight: 600;
        }
        .bv-admin-auth-select > div{
            padding-left: 17px;
        }

        .grayText{
            color: lightgray;
        }
        .bv-header-company-name{
            text-overflow: ellipsis;
            width: 178px;
            height: 20px;
            overflow: hidden;
            text-align: center;
            display: inline-block;
            white-space: nowrap;
            margin-top: 33px;
        }

        .border-black-box {
            border: 1px solid #000;
        }

        .border-gray-box {
            border: 1px solid #e5e5e5;
        }

        .border-box {
            border: 1px solid #e5e5e5;
        }

        .border-bottom {
            border-bottom: 1px solid #e5e5e5;
        }

        .border-right {
            border-right: 1px solid #e5e5e5;
        }

        .border-top {
            border-top: 1px solid #e5e5e5;
        }

        .border-left {
            border-left: 1px solid #e5e5e5;
        }

        .bv-svg-check-true
        {
            height:22px; width:22px;
            cursor: pointer;
            overflow: hidden;
        }

        .bv-svg-check-false
        {
            height:22px; width:22px;
            cursor: pointer;
        }

        .bv-svg-option-true
        {
            height:16px;
            width:18px;
            cursor: pointer;
        }
        .bv-svg-option-true > svg{
            height:16px;
            width:18px;
        }

        .bv-svg-option-false
        {
            height:16px;
            width:18px;
            cursor: pointer;
        }
        .bv-svg-option-false > svg{
            height:16px;
            width:18px;
        }

        .bv-doc-preview-portrait .modal-dialog {
            height:660px;
            width:480px;
            /*margin: 35px auto !important;*/
            position: absolute; margin: 0; top: 50%; left: 50%; transform: translate(-50%,-50.1%) !important; -webkit-transform: translate(-50%,-50.1%) !important; -ms-transform:  translate(-50%,-50.1%) !important;
        }

        .bv-doc-preview-container {
            height:625px;
            text-align: center;
        }

        .bv-doc-preview-img {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            -ms-transform:  translateY(-50%);
            max-height: 100%;
            max-width: 99%;
            width: auto;
        }
        /*
        .bv-doc-preview-container:before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            margin-right: -0.32em;
        }
        */

        .bv-doc-preview-landscape .modal-dialog{
            height:580px;
            width:625px;
            margin:70px auto !important;
        }
        .bv-doc-preview-landscape .modal-dialog > div > div > div > div > img {
            height:515px;
        }
        .brandingDialog .modal-dialog{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50.1%) !important;
            -webkit-transform: translate(-50%,-50.1%) !important;
            margin: 0;
            width: 1060px;
            height: 617px;
        }
        .center-div {
            display:table;
            text-align: center;
        }
        .center-div-content {
            display:table-cell;
            vertical-align:middle;
        }
        .no-content-msg-label {
            height:19px;
            line-height:19px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #aeaeae;
            padding-left: 20px;
        }


        .bv-context-menu-arrow-box {
            position: fixed;
            z-index: 1001;
        }

        .bv-context-menu-arrow {
            position: relative;
        }

        .bv-context-menu-arrow:before, .bv-context-menu-arrow:after {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-color: transparent;
            border-left: 0;
            position: absolute;
        }

        .bv-context-menu-arrow:before {
            border-right-color: rgba(128, 128, 128, 0.23);
            border-width: 16px;
            top: 0px;
            left: -7px;
        }

        .bv-context-menu-arrow:after {
            border-right-color: #fff;
            border-width: 14px;
            top: 1px;
            left: -4px;
        }

        .image-in-card,
        .image-in-card img{
            height: 100%;
            max-width: 100%;
            width: auto;
            vertical-align: middle;
            display: inline-block;
            top: 50%;
            position: relative;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            -ms-transform:  translateY(-50%);
            margin-left: auto;
            margin-right: auto;
            display: block;
        }
        .image-in-card > div,
        .image-in-card > div > svg,
        .image-in-card boardbook > svg{
            width: 100%;
            height: 100%;
        }
        .image-in-card img{
            height: auto;
            max-height: 100%;
        }
        .image-in-card img.pdf,
        .image-in-card img.txt {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
        .image-in-card > div > div > svg{
            width: 100%;
            height: 100%;
        }

        .bv-modules-settings .modal-dialog{
            width: 670px;
        }
        .modal:not(.bv-conf-details-panel):not(.ad-search-dialog):not(.bv-events):not(.meeting-details-react) .modal-dialog{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50.2%) !important;
            -webkit-transform: translate(-50%,-50.2%) !important;
            margin:0;
            border-radius: 5px;
        }

        .bv-modules-settings .bv-modal-body{
            padding: 20px;
        }
        .bv-modules-settings .bv-module-div1{
            height: 410px;
        }
        .bv-modules-settings .bv-module-ul{
            padding: 0px;
        }
        .bv-modules-settings li:last-child .bv-module-row{
            margin:0;
        }

        .bv-modules-settings .showModuleSettings .bvMenuColor{
            color: #000;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .sortingHandle {
            cursor: move;
            cursor: -moz-grab;
            cursor: -webkit-grab;
            cursor: grab;
        }

        .grabbing-item {
            cursor: move !important;
            cursor: -moz-grabbing !important;
            cursor: -webkit-grabbing !important;
            cursor: grabbing !important;
        }

        .ellipsis-overflow {
            overflow:hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .hover-background {
            /*background-color: #f9f9f9;*/
            /*background-color: rgba(0,0,0,0.04);*/
            background-color : #ebeef0;
        }

        .drag-handle {
            width: 14px;
            min-height: 10px;
            float: left;
            position: relative;
            top: 7px;
            padding-left: 4px;
        }

        .drag-handle-image {
            display: none;
            /*margin-top: 25px;*/
        }

        .bv-search-results-mobile .searchInputText{
            position: absolute;
            font-size: 15px;
            top: -37px;
            margin: 0 35px 0 10px;
            width: calc(100% - 55px);
            background-color: rgba(0, 0, 0, 0.25);
            color: #FFF;
            padding-left: 35px;
            height: 28px;
            box-shadow: inherit;
        }
        .bv-search-results-mobile .searchInputText::-webkit-input-placeholder{
            color: #FFF;
        }
        .bv-search-results-mobile .searchInputText:-moz-placeholder{
            color: #FFF;
        }
        .bv-search-results-mobile .searchInputText::-moz-placeholder{
            color: #FFF;
        }
        .bv-search-results-mobile .searchInputText:-ms-input-placeholder {
            color: #FFF;
        }

        .bv-search-results-mobile .bv-search-results-mobile-search-icon{
            width: 16px;
            height: 16px;
            top: -31px;
            position: absolute;
            left: 20px;
            fill: #fff;
            z-index: 1;
        }
        .bv-search-results-mobile .back-button .searchInputText{
            margin: 0 35px 0 45px !important;
        }
        .bv-search-results-mobile .back-button .bv-search-results-mobile-search-icon{
            left: 55px !important;
        }
        .bv-search-results-mobile back-button{
            top: -32px;
            left: 11px;
            position: absolute;
            fill: #fff;
        }

        .bv-small-font{
            font-size: 14px !important;
        }

        .bv-very-small-font {
            font-size: 12px !important;
        }

        .bv-modal-window-new-mobile-attachment .treeDoc,
        .bv-modal-window-new-mobile-attachment .document-filter-container,
        .bv-modal-window-new-mobile-attachment [bv-splitter]{
            display: none;
        }

        .bv-modal-window-new-mobile-attachment .file-chooser-padding{
            padding: 0;
        }
        .bv-modal-window-new-mobile-attachment .repositoryLeft{
            float: inherit;
            width: 100%;
        }
        .bv-modal-window-new-mobile-attachment .document-list-container{
            padding: 0;
            width: 100%;
        }
        .bv-modal-window-new-mobile-attachment [attachment-listing] > div > div{
            display: none;
        }

        .bv-modal-window-new-mobile-attachment [attachment-listing],
        .bv-modal-window-new-mobile-attachment .attachment-list{
            height: 100%;
        }

        .bv-modal-mobile-action-button {
            position: relative;
            float: left;
            margin-right: 10px;
        }

        .bv-modal-mobile-action-button > img {
            height: 22px;
        }

        .modal-dialog .bv-mobile-menu{
            width: 57px;
            height: 45px;
            right: 0px;
            position: absolute;
            top: 0px;
            display: block;
        }
        .modal-dialog .bv-mobile-menu > div{
            height: 45px;
        }
        .modal-dialog .bv-mobile-menu > div > svg{
            fill: #fff;
            stroke: #fff;
            stroke-width: 3;
            display:block;
            width: 40px;
            padding: 8px 29px;
            height: 46px;
            overflow: inherit;
        }
        .modal-dialog .bv-mobile-menu .dropdown-menu{
            top: 43px;
            /* left: -114px;
            min-width: 140px; */
            left: -170px;
                min-width: 205px;
            display: block;
        }

        .status-bar-over-dialog {
            height: 10px;
            width: 100%;
            position: absolute;
            top: -51px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        /* ng scrollbars related Start */

        .mCSB_scrollTools {
            width: 8px;
        }

        .mCSB_inside>.mCSB_container {
            margin-right: 20px;
        }

        .mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
            /*background-color: #000;*/
            background-color: rgba(0,0,0,.45) !important;
        }

        /*.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
            background-color: rgba(0, 0, 0, 0) !important;
        }*/

        .mCSB_scrollTools, .mCSB_scrollTools .mCSB_buttonDown,
        .mCSB_scrollTools .mCSB_buttonLeft,
        .mCSB_scrollTools .mCSB_buttonRight,
        .mCSB_scrollTools .mCSB_buttonUp,
        .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
            -webkit-transition: "";
            -moz-transition: "";
            -o-transition: "";
            transition: "";
        }

        .mCS-autoHide:hover>.mCustomScrollBox>.mCSB_scrollTools,
        .mCS-autoHide:hover>.mCustomScrollBox~.mCSB_scrollTools,
        .mCustomScrollBox:hover>.mCSB_scrollTools,
        .mCustomScrollBox:hover~.mCSB_scrollTools,
        .mCustomScrollbar>.mCustomScrollBox>.mCSB_scrollTools.mCSB_scrollTools_onDrag,
        .mCustomScrollbar>.mCustomScrollBox~.mCSB_scrollTools.mCSB_scrollTools_onDrag {
            opacity: 0.75;
            filter: "alpha(opacity=75)";
            -ms-filter: "alpha(opacity=75)";
        }

        .mCSB_outside+.mCSB_scrollTools {
            right: -13px;
        }

        .teamSideBar .mCSB_outside + .mCSB_scrollTools{
            right: 0px;
        }

        .mCSB_outside+.mCS-minimal-dark.mCSB_scrollTools_vertical,
        .mCSB_outside+.mCS-minimal.mCSB_scrollTools_vertical
        {
            right: -2px;
            margin: 0px;
        }

        /* ng scrollbars related end */

        /* bv accordion*/
        .bv-accordion {
            position: relative;
            width: 100%;
            display: inline-block;
        }

        .bv-accordion .bv-panel-heading {
            padding-left: 32px;
            height: 32px;
            line-height: 32px;
            font-weight: 500;
        }

        .bv-accordion .bv-panel-content {
            padding-left: 32px;
        }

        .bv-hide-show-content-arrow {
            height: 15px;
            width: 15px;
            position: absolute;
            left: 10px;
        }

        .bv-hide-panel-content-arrow {
            top: 9px;
        }

        .bv-show-panel-content-arrow {
            top: 10px;
            left: 8px;
        }

        .bv-hide-show-content-arrow svg {
            fill: #AEAEAE;
            transition: all 0.5s;
            transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            transform-origin: 50% 40%;
            -moz-transform: rotate(90deg);
            -moz-transform-origin: 7px 7px;
            height: 15px;
            width: 15px;
        }

        .bv-show-panel-content-arrow svg {
            transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            /* margin-bottom: 5px; */
            transform-origin: 50% 40%;
            -moz-transform: rotate(45deg);
            -moz-transform-origin: 7px 7px;
        }
        /*  accordion end */

        .bv-status-online-fill{
            fill: #2273a5;
        }
        .bv-status-offline-fill{
            fill: #bfbfbf;
        }

        .bv-print-approval .modal-dialog {
            width: 600px;
            /*height: 735px;*/
        }

        .bv-print-approval-options .modal-dialog {
            width: 300px;
        }
        
       .bv-bbk-edit-warning .modal-dialog {
      	width: 500px; }
      	
      	.bv-bbk-edit-warning .bv-confirm-content {
      	 text-align: justify;
      	}
      

        .bv-add-comments .modal-dialog{
            width: 600px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50.1%) !important;
            -webkit-transform: translate(-50%,-50.1%) !important;
            -ms-transform:  translate(-50%,-50.1%) !important;
            margin: 0;
        }

        /* tooltip directive */


        .bv-tooltip {
            display: inline-block;
            background-color: #2273a5;
            border-radius: 5px;
            padding: 7px;
            color: #fff;
        }

        .bv-tooltip-down-arrow {
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 7px solid #2273a5;
            margin-left: calc(50% - 7px);
        }

        /* toggle buttons */
        .bv-toggle-buttons {
            padding-bottom: 5px;
        }
        .bv-toggle-buttons li.bv-toggle-button {
            display: inline-block;
            margin: 0 20px;
        }

        .bv-toggle-buttons li.active {

        }

        .bv-toggle-buttons li.inactive {
            -webkit-filter: grayscale(0.5);
            filter: grayscale(0.5);
            color: #BBBBBB;
        }

        .bv-toggle-buttons li.bv-toggle-button span.bv-toggle-button-label {
            padding-bottom: 3px;
            border-bottom: 5px solid;
        }

        .bv-toggle-buttons li.active span.bv-toggle-button-label {

        }

        .bv-toggle-buttons li.inactive span.bv-toggle-button-label {
            border-bottom: none;
        }

        nvd3-pie-chart svg {
            height: 100%;
            width: 100%;
        }

        .bv-modal-window-folder-agenda-creator .model-dialog {
            width: 400px;
        }

        /* end */

        /********************/

        @media (min-width: 768px){
            .container{
                width: inherit;
            }
        }

        repositorylist{
            display: block;
        }


        .vEditDialog .modal-dialog
        {
            box-shadow: 0 0 0 0;
            height: 97%;
            margin: 0.1%;
            min-width: 300px;
            min-height: 300px;
            overflow: hidden;
            width: 96%;
        }
        .vEditDialog .modal-content
        {
            background: transparent;
            height: 100%;
            width: 100%;
        }

        .veditHeaderMenuDiv {
            position:absolute;height:3%;width:50%;left:25%;opacity:0;
        }

        .veditHeaderMenuDiv:hover {
            opacity:1;
        }

        .veditHeaderSubMenuFullScreen {
            display: inline-block;
        }
        :-webkit-full-screen .veditHeaderSubMenuFullScreen{
            display: none;
        }
        :-moz-full-screen .veditHeaderSubMenuFullScreen{
            display:none;
        }
        :-ms-full-screen .veditHeaderSubMenuFullScreen{
            display:none;
        }
        :full-screen .veditHeaderSubMenuFullScreen{
            display:none;
        }

        .veditHeaderSubMenuExitFullScreen {
            display:none;
        }
        :-webkit-full-screen .veditHeaderSubMenuExitFullScreen{
            display:inline-block;
        }
        :-moz-full-screen .veditHeaderSubMenuExitFullScreen{
            display:inline-block;
        }
        :-ms-full-screen .veditHeaderSubMenuExitFullScreen{
            display:inline-block;
        }
        :full-screen .veditHeaderSubMenuExitFullScreen{
            display:inline-block;
        }


        .veditHeaderMenuDiv:hover .veditHeaderMenu {
            display:block;
            cursor:pointer;
            /*background-color: #605e5d;*/
            background-color: #666666;
            color:#FFFFFF;
        }

        .veditHeaderMenu {
            position: absolute;
            display: none;
            padding: 5px;
            border: 1px solid #ccc;
        }

        .veditHeaderMenu > li {
            /*display: inline-block;*/
            cursor: pointer;
            padding-right: 5px;
            padding-left: 5px;
        }

        .bv-lang-main-div { height: calc(100% - 70px);margin-top:8px;width:100% }

        .bv-lang .modal-dialog{
            width: 500px;
        }
        .bv-lang .bv-lang-div0{
            height: 40px;
        }
        .bv-lang .bv-lang-div1{
            height: 30px;
        }
        .bv-lang .bv-modal-body{
            padding: 20px;
        }
        .bv-dialog-user-list.moduleCreatorShowMe{
            margin-left: 564px;
            display:block;
            height: 502px;
        }
        .bv-dialog-user-list.moduleCreatorNoShow{
            display:none;
        }
        .moduleCreatorPermissionsDIV{
            width:100%;
            height:300px;
            padding:20px;
        }

        .verticalLine{
            border-right: 1px solid #eee;
            top: 0;
            bottom: 0;
        }
        .bv-module-permission-option-row-selected {
            background-color: #eee;
        }
        .bv-module-permission-option-row-not-selected{
        }
        .module-creatorsDIV{
            width:40%;
        }
        .module-creator-heading{
            padding:10px;
        }
        .module-creator-hr-left{
            margin-top:0px;
            margin-bottom:10px;
            margin-right: 30px;
        }
        .module-creator-hr-right{
            margin-top:0px;
            margin-bottom:10px;
            margin-left: 20px;
        }
        .module-creator-module-name{
            padding:10px;
            cursor:pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width:183px;
        }
        .module-creator-users{
            width:60%;
        }
        .module-creator-users-heading{
            padding:10px;
            margin-left:15px;
        }
        .module-creator-user-li{
            padding:10px;
            clear:left;
        }
        .module-creator-remove-user{
            width:15px;
            float:right;
            cursor:pointer;
            margin-top:3px;
        }
        .module-creator-user-style{
            max-width:200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .bv-module-creator-user-item {width: 100%; display: list-item; height: 35px;}
        /* .bv-module-creator-user-item:hover{
            background:#ebeef0;
        } */
        .bv-approval-user-option-section1 .bv-dialog-user-btn svg {
            right: 0;
        }
        /* .bv-approval-user-option-section1 .bv-dialog-user-btn svg, .bv-permission-screen .bv-dialog-folder-btn svg {
            width: 17px;
            height: 20px;
            top: 0px;
            position: absolute;
            cursor: pointer;
        } */
        .bv-approval-user-option-section1 .bv-dialog-user-btn svg, .bv-permission-screen .bv-dialog-folder-btn svg {
            width: 17px;
            height: 20px;
            top: 0px;
            position: absolute;
            cursor: pointer;
            /* padding-top: 29px; */
            left: 0px;
            }
        .bv-permisson-folder-arrow-svg {
            width: 17px;
            height: 20px;
            position: absolute;
            cursor: pointer;
            /* padding-top: 29px; */
            left: 67px;
            }
        .bv-span-plusIcon{
            width:21px;
            height:21px;
            cursor:pointer;
        }
        .bv-input-btn-box-remove-shadow{
            text-align: center;
            padding: 0px 20px 20px 20px;
            position: relative;
            z-index: 1;
            text-align: right;
            width: 100%;
        }
        .bv-nonrespondents-svg
        {
            width:7%;
            height:20%;
        }
        .bv-surveys-itemList
        {
            height:95%;
            width:100%;
        }
        .bv-notSurveys-itemList
        {
            height:100%;
            width:100%;
        }
        .bv-survey-nonRespondants-label
        {
            position:relative;
            top:2px;
            font-size:14px;
        }

        .display-inline {
            display: inline-block;
        }

        .session-timeout-message {
            padding: 30px 20px 20px 20px !important;
        }

        .nav>li.disabled>bt {
            color: #DCDCDC;
            cursor: not-allowed;
        }

        .bv-set-follower{
            font-size: 14px;
        }
        .bv-set-follower .bv-set-follower-form{
            padding: 20px;
            height: 400px;
        }
        .bv-set-follower .bv-dialog-title-box{
            z-index: 1;
            position: relative;
        }
        .bv-set-follower .bv-dialog-title-box input{
            width: calc(100% - 80px);
        }
        .bv-set-follower .bv-dialog-user-btn{
            float: right;
            margin-top: 5px;
            min-width: 65px;
        }
        .bv-set-follower .bv-dialog-user-btn > span{
            font-size: 15px;
        }
        .bv-set-follower .bv-dialog-user-btn > svg{
            height: 15px;
            margin: 3px 5px;
            float: right;
            fill: #818587;
            width: 9px;
        }
        .bv-set-follower .bv-dialog-user-list{
            display: none;
            margin-left: 600px;
        }
        .bv-set-follower .bv-dialog-user-list.show{
            display: block;
        }
        .bv-set-follower .bv-set-follower-form > div:last-child{
            top: -15px;
        }
        .bv-set-follower .bv-scroll-list > filter{
            width: 80%;
        }
        .bv-set-follower [bv-item-list]{
            height: 532px;
        }
        .bv-set-follower .checkbox-disabled > svg{
            opacity: 0.5;
            cursor: default;
        }
        
       /* .MeetingDetailTop__MeetingDescription-fkHmMA ul{   
		    padding-left: 15px !important;
		    list-style: inherit !important; 
		}*/
        .bv-set-follower .modal-dialog {
          width: 600px;
          position: absolute;
          margin: 0;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) !important;
          -webkit-transform: translate(-50%, -50%) !important;
          -ms-transform: translate(-50%, -50%) !important;
          }

        .bv-set-follower .bv-item-list-main {
            height: calc(100% - 97px);
        }

        .bv-set-follower .bv-modal-dialog .bv-modal-title{
            font-size: 16px;
        }

        .bv-user-management-toolkit{
            font-size: 14px;
        }
        .bv-user-management-toolkit .bv-user-management-toolkit-form{
            padding: 20px;
            height: 460px;
        }
        .bv-user-management-toolkit .bv-dialog-title-box{
            z-index: 1;
            position: relative;
        }
        .bv-user-management-toolkit .bv-dialog-title-box input{
            width: calc(100% - 80px);
        }
        .bv-user-management-toolkit .bv-dialog-user-btn{
            float: right;
            margin-top: 5px;
            max-width: 110px;
            width:auto;
        }
        .bv-user-management-toolkit .bv-dialog-user-btn > span{
            font-size: 15px;
        }
        .bv-user-management-toolkit .bv-dialog-user-btn > svg{
            height: 15px;
            margin: 3px 5px;
            float: right;
            fill: #818587;
            width: 9px;
        }
        .bv-user-management-toolkit .bv-dialog-user-list{
            display: none;
            margin-left: 600px;
        }
        .bv-user-management-toolkit .bv-dialog-user-list.show{
            display: block;
        }
        .bv-user-management-toolkit .bv-user-management-toolkit-form > div:last-child{
            top: -15px;
        }
        .bv-user-management-toolkit .bv-scroll-list > filter{
            width: 80%;
        }
        .bv-user-management-toolkit [bv-item-list]{
            height: 463px;
        }
        .bv-user-management-toolkit .checkbox-disabled > svg{
            opacity: 0.5;
            cursor: default;
        }

        .disableForOffline{
          opacity: 0.5;
          pointer-events: none;

          > * {
            opacity: 0.5;
            pointer-events: none;
          }
        }

        .bv-scroll-list-table > ul > li > svg,
        .bv-scroll-list-table .scroll-list > li > ul > li svg{
            float: right;
            top: 12px;
            position: relative;
            margin-left: 5px;
            width: 18px;
            height: 17px;
        }
        .bv-scroll-list-table > ul{
            margin: 0 !important;
            background: #EFEFEF;
        }
        .bv-scroll-list-table > ul > li,
        .bv-scroll-list-table .scroll-list > li > ul > li{
            padding: 0 10px;
            text-align: right;
            height: 40px;
            cursor: default;
        }

        .bv-scroll-list-table .scroll-list > li > ul > li span{
            width: 150px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            display: block;
        }
        .bv-set-follower-form .bv-scroll-list-table .scroll-list > li > ul > li span{
            width: 300px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            display: block;
        }
        .bv-scroll-list-table > ul > li:first-child,
        .bv-scroll-list-table .scroll-list > li > ul > li:first-child{
            text-align: left;
        }
        .bv-scroll-list-table .scroll-list > li > ul > li:nth-child(1){
            overflow: visible !important;
        }
        .bv-scroll-list-table .scroll-list-items{
            padding-right: 0 !important;
            margin-top: 20px;
            height: 315px;
        }
        .bv-set-follower-form .bv-scroll-list-table .scroll-list-items{
            padding-right: 0 !important;
            // margin-top: 20px;
            height: 330px;
        }
        .bv-scroll-list-table .scroll-list > li{
            border: 0 !important;
        }
        .bv-scroll-list-table .scroll-list > li:nth-child(odd){
            background: #f7f9fa;
        }
        .bv-scroll-list-table .delete-btn{
            width: 16px;
            opacity: 0;
            right: -30px;
            z-index: 1;
            position: absolute !important;
        }
        .bv-scroll-list-table .scroll-list > li:hover .delete-btn{
            opacity: 0.2;
        }
        .bv-scroll-list-table .scroll-list > li .delete-btn:hover{
            opacity: 1;
            cursor: pointer;
        }
        .bv-scroll-list-table .scroll-list > li .delete-btn.delete-disabled:hover{
            opacity: 0.2;
            cursor: default;
        }

        .bv-scroll-list-table svg.checkbox-disabled{
            opacity: 0.3;
            cursor: default;
        }
        .bv-scroll-list-table svg.sort-direction{
            display: inline;
            width: 15px;
            float: none;
            top: 0px;
            display: none;
        }
        .bv-scroll-list-table .bv-scroll-col{
            white-space: normal !important;
        }

        .bv-permission-list:hover .deleteIconSvg
        {
            fill:#D3D3D3;
            visibility:visible;
        }
        .deleteIconSvg
        {
            fill:black;
            visibility:hidden;
        }
        .deleteIconSvg:hover
        {
            fill:black !important;
            visibility:visible;
        }

        checkbox svg{
            width: 18px;
            height: 18px;
        }
        checkbox .active svg{
            fill: #2273a5;
        }
        
        .chk{
        	display:none;
        }
        .chk + label:before{
		    color: #00bce3;
		    height: 18px;
		    width: 18px;
		    font-size: 13px;
		    padding-left: 2px;
        }
        
        .small .top-panel .chkbox{
        	height: 15px;
        	width: 8px;
        }
        
        .large .top-panel .chkbox{
        	height: 15px;
        	width: 18px;
        }
        
        .top-panel .chkbox{
        	height: 15px;
        	width: 18px;
        }
        
        .small .chk + label:before{
        	height: 13px;
		    width: 13px;
		    font-size: 9px;
        }
        .large .chk + label:before{
        	height: 22px;
		    width: 22px;
		    font-size: 17px;
        }
        .small .chk + label:before{
        	height: 13px;
		    width: 13px;
        }

        .animate-opacity{
            -webkit-transition: opacity 500ms ease-out 100ms;
            -moz-transition: opacity 500ms ease-out 100ms;
            -o-transition: opacity 500ms ease-out 100ms;
            transition: opacity 500ms ease-out 100ms;
        }
        .richtext{
          word-wrap: break-word;
           ul{
                list-style: disc; 
               ul{
	                list-style: circle;
	                ul{
		                list-style: square;
		            }
	            }
            }
        }
        
        .font-weight-medium{
        	font-weight: 300;
        }
        
        .margin10{
        	margin: 10px
        }
        
        .margin20{
        	margin: 20px
        }
       & .bv-modal-window-send-meeting-notification{
			& .modal-dialog{
				width: 800px !important;
			}
		} 
    }
}
