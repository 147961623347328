@import 'src/utils/variables';

.events-dialog{
    &.modal-backdrop{
        opacity: 0;
    }

    .modal-dialog{
        width: 362px;
        /*box-shadow: 0px 0px 3px 0px #6f6f6f;*/
        border-radius: 10px;
        box-shadow: 10px 9px 69px -11px rgba(0, 0, 0, 0.5) !important;
        border-radius: 10px !important;
        .modal-content{
            box-shadow: none;
            border-radius: 10px;
        }

        .bv-modal-body{
            > div{
                > span{
                    padding: 15px 0px 0 15px;
                    display: block;
                    font-size: 25px;
                    font-family: $nasdaqFontSemibold;
                    color: $grayDarkest;
                }

                > ul{
                    max-height: 370px;
                    overflow-y: auto;
                    padding: 0 20px;
                    background-color: #f4f4f5;
                    border-radius: 10px;

                    > .mCSB_scrollTools{
                        right: 6px;
                    }

                    > .mCustomScrollBox{
                        border-bottom-right-radius: 6px;
                    }

                    li.single-meeting , li:last-child{
                        border-bottom: none !important;
                    }

                    li{
                         /* height: 74px; */
                        border-bottom: 1px solid #b6b7ba;
                        padding: 14px 0;
                        font-size: 18px;
                        cursor: pointer;
                        background-color: #F4F4F5;

                      
                            .dateStyle{
                                height: 17px;
  								font-family: HelveticaNeue;
  								font-size: 14px;
  								font-weight: bold;
  								font-style: normal;
  								font-stretch: normal;
  								line-height: normal;
  								letter-spacing: 0.3px;
  								text-align: left;
  								color: #474a53;
                            }
                            .nameStyle{                          
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: inline-block;
                                width: 320px;
  								font-family: HelveticaNeue;
  								font-size: 14px;
  								font-weight: normal;
  								font-style: normal;
  								font-stretch: normal;
  								line-height: normal;
  								letter-spacing: 0.3px;
  								text-align: left;
  								color: #000000;
                            }
                            .teamspaceName{
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: inline-block;
                                width: 320px;
  								font-family: HelveticaNeue;
  								font-size: 14px;
  								font-weight: bold;
  								font-style: normal;
  								font-stretch: normal;
  								line-height: normal;
  								letter-spacing: 0.3px;
  								text-align: left;
  								color: #000000;
                                margin-bottom: 1px;
                            }
                            .editIcon{
                                color: #818181;
                                font-size: 14px;
                                padding-left: 5px;
                            }
                     
                    }

                    &.left-dialog{
                        > .mCSB_scrollTools{
                            right: -2px;
                        }

                        > .mCustomScrollBox{
                            border-bottom-right-radius: none;
                            border-bottom-left-radius: 6px;
                        }

                        li{
                            border-right: none;
                        }
                    }
                }

                > div{
                    &.arrow{
                        width: 0;
                        height: 0;
                        border-top: 20px solid transparent;
                        border-bottom: 20px solid transparent;
                        border-right: 20px solid #fff;
                    }
                }
            }
        }

        svg{
            position: absolute;
            transform: rotate(270deg);
            -webkit-transform: rotate(270deg);
            left: -45px;
            right: inherit;
            top: 51px;
            width: 73px;
            z-index: -1;

            &.left-dialog{
                right: -45px;
                left: inherit;
                transform: rotate(90deg);
                -webkit-transform: rotate(90deg);
            }
             path{
                 fill: #F4F4F5;
             }
        }
    }
}
.ie .events-dialog .modal-dialog svg.left-dialog{
	top:0px !important;
}