@import 'src/utils/variables';
@import 'src/utils/classes';

.nasdaq{
    .preview-survey-menu-item {
        font-size: 12px !important;
        font-weight: 500 !important;
    }

    .bv-modal-dialog {
        .respond-survey-wrapper {
            margin-bottom: 40px !important;
        }
    }

	&.preview-survey-modal{
		.modal-backdrop{
		    z-index: 99 !important;
		}
		.preview-survey-dialog{
			    z-index: 100 !important;
		}
	}
	& .description-style ul {
    list-style:disc;
    padding-left: 30px;
   	 ul{
    	 list-style: circle;
    	 ul{
   		   list-style: square;
	 	 }
	 }
}
	
    .container1 {
        min-width: 375px;
        float:left;
    }
    .container2 {
        width: calc(100% - 375px);
        float:left;
        overflow:hidden;
    }

    .survey-list{
        height: calc(100% - 101px);
        overflow: auto;
        &.showScroll{
			min-height:95vh;
		}
    }

    .survey-list .open-survey-list.rightClickMenu , .survey-list .closed-survey-list .rightClickMenu , .survey-list .owned-survey-list .rightClickMenu{
        min-height: 40px;
        margin-top: 5px;
    }

    .closed-survey-list .surveyName:hover{
         text-decoration : none !important;
         cursor : auto !important;
    }
    

    div[state="home.surveys"]{
    	.viewerDialogClass {
			  z-index:1050 !important;
		  }
    }

    .surveyImg
    {
        float: left;
        width: 30%;
        padding:4%;
        padding-top: 7%;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    .surveyDetails
    {
        float:left;
        width: 65%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .surveyDescription
    {
        width: calc(100% - 150px);
        overflow:hidden;
        font-size: small;
        text-overflow: ellipsis;
        float:left;
        height:145px;
        overflow: auto;
        text-align:left;
        padding-top: 4%;
    }

    .surveyStatus
    {
        float: left;
        width: 150px;
        margin-top: 4%;
        overflow:hidden;
    }
    .surveyBtn
    {
        padding-bottom:4%;
    }

    .editSurveyBtn{
        padding-bottom:1%;
    }

    .surveyStatusBar{
         padding-left: 6%;
         font-size: x-small;
    }

    progress.surveys-bar::-moz-progress-bar {
        background: black;
    }

    /* Chrome */
    progress.surveys-bar::-webkit-progress-bar
    {
        background: #d6d6d6;
    }
    progress.surveys-bar::-webkit-progress-value {
        background: black;
    }

    /* Polyfill */
    progress.surveys-bar[aria-valuenow]:before  {
        background: #d6d6d6;
    }

    /*firefox*/
    @-moz-document url-prefix() {
        .closeButtonDetailsFirefox {
            position:relative;
            margin-right: 5px;
        }
        .bv-survey-arrow-next-details
        {
            position: relative;
            margin-left: 0 !important;
            margin-top: 2px !important;
            right: 43px;
            width: 17px;
        }
    }

    progress.surveys-bar{
        -webkit-appearance: none;
         -moz-appearance: none;
         appearance: none;
         color: #515151;
         border-radius: 2px;
         box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
         background-color:#d6d6d6;
         width: 100px;
         height: 8px;

    }


    progress.open-survey-bar::-moz-progress-bar {
        background: black;
    }

    /* Chrome */
    progress.open-survey-bar::-webkit-progress-bar
    {
        background: #d6d6d6;
    }
    progress.open-survey-bar::-webkit-progress-value {
        background: black;
    }

    /* Polyfill */
    progress.open-survey-bar[aria-valuenow]:before  {
        background: #d6d6d6;
    }
    .bv-slider > div > svg {
        fill: #808080;
    }
    progress.open-survey-bar{
        -webkit-appearance: none;
         -moz-appearance: none;
         appearance: none;
         color: #515151;
         border-radius: 2px;
         box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
         background-color:#d6d6d6;
         width: 100%;
         height: 30px;

    }
    div.bv-surveyProgress-left
    {
        float: left;
        margin-left: 10px;
        top: -1px;
        position: relative;
        .btn{
                font-size:$commonFontSize;
                line-height:1.1em
            }
    }
    div.bv-surveyProgress-middle
    {
        width: calc(100% - 240px);
        float:left;
    }
    div.bv-surveyProgress-right
    {
        float: right;
        top: -1px;
        position: relative;
        .btn{
                font-size:$commonFontSize;
                line-height:1.1em
            }
    }

    .surveyPortal
    {
        padding-top: 10%;
        font-weight: bold;
        white-space:nowrap;
    }

    .surveyPriority
    {
        padding-top:1%;
        font-size:small;
        line-height:0.5;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
    }

    .surveyHrBar
    {
        overflow:hidden;
        width:100%;
        border-bottom: 2.5px solid #DCDCDC;
    }

    .surveyGroupHrBar
    {
        overflow:hidden;
        width:100%;
        hight:20px;
        background-color: #DCDCDC;
        padding-left: 2%;
        font-weight:bold;
    }

    .bv-surveys-image{
        padding-top:1px;
        height: 90px;
    }

    .bv-surveys-attachment-image{
      width: 100px;
      height: 120px;
      margin: 20px 10px 5px 0;
      border:1px solid #021a40;
    }

    .review-survey-form{
      width:100%;
      height:100vh;
    }

    .surveyScreenDIV{
          height:100%;
          width:100%;
    }
    .surveyOverview{
        padding-left:3%;
        padding-top:1%;
        /* width:60%; */
        height:100%;
        overflow:auto;
        float:left;
        width: calc(100% - 465px);

    }

    .surveySections{
        padding:2% 2% 2% 2%;
        width:450px;
        overflow:auto;
        float:left;
        height:100%;
    }

    .surveySectionsInside{
        background-color:#F0F0F0;
        border: 1px solid #DBDBDB;
        border-radius: 15px;
        padding:5% 3% 0% 3%;
    }

    .surveyProgress{
        width: 100%;
        height: 65px;
        position: absolute;
        background: $grayLighter;
        padding: 18px;
        border-top: 1px solid $grayLight;
        bottom: 0;
        border-radius: 0 0 5px 5px;
    }

    .surveyProgressNoBackground{
        height: 15%;
        /* padding-top:1%; */
        padding-left:2%;
        width:100%;
    }

    .surveyProgressText{
        padding-left:36%;
        font-size:smaller;
    }
    .surveyText{
        padding-left: 2%;
        padding-right:2%;
        text-align: left;
        overflow-y: scroll;
    /* 	height:250px;
        overflow:hidden;
        margin-bottom: 2%; */
    }

    .surveyAttachments{
        white-space: nowrap;
        display:inline;
        /* overflow-x:scroll; */
        padding-left:5%;
        /* height:100%; */
    }

    .innerSection{
        background-color:#fff;
        padding-left:3%;
        padding-right:2%;
        margin-left:25%;
        margin-right:25%;
        /* margin-top:1%; */
        position:relative;
        height:100%;
        overflow:auto;
        border: 1px;
        border-radius: 10px;
    }

    .bv-input-btn-grey
    {
        color:#FFFFFF;
        min-width:100px;
        background-color: #424242;
        border-style:none;
        max-height: 45px;
        min-height: 30px;
    }

    .bv-surveys-card-image{
        padding-bottom:3%;
        margin: 0 auto;
        display: block;
    /* 	padding-left:40%; */
    }

    .surveyQuestionDIV, .surveySectionDIV{
        height:100%;
        width:100%;
    }

    .sendSurveyAlertDIV{
        /* height:550px; */
        padding-left:3%;
        padding-right:3%;
        padding-top:0%;
        padding-bottom:3%;
        /* overflow:scroll; */
    }
    .userSurveyPreviewDIV{
        height:550px;
        padding:20px;
        /* overflow:scroll; */
    }
    .surveyQuestionOverview {
        height: 200px;
        width:100%;
        padding :15px;
    }

    .surveyQuestionSections {
        background-color:#FFFFFF;
        height: calc(100% - 50%);
        width:100%;
        padding-top:0.8%;
        padding-bottom:0.8%;
        text-align:left;
        overflow:scroll;
    }

    .surveyQuestionSectionsError, .responseCommentsError {
        border: 1px solid #F8E0E6;
        background-color: #FBEFF2;
    }

    .surveySectionAttachments {
        height: calc(100% - 230px);
        width:100%;
        padding:15px;
    }
    .questionDetail {
        /* padding-left:2%;
        padding-right:2%;
        margin-bottom:2%; */
    }

    .surveyQuestionProgress {
        width:100%;
        height: 30px;
        padding-top:0.5%;
        padding-left:2%;
        padding-right:2%;
        margin-top:1%;
        margin-bottom:1%;
    }
    .surveyQuestionText {
        width:100%;
        height: 100%;
        padding-left: 2%;
        padding-right:2%;
    }
    .bv-input-btn-white
    {
        color:#000000;
        background-color: #FFFFFF;
        border-style:none;
        max-height: 45px;
        min-height: 30px;
    }
    /* .bv-input-btn-black {
        color:#FFFFFF;
        min-width:100px;
        background-color: #000000;
        border-style:none;
        max-height: 45px;
        min-height: 30px;
    } */
    .questionFontBold {
        font-weight: bold;
    }
    .questionFontMandatory {
        color: #FF0000;
        font-weight: bold;
    }
    
    .mandatory-button {
      background: #EA4E56;
      border: 1px solid #BD2832;
      color: #484848;
    }

    .mandatory-button {
      display: inline-block;
      margin-left: 10px;
      margin-bottom: 1px;
      color: white;
      padding: 3px 3px;
      border-radius: 10px;
      font-size: 0.6rem;
      line-height: 1em;
      text-align: center;
      vertical-align: middle;
      transition: background-color 0.2s linear 0.1s;
    }
    
    .questionFont {
        font-weight: bold;
        font-size: 16px;
    }
    .questionSection {
        width:100%;
        text-align:left;
        ul{
              list-style-type: initial;
    		  margin-left: 20px;
              
            }
    }
    .survey-add-comments-img {
      width:35px;
    }
    .questionProgressLeft {
        width: 48%;
        height: 100%;
    }
    .questionProgressRight {
        width: 52%;
        height: 100%;
    }
    .questionProgressButton {
        /* width: 32%; */
        height: 100%;
        /* margin-left: 2%; */
        position: relative;
    }
    .bv-survey-arrow-next {
        width: 17px;
        position: absolute;
        margin-left: -27px;
        margin-top: 5px;
    }
    .bv-survey-arrow-back {
        width: 17px;
        position: absolute;
        margin-left: 16px;
        margin-top: 5px;
    }

    .bv-survey-arrow-next-details {
        width: 17px;
        /* position: absolute; */
        margin-left: -43px;
        margin-top: 2px;
    }
    .bv-survey-attach-next {
        position: absolute;
        margin-left: 170px;
        margin-top:10px;
    }

    .carousel-control.left, .carousel-control.right
    {
        background: none !important;
    }
    .carousel{
        position:relative !important;
        height:100% !important;
    }

    .carousel-inner{
        position:relative !important;
        width:100% !important;
        height:100% !important;
    }
    .carousel .carousel-indicators {
        visibility: hidden !important;
        }

    .carousel-control{
        color: #000000 !important;
    }

    .carousel .item{
        text-align:left !important;
    }

    /*.modal-dialog{
        height:450px !important;
        width:800px !important;
    }*/

    .yesNoClass, .trueFalseClass, .meetsOrNotClass{
        font-size:x-large;
        width: 50px;
        height: 50px;
        margin-left:30%;
        display:inline;
    }

    .bv-likert-scale{
        display:inline;
        margin-left: 0.2em;
        margin-top:2%;
        float:left;
    }

    .bv-multi-choice, .bv-multi-choice-multi-value{
        margin-left: 0.2em;
        margin-top:2%;
    }

    .yesNoClass > input[type="radio"], .meetsOrNotClass > input[type="radio"], .trueFalseClass > input[type="radio"], .bv-likert-scale > input[type="radio"], .bv-multi-choice-multi-value > input[type="checkbox"], .bv-multi-choice > input[type="radio"]{
      width   : 28px;
      margin  : 0;
      padding : 0;
      opacity : 0;
    }

    .yesNoClass > input[type="radio"] + label, .meetsOrNotClass > input[type="radio"] + label, .trueFalseClass > input[type="radio"] + label, .bv-likert-scale > input[type="radio"] + label{
      background-image: url("#{$resources-path}/assets/images/BVLiverySurveyResponseNo.png");
      background-repeat: no-repeat;
      background-position: left bottom;
      background-size:27px;
      height: 100%;
      width: 100%;
      display:inline;
      position: relative;
      z-index: 1;
      margin-left: 9px;
      padding-left: 32px;

    }

    .yesNoClass > input[type="radio"]:Checked + label, .meetsOrNotClass > input[type="radio"]:Checked + label, .trueFalseClass > input[type="radio"]:Checked + label, .bv-likert-scale > input[type="radio"]:Checked + label, .bv-multi-choice > input[type="radio"]:Checked + label{
      background-image: url("#{$resources-path}/assets/images/BVLiverySurveyResponseYes.png");
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      display:inline;
      padding: 0 0 0 0px;
      margin-left: 9px;
      padding-left: 32px;
    }

    .bv-likert-scale > input[type="radio"] + label{
        margin-left: 0px;
        background-size: 17px;
    }

    .bv-likert-scale > input[type="radio"]:Checked + label{
        margin-left: 0px;
        background-size: 17px;
    }

    .bv-multi-choice-multi-value > input[type="checkbox"] + label{
      background-image: url("#{$resources-path}/assets/images/checkbox-empty.png");
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      display:block;
      padding: 0 0 0 0px;
      margin-left: 9px;
      padding-left: 32px;
     }

     .bv-multi-choice-multi-value > input[type="checkbox"]:Checked + label{
       background-image: url("#{$resources-path}/assets/images/checkbox-marked.png");
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      display:block;
      padding: 0 0 0 0px;
      margin-left: 9px;
      padding-left: 32px;
     }

     .bv-multi-choice > input[type="radio"] + label{
      background-image: url("#{$resources-path}/assets/images/BVLiverySurveyResponseNo.png");
      background-repeat: no-repeat;
      background-position: left bottom;
      background-size: 20px;
      height: 100%;
      width: 100%;
      display:block;
      position: relative;
      z-index: 1;
      margin-left: 9px;
      padding-left: 32px;
    }

    .bv-multi-choice > input[type="radio"]:Checked + label{
      background-image: url("#{$resources-path}/assets/images/BVLiverySurveyResponseYes.png");
      background-repeat: no-repeat;
      background-size: 20px;
      height: 100%;
      width: 100%;
      display:block;
      padding: 0 0 0 0px;
      margin-left: 9px;
      padding-left: 32px;
    }

    div.progress-bar-openSurvey-box{
        margin-bottom:0px;
        width: 100%;
        height: 28px;
        border: 1px solid $grayLight;
        pointer-events: none;
        border-radius: 15px;
        box-shadow: inset 0px 0px 1px 0px $grayMid;
        background-color: $lightMockGrayBackground;
    }
    div.progress-bar-openSurvey-box .progress-bar{
        background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 73%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
        background-size: 24px 68px;
        border: 1.9px solid $blueMid;
        border-radius: 15px;
        box-shadow: 0px 0px 1px 0px #000000;
        padding-top: 4px;
        overflow: hidden;
        background-color: $blue;
        color: $blue;
    }
    div.progress-bar-openSurvey-box .progress-bar::after{
        background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 73%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
        background-size: 24px 68px;
        height: 25px;
        width: 100%;
        display: block;
        content: "";
        position: relative;
        top: -25px;
        left: -13px;
    }
    div.progress-bar-openSurvey-box .progress-bar span{
        color: #fff;
    }

    .progress-bar-openSurvey-box-mobile{
        display: none;
    }

    div.progress-bar-openSurvey-value
    {
        background-color:#3596BD;

    }
    div.progress-bar-Survey-box
    {
        background-color:#d6d6d6;
        width:100px;
        height:10px;
        margin-bottom:0px;
    }

    .progress-bar-Survey-box-openSurvey{
        background-color:#d6d6d6;
        width:100px;
        height:5px;
        margin-bottom:0px;
    }
    div.progress-bar-Survey-value
    {
        background-color:#3596BD;
    }
    .glyphicon-chevron-left:before
    {
        content: url("#{$resources-path}/assets/images/leftarrow.png")
    }
    .glyphicon-chevron-right:before
    {
        content: url("#{$resources-path}/assets/images/rightarrow.png")
    }
    .openSurveyDescription{
        line-height:1.5;
        padding-bottom:2%;
        text-align:left;
        padding-right:3%;
    }

    /* .bv-modal{
        position: absolute;
        top: -15%;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1050;
        display: none;
        overflow: auto;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        outline: 0;
    } */

    .bv-report-modal, .bv-participantsList-modal, .bv-send-alert-modal, .bv-admin-preview-modal, .bv-printable-reports-modal{
        position: fixed;
        /* top: -15%; */
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1050;
        display: none;
        overflow: auto;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        outline: 0;
    }
    .bv-copy-survey-modal{
        /* width:500px; */
        position: fixed;
        right: 0;
        bottom: 0;
        /* left: 35%; */
        z-index: 1050;
        display: none;
        overflow: hidden;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        outline: 0;
    }
    .bv-signature-modal{
        top: 60%;
        left: 85%;
        max-width: 300px;
        max-height: 300px;
        display: none;
        -webkit-overflow-scrolling: touch;
        outline: 0;
    }
    .surveys-signature-outer {
        width: 57%;
        height: 70px;
        background: #AC2738;
        margin: 0px 0px 7px 0px;
        position: absolute;
        bottom: 35px;
    }

    .surveys-signature-inside {
      width: 86%;
      height: 60px;
      background: #FFFFFF;
      margin: 5px 1% 5px 13%;
      border-radius: 5px 5px 5px 5px;
      position: absolute;
      text-align: center;
    }

    .surveys-signature-image {
        height: 60px;
    }

    .bv-document-name{
        display: inline-block;
        width: 90px;
        color: #428bca !important;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: small;
    }

    .bv-document-name:hover{
        text-decoration: underline;
    }
    .sectionName{
        width: 100%;
        /* min-width: 100px; */
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    /* .sectionBoxLeft .sectionName:hover{
        text-decoration: underline;
        cursor : pointer;
    } */
    .sectionNameText:hover{
        text-decoration: underline;
        cursor : pointer;
    }

    .bigFont{
        font-size:x-large;
        font-weight:bold;
    }

    .boldFont{
        font-weight:bold;
    }

    .underline{
        text-decoration: underline;
    }

    .capitalize{
        text-transform:capitalize;
    }

    .blueFont{
        color:#2273a5;
    }
    .redFont{
        color:#FF0000;
    }
    .purple-font{
        color: #bb4598;
    }
    .surveyPriorityDescription{
        padding-top: 3%;
        font-size: small;
        line-height: 0.5;
        overflow: scroll;
        bottom: 0px;
        top: 0px;
    }
    .carousel .item
    {
        height:100%;
        padding:20px;
    }

    .surveyQuestionAttachments{
        /* float: left; */
        height:20%;
        width:100%;
        padding-left:2%;
        padding-top: 2%;
    }

    .bv-input-btn-grey-disabled{
        color: #FFFFFF;
        min-width: 100px;
        border-style: none;
        max-height: 45px;
        min-height: 30px;
    }

    .bv-input-btn-blue-disabled
    {
        color:#FFFFFF;
        min-width:125px;
        border-style:none;
        max-height: 45px;
        min-height: 35px;
    }

    .surveyReportsTab{
        padding-left:2%;
        padding-top:2%;
        padding-right:2%;
        padding-bottom:2%;
    }
    .surveyReportsDIV{
        height:550px;
        overflow:scroll;
        padding-left:3%;
        padding-right:3%;
        padding-top:3%;
        padding-bottom:3%;
        text-align: justify;
    }

    .participantsListDIV{
        height:300px;
        overflow:scroll;
        padding-left: 5%;
        padding-top: 5%;
        padding-right: 5%;
        padding-bottom: 5%;
    }

    .participantsList > ul >li{
        padding: 3% 0% 3% 3%;
    }

    .gridStyle {
        border: 1px solid rgb(212,212,212);
        min-width: 250px;
        min-height: 180px;
        overflow:scroll;
    }
    .reportsQuestions{
         border:1px solid;
         padding-left:2%;
         padding-right:2%;
    }

    .barOrPieChart{
        padding-bottom:2%;
    }

    .surveyReportTab, .surveyUserPreviewTab{
        background-color:#555;
    }

    .surveyNameInReport{
        padding:1% 2% 2% 2%;
        float:left;
    }

    .participantListClass{
        width:20px;
        height:20px;
    }

    .toClass{
        overflow:scroll;
    }
    .subjectClass{
        overflow:scroll;
    }
    .participantsGridStyle{
        border: 1px solid rgb(212,212,212);
        width: 100%;
        max-height: 200px;
        overflow: auto;
    }
    .previewNgCell{
        color:black;
        text-decoration:underline;
        text-align:center;
    }

    .eachQuestion{
        border: 1px solid rgb(212,212,212);
        padding:2%;
    }
    .copySurveyDIV{
        padding: 5%;
    }

    .copySurveyNameClass{
        width:100%;
    }
    .previewProgress{
        margin-left:14%;
        font-size:xx-small;
        padding-top: 4%;
    }
    .printableReportsDIV{
        /* padding:2%; */
        padding:25px 25px 25px 25px;
        height:100%;
    }
    .surveyDetailsInPrintableReports{
        padding:3%;
        border: 1px solid;
        border-radius: 5px;
        background: #D9D9D9;
        background: url("#{$resources-path}/assets/images/dust.png");
        text-align: justify;
    }
    .sectionDetailsInPrintableReports{
        padding: 3%;
        border: 1px solid;
        border-radius: 5px;
        background: #D9D9D9;
        background: url("#{$resources-path}/assets/images/dust.png");
        text-align: justify;
    }
    .questionDetailsInPrintableReports{
        padding:3%;
        border: 1px solid;
        border-radius: 5px;
        text-align: justify;
    }
    .printableReportsDIV{
        height:550px;
        overflow:auto;
        background:#FFFFFF;
    }
    .printableReportsHeader{
        padding: 0% 2% 4% 3%;
    }

    .surveyReportSaveName{
        padding-top: 5%;
        padding-left: 4%;
    }

    .accordionContent{
        padding:2% 2% 2% 2%;
    }

    .accordionSectionDescription{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width:100%;
    }

    /* .questionInOverview{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre;
        padding:2% 0% 2% 2%;
    } */

    /* .questionInOverview > p{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding-left:2%;
        padding-right:2%;

    } */
    .accordionIndex{
        width:5%;
        padding-left:2%;
        float:left;
    }
    .answeredOrNot{
        padding-left:4%;
        width:10%;
        float:left;
    }
    .accordionQuestion{
        float:left;
        width:80%;
        padding-left:3%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding-left:7%;
        padding-right:2%;
        height: 28px;
        display:inline-block;
    }
    .accordionQuestion >p{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .surveyOverviewAccordion{
        width:100%;
    }

    .bv-input-btn-blue-surveys
    {
        color:#FFFFFF;
        background-color: #3596BD;
        border-style:none;
        font-size:smaller;
    }

    .bv-input-btn-blue:disabled-surveys
    {
        background-color:#D5D5D5;
        font-size:smaller;
    }

    .bv-input-btn-black-surveys
    {
        color:#FFFFFF;
        background-color: #000000;
        border-style:none;
        font-size:smaller;
    }

    .bv-input-btn-grey-surveys{
        color:#FFFFFF;
        background-color: #424242;
        border-style:none;
        font-size:smaller;
    }

    .bv-input-btn-grey-disabled-surveys{
        color: #FFFFFF;
        border-style: none;
        font-size:smaller;
    }

    .sectionQuestionsAnsweredCount{
        padding-right:2%;
    }

    .xSmallProgressBar{
        padding-left:38%;
        font-size:xx-small;
    }

    .pointer {
        cursor: pointer;
    }

    .highlight {
        background-color:#D5D5D5;
        font-style: italic;
        overflow:hidden;
        display:inline-block;
    }

    .panel-heading{
        height:40px !important;
    }
     .bv-surveys-accordion-heading-class{
        width: 100%;
     }
     .bv-create-survey-tabs
     {
        width:100%;
        height:100%;
        top:0px;
     }
    .bv-progress-bar-question
    {
        background-color: #d6d6d6;
         width: 100px;
         height: 10px;
        margin-bottom: 0px;
        display:inline-block;
    }
    .bv-survey-section-button
    {
        float:right;
        margin-right:5px;
    }
    .bv-survey-question-button
    {
        float:right;
        margin-right:5px;
    }
    .question-placeholder-highlight {
        height: 15px;
        background-color: #CCCCCC;
    }
    .questions-list-order
    {
        overflow-x: hidden;
        overflow-y: hidden;
        height: calc(100% - 0px);
        position: relative;
        width: calc(100% - 40px);
        margin-top: 10px;
    }
    .left-half-survey-details
    {
        width:80%;
        float:left;
    }
    .div-input-survey-name
    {
        padding:50px 0px 0px 10px;
        width:80%;
    }
    .input-survey-name
    {
        width:100%;
    }
    .desrciption-div
    {
        padding:20px 0px 0px 10px;
    }
    .span-survey-description-character-count
    {
        padding:0px 0px 0px 10%;
    }
    .survey-description-barbox
    {
      background-color: #d6d6d6;
      height: 10px;
      margin-bottom: 0px;
      display: inline-block;
      width: 35%;
      margin-left: 20%;
    }
    .div-description-text-area
    {
        padding:0px 10px 0px 10px;
    }
    .div-radio-options-survey-details
    {
        width:100%;
        height:110px;
    }
    .div-label-types-survey-details
    {
        width:15%;
        float:left;
        padding:10px 0px 0px 10px;
    }
    .div-survey-due-date
    {
        padding:11px 0px 0px 0px;
    }
    .div-survey-priority
    {
        padding:22px 0px 0px 0px;
    }
    .div-survey-details-imageTypes
    {
        width:2%;
        float:left
    }
    .subdiv-survey-details-imageTypes
    {
        padding:10px 0px 0px 10%;
    }
    .svg-survey-details-imageTypes
    {
        display:inline-block;
        position:relative;
        top:4px;
    }
    .input-field-survey-dueDate
    {
        width:180px;
        height:30px;
    }
    .div-survey-details-responseTypes1
    {
        width:19%;
        float:left;
        padding:13px 0px 0px 0%;
    }
    .subdiv-survey-details-responseTypes1
    {
        padding:50px 0px 0px 0px;
    }
    .span-priorityMedium-survey-details
    {
        padding:0px 0px 0px 10%;
    }
    .svg-priorityMedium-survey-details
    {
        display:inline-block;
        position:relative;
        top:2px;
    }
    .div-survey-details-imagesTypes2
    {
        width:2%;
        float:left;
    }
    .subdiv-survey-details-imagesTypes2
    {
        padding:10px 0px 0px 10%;
    }
    .svg-survey-details-imagesTypes2
    {
        display:inline-block;
        position:relative;
        top:4px;
    }
    .div-priorityLow-survey-details
    {
        padding:12px 0px 0px 10%;
    }
    .div-survey-details-responsetypes2
    {
        width:15%;
        float:left;
        padding:13px 0px 0px 0px;
    }
    .label-survey-details-low
    {
        padding:47px 0px 0px 0%;
    }
    .div-survey-details-imagesTypes3
    {
        width:2%;
        float:left
    }
    .subdiv-survey-details-imagesTypes3
    {
        padding:10px 0px 0px 10%;
    }
    .svg-survey-details-imagesTypes3
    {
        display:inline-block;
        position:relative;
        top:5px
    }
    .div-survey-details-responseTypes3
    {
        width:25%;
        float:left;
        padding:13px 0px 0px 1%;
    }
    .label-survey-sequentalNumber
    {
        padding:15px 0px 0px 0%;
    }
    .div-rightHalf-survey-details
    {
        float:left;
        width:20%;
    }
    .div-topBar-survey-details
    {
        padding-top:10px;
    }
    .button-show-owners-survey
    {
        margin-top:10px;
    }
    .div-topSection-survey-question
    {
        width:100%;
        height:56px;
    }
    .div-topSection-title-survey-question
    {
        width:70%;
        float:left;
        font-size:x-large;
        padding:28px 0 0 10px;
    }
    .div-edit-question-survey
    {
        width:30%;
        float:right;
        padding:35px 0px 0px 0px;
    }
    .div-edit-questions-charactersdiv
    {
        margin-top:1px;
    }
    .div-edit-question-charactersLabel
    {
        padding:10px 0px 0px 10px;
    }
    .div-survey-question-barbox
    {
      display: inline-block;
      width: 65%;
      margin-left: 5%;
      background-color: #d6d6d6;
      height: 10px;
      margin-bottom: 0px;
    }
    .div-survey-question-imageTypes
    {
        width:19%;
        float:left;
        padding:0px 0px 0px 10px;
    }
    .svg-survey-dropDownItem
    {
        display:inline-block;
        position:relative;
        top:4px;
    }
    .div-survey-question-textArea
    {
        padding:10px 10px 0px 10px;
    }
    .div-survey-question-imageTypes2
    {
        width:1.5%;
        float:left;
        padding:20px 0px 0px 0px;
    }
    .svg-survey-multipleChoiceItem
    {
        display:inline-block;
        position:relative;
        top:4px;
    }
    .div-survey-question-multipleChoiceLabel
    {
        width:15%;
        float:left;
        padding:20px 0px 0px 0px;
    }
    .div-survey-question-imageTypes3
    {
        width:1.5%;
        float:left;
        padding:20px 0px 0px 0px;
    }
    .svg-survey-question-openAnswerItem
    {
        display:inline-block;
        position:relative;
        top:4px;
    }
    .div-survey-question-openAnswerLabel
    {
        width:15%;
        float:left;
        padding:20px 0px 0px 0px;
    }
    .div-survey-question-imageTypes4
    {
        width:1.5%;
        float:left;
        padding:20px 0px 0px 0px;
    }
    .svg-survey-question-likertScaleItem
    {
        display:inline-block;
        position:relative;
        top:4px;
    }
    .div-survey-question-likertScaleLabel
    {
        width:15%;
        float:left;
        padding:20px 0px 0px 0px;
        margin-bottom: 15px;
    }
    .div-survey-question-multipleChoiceorLikertScale
    {
        /* width:100%; */
        /* padding:20px 0px 0px 0px; */
        /* float:left; */
        /* margin-bottom: 15px; */
    }
    .div-survey-question-multipleChoiceDiv
    {
        /* padding:0px 0px 0px 10px; */
    }
    .svg-survey-question-allowMultipleChoice
    {
        display:inline-block;
        position:relative;
        top:9px;
    }
    .label-survey-question-allowMultipleChoice
    {
        /* padding-left:10px; */
        padding-bottom:10px;
        padding-left: 50px;
    }
    .div-survey-questions-multipleChoiceOptions
    {
        /* padding:10px 0px 0px 10px; */
    }
    .inputtext-survey-questions-multipleChoiceOptions
    {
        width:87%;
    }
    .div-survey-question-likertScaleDiv
    {
        /* padding:0px 0px 0px 10px; */
    }
    .div-survey-question-likertScaleOptions
    {
        /* padding:10px 0px 0px 10px; */
    }
    .inputtext1-survey-question-likertScaleOptions
    {
        width:75%;
    }
    .inputtext2-survey-question-likertScaleOptions
    {
            width: 10%;
    }
    .div-survey-question-reply
    {
        width:100%;
        padding:0px 0px 0px 10px;
        float:left;
    }
    .div-survey-question-replyandcomment
    {
        width:45%;
    }
    .bv-survey-admin-preview-modal .modal-dialog, .bv-survey-userAnsweres-preview-modal .modal-dialog{
        width:900px;
        /* height:500px; */
        /* margin-top: 5%; */
     }
     .bv-survey-create-preview-modal .modal-dialog{
        width:900px;
        height:550px;
     }
     .bv-survey-alert-modal .modal-dialog, .bv-report-modal .modal-dialog, .bv-printable-reports-modal .modal-dialog{
        width: 640px;
        /* margin-top: 5%; */

        .mCSB_outside + .mCSB_scrollTools{
          right: 0;
        }
     }
     .bv-report-modal .nv-axis .tick line, .bv-printable-reports-modal .nv-axis .tick line {
            display:none;
        }
        .surveys-menu-icon {
        height: 10px;
        width: 11px;
        position: relative;
        fill: rgb(0, 0, 0);
        margin: 0px 0px 0 0px;
        display: none;
        cursor: pointer;
    }
    .survey-report-content {
        min-height: 316px;
        max-height: 70vh;
        overflow: none;
        padding-bottom: 50px;
    }
    .survey-report-content hr {
        margin-top: 14px;
        margin-bottom: 17px;
        border: 0;
        border-top: 1px solid #C8C8C8;
    }
    .survey-report-info-box {
        margin-top: 20px;
        margin-left: 41px;
        margin-right: 53px;
        margin-bottom: 17px;
    }
    .survey-report-title {
        margin-bottom: 4px;
        overflow: auto;
        max-height: 105px;

        font-family: "SourceSansProBold";
        font-size: 18px;
        line-height: 21px;
        letter-spacing: -0.196429px;

        color: #1D1E25;
    }
    .survey-report-info {
        margin-bottom: 14px;

        font-family: "Neue Helvetica Roman";
        font-size: 14px;
        line-height: 17px;

        color: #000000;
    }
    .survey-report-options-box {
        margin-top: 17px;
        margin-left: 37px;
        margin-right: 37px;
        margin-bottom: 37px;
    }
    .survey-report-file-label {
        font-family: "Neue Helvetica Bold";
        font-size: 14px;
        line-height: 17px;

        text-align: center;
        margin-right: 45px;

        color: #1D1E25;
    }
    .survey-report-file-select {
        display: inline-flex;
        height: 32px;
        width: 300px;
        align-items: center;
        border-radius: 4px;
    }
    .survey-report-file-select .option {
        background: #fff;
        height: 32px;
        width: 90px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin: 0 0px;
        cursor: pointer;
        padding: 0 0px;
        border: 1px solid #ADADAD;
        transition: all 0.3s ease;
    }
    .survey-report-file-select .file-format-label {
        font-family: "Neue Helvetica Medium";
        font-size: 14px;
        line-height: 17px;

        text-align: center;
    }
    
    .survey-report-file-select .file-format-xls {
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
    }
    .survey-report-file-select .file-format-print {
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
    }
    .survey-report-file-select .file-format-word {
        border-left: 0;
        border-right: 0;
    }
    .survey-report-file-select input[type="radio"] {
        display: none;
    }
    #file-format-xls:checked:checked ~ .file-format-xls,
    #file-format-word:checked:checked ~ .file-format-word,
    #file-format-print:checked:checked ~ .file-format-print {
        border-color: #22809C;
        background: #22809C;
    }
    #file-format-word:checked:checked ~ .file-format-xls {
        border-right-color: #22809C;
    }
    #file-format-word:checked:checked ~ .file-format-print {
    border-left-color: #22809C;
    }
    .survey-report-file-select .option span {
        font-size: 14px;
        color: #1B1B1B;
    }
    #file-format-xls:checked:checked ~ .file-format-xls span,
    #file-format-word:checked:checked ~ .file-format-word span,
    #file-format-print:checked:checked ~ .file-format-print span {
        color: #fff;
    }
    #survey-report-button-box {
        position: absolute;
        bottom: 0;
        padding: 0;
        height: 50px;
    }
    #survey-report-button-box > button {
        position: relative;
        right: auto;
        font-family: "Neue Helvetica Medium";
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        width: 143px;
    }
    #survey-report-button-cancel {
        color: #DA353B;
    }
    #survey-report-button-download {
        letter-spacing: -0.216562px;
    }

    .survey-report-includes-box {
        margin-top: 40px;
    }
    .survey-report-includes {
        display: flex;
        width: 100%;
        margin-bottom: 28px;
    }
    .survey-report-includes-wrap {
        float:left;
        width: 100%;
        margin-bottom: 28px;
    }
    .survey-report-includes-wrap > .label {
        float: left;
        width: 26%;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 14px;
        color: #1D1E25;
        font-family: 'Neue Helvetica Roman';
        text-align: left;
    } 
    .survey-report-includes-wrap > .titile {
        font-size: 13px;
        color: #000000;
        font-family: 'Neue Helvetica Roman';
        float: left;
        width: 60%;
        font-weight: normal;
       
    } 
    .survey-report-includes > :first-child {
        margin-left: 0 !important;
    }
    .survey-report-includes > :last-child { 
        margin-right: 0 !important;
    }
    .survey-report-checkbox-box {
        width: 188px;
    }
    .survey-report-checkbox {
        display: inline;
        position: relative;
        padding-left: 25px;
        margin-bottom: 12px;
        margin-left: 9px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        width: 31%;
        color: #000000;
        
        font-family: 'Helvetica';
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 15px;
    }
    .survey-report-checkbox input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    .survey-report-checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 15px;
        width: 16px;
        border: 1px solid #B6B7BA;
        border-radius: 3px;
        
        font-family: "SSStandard";
        font-size: 13px;
        line-height: 18px;
        text-align: end;
        color: #fff;
    }
    .survey-report-checkbox:hover input ~ .survey-report-checkmark {
        color: #ccc;
    }
    .survey-report-checkbox input:checked ~ .survey-report-checkmark {
        color: #474A53;
    }
      

    .surveyDetails:hover .surveys-menu-icon {
      display: inline;
    }
    .survey-sign-here-tooltip {
      position: absolute;
      left: 110px;
    }

    .survey-signature-outer {
        width: calc(100% - 20px);
        height: 90px;
        /*background: #AC2738;*/
        margin: 0px 0px 7px 0px;
        position: absolute;
        bottom: 55px;
    }

    .survey-signature-inside {
        width: 80%;
        height: 80px;
        background: #FFFFFF;
        margin: 5px 1% 5px 18%;
        position: absolute;
        text-align: center;
    }

    .survey-signature-image {
        height: 60px;
    }

    .survey-signature-meta {
      position: absolute;
      bottom: 5px;
      width: calc(100% - 70px);
      right: 10px;
    }

    .signature-pen {
      width: 16%;
      height: 75px;
      float: left;
      margin: 10px 0 0 1%;
    }

    .surveysMain{
      width:100%;
      height:100%;
    /*min-width:1000px;
      min-height: 580px;*/
      position: relative;
    /*   overflow: auto; */

        > .mCSB_scrollTools{
            right: 0 !important;
        }
    }
    .surveys-section{
        overflow:hidden;
        width:100%;
        border-bottom: 1px solid $grayLighter;
        min-height: 57px;
        padding: 5px;
        display: flex;
    }
    /* .surveys-section:nth-child(odd){
        background: #f7f9fa;
    } */
    .surveys-sectionOne,
    .surveysMain .sectionOne{
        /*float:left;
        width: calc(100% - 424px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;*/
        width: 40%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    @media only screen and (min-width: 642px) and (max-width: 1280px) {
        .surveysMain .surveys-sectionOne{
            width: 45%;
        }
        .surveysMain .surveyType{
            width: 20%;
            display: none;
        }
        .surveysMain .bv-survey-openSurvey-icon-div{
            width: 40%;
        }
    }

    .surveys-sectionTwo {
        padding-top: 12px;
        width: 20%;
    }
    .surveyName {
        display: inline-block;
        cursor: pointer;
        padding-left:18px;
        font-size: $commonFontSize;
        color: $nasdaqBlue;
        font-weight: bold;
        margin-top: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100% - 30px);
        float: left;
    }

    .surveyName:hover {
        text-decoration: underline;
    }

    .survey-menu-icon {
      height: 10px;
      width: 16px;
      position: relative;
      fill: rgb(0, 0, 0);
      display: none;      
      float: left;
      margin-top: 20px;
      padding-left: 5px;
      svg{
        cursor: pointer;
        width: 11px;
        height: 10px;
      }
    }

    .surveyDue {
        font-size: $commonFontSize;
        margin-top: 14px;
        display: inline-block;
        color: $grayDark;
        font-weight: $commonFontWeight;
        width: 15%;
        text-align: left;
    }
    .surveyType {
        font-size: $commonFontSize;
        margin-top: 14px;
        display: inline-block;
        color: $grayDark;
        font-weight: $commonFontWeight;
        width: 25%;
        text-align: left;
        padding-left:20px;
    }

    .surveys-section:hover,.questionBox:hover,.manage-survey-section:hover, .participantTableDataHover:hover, .bv-survey-removeAttendeesDiv2 .bv-ts-grid-ul-li:hover{
        background: #ebeef0;
    }
    .clickSection{
       ul{
          list-style-type: initial;
		  margin-left: 20px;
          
        }
    }
    .clickSection:hover{
       box-shadow: 0px 0px 15px 0px #000;
    }

    .surveys-section:hover .survey-menu-icon {
      display: inline;
    }
    .surveys-section:hover > div > svg {
      display: inline;
    }

    .surveyListProgressBar {
        width: 100%;
        height: 20px;
        border: 1px solid $grayLight;
        pointer-events: none;
        border-radius: 15px;
        box-shadow: inset 0px 0px 1px 0px $grayMid;
        background-color: $lightMockGrayBackground;
    }

    .surveyListProgressBar .progress-bar{
        background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 73%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
        background-size: 16px 44px;
        border: 1.9px solid $blueMid;
        border-radius: 15px;
        box-shadow: 0px 0px 1px 0px #000000;
        overflow: hidden;
    }
    .surveyListProgressBar .progress-bar::after{
        background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 73%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
        background-size: 16px 44px;
        height: 18px;
        width: 100%;
        display: block;
        content: "";
        position: relative;
        top: -20px;
        left: -8px;
    }
    .surveyListProgressBar .progress-bar span{
        text-align: center;
        top: -2px;
        position: relative;
        color: #fff !important;
    }


    .disabledClass{
        pointer-events:none;
    }
    .progress-bar-survey-value
    {
        background-color: $blue;
        color: $blue;
    }
    /*.bv-survey-message-center-div
    {
        position:relative;
        margin-top:14%;
    }*/
    .survey-row-first{
        width: 100%;
        min-width: 850px;
        height: calc(100% - 50px);
        overflow: hidden;
        /* padding-bottom: 22px; */
        display: list-item;
        list-style-type: none;
        padding-bottom: 20px;
        /* border: 1px solid; */
    }

    .survey-row-second{
    /* 	position: absolute;
        right: 20px;
        bottom: 20px;
        height: 25px;
        height: 25px; */
        float: right;
        padding-right: 15px;
        padding-bottom: 20px;
        height: 50px;
    }

    .survey-col-details{
        height: 100%;
        width: 370px;
        padding:15px 16px 20px 20px;
        border-right: 1px solid #e5e5e5;
        float: left;
        overflow: hidden;
        position: relative;
    }

    .survey-col-details,
    .bv-survey-section-details,
    #questionCreateDiv{
        .cke{
            iframe{
                background:  #e5e5e5;
            }
        }
    }

    .survey-col-both-attachments{
        height: 100%;
        float: right;
        width: calc(100% - 370px);
    }
    .bv-survey-addAttendeesDiv2-users.managesurveyrespondents{
     height: auto;
    }
    .survey-col-attachments{
        height: 100%;
        display: inline-block;
        width: 50%;
        padding: 15px 15px 0px 15px;
        float: left;
    }

    .survey-col-users{
        height: calc(100% - 25px);
        width: 50%;
        float: right;
        padding: 15px 15px 0px 15px;
    }

    .survey-label {
        margin-bottom: 5px;
    }
    .survey-attachment {
        position: relative;
        height: 60px;
        margin-bottom: 20px;
    }

    .survey-attachments{
        height: calc(100% - 20px);
        width: 100%;
        overflow-x:hidden;
        overflow-y:hidden;
    }
    .survey-attachments:hover{
        overflow-y:auto;
    }

    .survey-attachment:hover .drag-handle-image, .questionBox:hover .drag-handle-image{
        display: block;
    }
    .sectionCreateDivRight .drag-handle-image{
        display: none;
        margin-top: -12px;
    }
    .bv-survey-attachment-section-li:hover .drag-handle-image{
        display: block;
        margin-top: -12px;
    }
    .questionBox .drag-handle {
        width: 14px;
        min-height: 10px;
        float: left;
        position: relative;
        top: 3px;
        padding-left: 0px;
        }
    .sectionBox:hover{
        background-color:#DEDEDE;
    }
    .questionBox .survey-attachment {
        position: relative;
        height: 60px;
        margin-bottom: 20px;
    }
    .survey-attachment-text-center{
        margin: 20px 0px 0px 0px;
        cursor: pointer;
    }
    .surveyDropDown{
        width:100%;
        background-color:#e5e5e5;
        border-radius:0;
        height:26px;
        border:none;
        padding-left: 9px;
        -webkit-appearance: none;
        -webkit-border-radius: 0px;
    }
    .survey-text-truncate{
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    /* .btn-remove {
        width:21px;
        float:right;
        display:inline;
        padding-top: 19px;
    } */
    .form-group-survey{
        margin-bottom: 5px;
        width: 100%;
        float: left;
        .cke_top{
         padding:0;
         .cke_toolgroup {
			    margin: 1px 0px 6px 0;
			}
        }
    }
    .survey-textbox{
        width: 100%;
        margin-bottom:5px;
        font-size:14px;
    }
    .survey-datePick-wraper{
	    position: relative;
	    float: left;
	    padding-left: 10px;
	    width: 160px;
	    input {
	    	width:100%;
	    }
	    
	    	icon{
	    		right: 7px !important;
	    	}
	    	
	   
    }
    .survey-dueDate {
        width: 47%;
        float: left;
        display:inline;
        margin-bottom:5px;

        icon{
            position: absolute;
            
            top: 30px;
            font-size: 14px;
            pointer-events: none;
        }
    }
    .survey-prioirty {
        width: 47%;
        float: right;
        display:inline;
        margin-bottom:5px;
    }
    .survey-col-details-form {
        height: 100%;
        width: 100%;
        position: relative;
    }
    .survey-col-details-form .form-group {
        margin: 0px;
    }
    .survey-textarea{
        width: 100%;
        /*border: 0px none;*/
        line-height: 1.4;
        /*padding: 2px 10px;*/
        margin-bottom: 5px;
        resize:vertical;
        overflow: auto;
        height: 85px;
        /*outline: none;*/
        font-size:14px;
    }
    .survey-user-unchecked{
        font-weight: normal;
        color: #808080;
        font-size: 14px;
    }
    .attachment-image-survey {
        width: 50px;
        height: 60px;
        /*margin: 0 10px 25px 0;*/
        border: 1px solid #eeeeee;
        cursor: pointer;
    }
    .attachment-image-survey img,
    .attachment-image-survey > div{
        width: 47px;
        height: 60px;
    }
    .attachmentRow-survey .bv-slider > ul{
        margin: 0px 20px;
    }

    .attachmentRow-survey .bv-slider > ul > li{
        width: 33.2%;
        margin: 0;
        box-shadow: inherit;
    }
    .survey-checkbox-padding{
        padding-top:2px;
    }
    .surveyDrpDwnList{
            min-width:100% !important;
            top : 25px !important;
        }
    .surveyDrpDwnBkgrndColor{
            background : #e5e5e5 !important;
            color: #000 !important;
        }
    .survey-response{
        height:22px;
        width:22px;
        cursor:pointer;
        padding-right:25px;
        padding-left:30px;
    }
    .moveCursor{
        cursor:move !important;
    }
    .bv-survey-user-option .modal-dialog { width:565px; position: absolute; margin: 0; top: 50%; left: 50%; transform: translate(-50%,-50%) !important; -webkit-transform: translate(-50%,-50%) !important; -ms-transform:  translate(-50%,-50%) !important; }
    .bv-survey-user-option-container-div { width:100%; height: 502px; font-weight: normal; color:#000000; margin: 0px; }
    .bv-survey-user-option-col1 { height:100%; width:240px; float:left;  font-weight: 500;}
    .bv-survey-user-option-col2 { height:100%; width:324px; float:right; display: inline-block; position: absolute; }
    .bv-survey-user-option-col2 .padding-left-20 { padding:20px;}
    .bv-survey-user-option-row { height:60px;line-height:55px;width:100%; font-size:20px; font-weight:400; cursor:pointer; display:block; text-align:left; margin-top:7px; margin-bottom:13px; padding: 0px 0px 0px 20px; }
    .bv-survey-user-option-row > span {
        line-height: normal;
        vertical-align: middle;
        white-space: inherit;
        display: inline-block;
    }
    .bv-survey-user-option-col2-publishUnpublish{
        height:100%;
        width:680px;
        float:right;
        display: inline-block;
        position: absolute;
        padding-bottom:0px;
    }
    .manageSurveyPublishCol1{
        width:550px;
        padding-top:5px;
    }
    .manageSurveyPublishCol2{
        width:calc(100% - 550px);
        padding-top:5px;

    }
    .manageSurveyPublishCol1AddRespdnts{
        width:300px;;
        padding-top:5px;
    }
    .manageSurveyPublishCol2AddRespdnts{
        width:calc(100% - 300px);
        padding-top: 15px;
        padding-left: 25px;
    }
    .manageSurveyPublishCol3{
        width:155px;
    }
    .bv-survey-user-option-row-selected { background-color: #2273a5; color:#FFFFFF;}
    .bv-survey-user-option-row-not-selected { color: #2273a5;}
    .bv-survey-user-option-text-truncate {max-width: 90%;float: left; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
    .bv-survey-user-option-section1 {
        width: 100%;
        height: 90%;
        padding: 15px;
    }
    .bv-survey-user-option-section2 {width: 100%; height: 10%;}
    .bv-survey-user-accepted-bar {display: inline-block; width: 5px; height: 15px; background: #199c48; float: left; margin-top:3px}
    .bv-survey-user-not-responded-bar {display: inline-block; width: 5px; height: 15px; background: #959596;float: left; margin-top:3px}
    .bv-survey-user-tentative-bar {display: inline-block; width: 5px; height: 15px; background: #ea9930;float: left; margin-top:3px}
    .bv-survey-user-declined-bar {display: inline-block; width: 5px; height: 15px; background: #ea252f;float: left; margin-top:3px}
    .bv-survey-user-users {height: calc(100% - 41px); width: 100%; overflow-x:hidden; overflow-y:hidden;}
    .bv-survey-user-users:hover{overflow-y:auto; }
    .bv-survey-user-checkbox {width:27px; float: right;display: inline;}
    .bv-survey-user-checkbox-inner {float: right;display: inline;cursor: pointer;}
    .bv-survey-user-item-width {width: 310px;}
    .bv-survey-user-item {width: 100%; display: list-item; height: 35px;}
    .bv-survey-user-seperator {margin-top:5px;margin-bottom:12px;border-color: #e5e5e5}
    .qMgrTopSection {
        width: 100%;
        height: 25px;
        overflow: hidden;
        /* padding: 16px 20px 20px 20px; */
    }

    .qMgrMiddleSection{
        height:calc(100% - 28px);
    }

    /* View survey */

    .survey-main {
      width:100%;
      height:100%;
      min-width:1000px;
      min-height: 520px;
      position: relative;
      overflow: auto;
    }

    .survey-top-section {
      width: 100%;
      min-height: 224px;
      overflow: hidden;
      padding: 16px 20px 10px 20px;
    }
    .survey-top-section-preview {
      width: 100%;
      height: 175px;
      overflow: hidden;
     /*  padding: 16px 20px 10px 20px; */
    }

    .survey-top-section-preview .survey-top-left{
        padding: 16px 20px 10px 20px !important;
        height:100%;
    }

    .survey-top-section-preview .survey-top-right{
        padding: 0px 20px 10px 20px !important;
        height:100%;
    }
    .survey-top-left {
      width: calc(100% - 440px);
      height: 100%;
      overflow: hidden;
    }

    .survey-instructions {
      /*margin-top: 10px;*/
      min-height: 30px;
      max-height: 65px;
      width: calc(100% - 15px);
      text-align: left;
      ul{
          list-style-type: initial;
		  margin-left: 20px;
          
        }
    }

    .survey-top-right {
        width: 440px;
        height:100%;
        vertical-align: middle;
        position: relative;
        /*   top: 20%; */
        white-space: nowrap;
        /*display: inline-block;*/
        overflow: hidden;
        /*   margin: 10px; */
        text-align: center;
    }


    .survey-attachments-row {
      height: 170px;
      text-align: center;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      position: relative;
      padding: 10px 10px 10px 10px;
      display: inline-block;
    }
    .survey-attachments-row-sections
    {
      height: 170px;
      text-align: center;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      position: relative;
      padding: 10px 10px 10px 10px;
      display: inline-block;
    }

    .survey-attachments-row .bv-slider > ul > li {
      margin: 10px 11px !important;
      width: 90px;
      height: calc(100% - 36px);
      box-shadow:none;
    }
    .userPreviewResponseRight .bv-slider > ul > li {
      margin: 10px 7px !important;
      width: 90px;
      height: 115px;
       box-shadow:none;
      /* box-shadow:1px 1px 1px 1px; */
    }

    .userPreviewTopRight .bv-slider > ul > li {
      margin: 10px 8px !important;
      width: 90px;
      height: 115px;
       box-shadow:none;
      /* box-shadow:1px 1px 1px 1px; */
    }
    .userPreviewSectionDetailsRight .bv-slider > ul > li {
      margin: 10px 7px !important;
      width: 90px;
      height: 115px;
       box-shadow:none;
      /* box-shadow:1px 1px 1px 1px; */
    }

    .survey-attachments-row-sections .bv-slider > ul > li{
        display: inline-block;
        height: calc(100% - 40px);
        box-shadow: none;
        margin: 0;
        padding-top: 7px;
    }
    /* .userPreviewTopRight .bv-slider > ul > li{
        display: inline-block;
        height: calc(100% - 40px);
        width: 31.2%;
        margin: 20px 1%;
        box-shadow: 0px 0px 15px 0px;
        position: relative;
    } */
    /*  .userPreviewSectionDetailsRight .bv-slider > ul > li{
        display: inline-block;
        height: calc(100% - 40px);
        width: 31.2%;
        margin: 20px 1%;
        box-shadow:  0px 0px 15px 0px;
        position: relative;

     } */
    /*  .userPreviewResponseRight .bv-slider > ul > li{
        display: inline-block;
        height: calc(100% - 40px);
        width: 31.2%;
        margin: 20px 1%;
        box-shadow:  0px 0px 15px 0px;
        position: relative;

     } */

    .bv-slider > div > svg {
        fill: #808080;
    }

    .survey-attachment-image-container {
      width: 90px;
      height: 115px;
      margin: 0 10px 10px 0;
     /*  box-shadow: none; */
      box-shadow: 0 0 7px 0 #707070;
      position: relative;
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      background: #fff;
    }

    .survey-attachment-text-center{
      margin: 20px 0px 0px 0px;
      cursor: pointer;
    }
    .survey-second-section {
      width:100%;
      height:calc(100% - 329px);
      overflow: hidden;
      position: relative;
      margin: 20px 0px;
    }
    #surveyConsumerSectionDIV .survey-second-section{
      width:100%;
      height:calc(100% - 260px);
      overflow: hidden;
      position:relative;
      padding: 20px;
    }
    /*#surveyConsumerSectionDIV{
        padding: 20px 20px 20px 20px;
    }*/

    #surveyConsumerSectionDIV div.bv-surveyProgress-middle,
    #surveyConsumerQuestionDIV div.bv-surveyProgress-middle{
        width: calc(100% - 180px);
    }
    #surveyConsumerQuestionDIV div.bv-surveyProgress-middle.completed-progress{
        width: calc(100% - 190px);
    }
    #surveyConsumerQuestionDIV .bv-surveyProgress-right button.complete{
        width: 80px;
    }
    #surveyConsumerSectionDIV .bv-surveyProgress-left,
    #surveyConsumerQuestionDIV .bv-surveyProgress-left{
        margin-left: 0;
    }

    #surveyConsumerSectionDIV .bv-surveyProgress-left button,
    #surveyConsumerQuestionDIV .bv-surveyProgress-left button{
        margin: 0 22px 0 0;
        padding: 5px 0px 6px;
    }

    #surveyConsumerSectionDIV .bv-surveyProgress-left button,
    #surveyConsumerSectionDIV .bv-surveyProgress-right button,
    #surveyConsumerQuestionDIV .bv-surveyProgress-left button,
    #surveyConsumerQuestionDIV .bv-surveyProgress-right button{
        width: 70px;
        font-size: 13px;
        letter-spacing: 0px;
        line-height: 1.1em;
    }

    #surveyConsumerSectionDIV,
    #surveyConsumerQuestionDIV{
        .surveyProgress{
            button{
                margin: 0 15px;
                top: -9px;
                position: relative;
                line-height: 1.1em;
                background-color: $nasdaqBlue;

                &.bv-survey-question-mobile-finish-later-label{
                    display: none;
                }

                &.complete{
                    background-color: $green;
                }
            }

            .progress-bar-openSurvey-box{
                left: 0 !important;
                top: 0 !important;
                position: relative;
                width: calc(100% - 270px);
                display: inline-block !important;
            }
        }

        .survey_response_bottom_right_options{
            a{
                margin-right: 25px;
                cursor: pointer;
            }
        }
    }


    /*#surveyConsumerQuestionDIV{
        padding:20px;
    }*/
    #surveyConsumerQuestionDIV .bv-question-details{
        height: calc(100% - 305px);
        padding-top: 20px;
        min-height: 180px;
    }
    #surveyConsumerQuestionDIV .bv-survey-question-top-section{
        width: 100%;
        height: 200px;
        padding: 12px;
    }
    #surveyConsumerSectionDIV .survey-bottom-section{
        width:100%;
        min-height:50px;
        position:relative;
         /* padding: 20px; */
    }

    .bv-survey-question-top-section{
        .bv-survey-question-top-left{
            .question-comments{
                height: calc(100% - 40px);
                word-break: break-all;
                ul{
		          list-style-type: initial;
				  margin-left: 20px;
		        }
            }
        }
    }

    .survey-section-cards .bv-slider > ul > li {
        width:31.7%;
        margin: 20px 0.8%;
        /* height: calc(100% - 305px); */
        overflow:hidden;
    }
    .survey-section-cards .card-box{
        padding: 12px 15px;
        height: calc(100% - 85px);
    }
    .survey-section-cards .survey-cards-button{
        bottom: 0;
	    position: absolute;
	    width: 100%;
	    text-align: center;
	    padding: 10px;
	    background: gray;
	    color: #fff;
    }
    .survey-section-cards .card-box titl{
        display: block;
        font-size: 17px;
    }
    .bv-surveys-cards-line {
        height: 1px;
    }
    .bv-surveys-cards-line > div{
        background: $grayLight;
        height: 1px;
    }
    .bv-survey-section-cards-title{
        font-size: 17.5px;
        padding: 15px 20px 5px;
    }
    .section-description-title{
        padding-top: 21px;
    }
    .section-description{
        padding-top: 5px;
        overflow: scroll;
        height: calc(100% - 73px);
        white-space: normal;
        text-align: left;
        font-weight: 300;
    }
    .survey-bottom-section{
        width: 100%;
        height: 65px;
        position: relative;
        background: $grayLighter;
        padding: 18px;
        border-top: 1px solid $grayLight;
    }
    #idSurveyQuestionManager{
        padding: 15px 20px 20px 20px;
        height:100%;
        width:100%;
    }
    .sectionBox {
        background: #e5e5e5;
        width: 100%;
        padding: 10px;
        margin-top: 15px;
        border: 1px solid rgb(218, 216, 216);
        height:40px;
    }
    .sectionBoxLeft{
        /* width:100px; */
        /* max-width:calc(100% - 100px); */
        width:calc(100% - 100px);
        min-width:100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .sectionBoxRight{
        /* width:calc(100% - 100px); */
        overflow:hidden;

    }
    .questionBox {
        padding: 10px;
        border: 1px solid rgb(218, 216, 216);
        border-top: 0;
       /*  margin-top: 15px; */
       /*  margin-left:20px;
        width:calc(100% - 20px); */
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor:pointer;
    }
    .sectionQuestionLeft{
        /* max-width:calc(100% - 100px); */
        width:calc(100% - 100px);
        min-width:100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-height: 20px;
    }
    .sectionQuestionRight{
        overflow:hidden;
    }
    .survey-drop-down-arrow {
        height: 13px;
        width: 13px;
        position: relative;
        fill: rgb(0, 0, 0);
        margin: 0px 0px 0 0px;
        cursor: pointer;
        display: inline;
        transition: 200ms;
        -webkit-transition: 200ms;
        padding-left: 3px;
        padding-top: 1px;
    }
    .survey-drop-down-arrow.opened{
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        transition: 200ms;
        margin-top: 3px;
    }
    /* .sectionBoxRight{
        display: inline;
    } */
    .bv-show-remove-btn-surveys{
        display: table-cell;
        cursor: pointer;
    }

    .bv-show-remove-btn-surveys:hover .btn-remove-surveySection {
        display:inherit;
    }

    /* .bv-remove-choice-btn {
        margin-left: -26px;
        display: none;
        position: absolute;
        cursor: pointer;
        margin-top: 6px;
    } */
    .btn-remove-surveySection {
        width: 21px;
        float: right;
        display: inline;
        cursor:pointer;
        display:none;
    }


    .bv-survey-question-details-option-section1 {width: 100%; height: calc(100% - 50px);}
    .bv-survey-question-details-option-section2 {width: 100%; height: 50px;padding-top:20px;}
    .addSectionBtn{
        cursor:pointer;
    }

    survey-question-div,
    survey-section-div
    {
        padding:0;
        overflow:hidden;
        width:100%;
        height:100%;
         transition: height 300ms;
        -webkit-transition: height 300ms;
        display: block;
    }
    survey-section-div .bv-survey-section-details,
    survey-question-div #questionCreateDiv{
        height: 100%;
        border: 2px solid #2273A6;
        padding: 15px;
    }

    /* .opened survey-question-div,
    .opened survey-section-div
    {
        height:300px;
    } */
    .sectionQuestionsArea{
        overflow: hidden;
         transition: height 300ms;
        -webkit-transition: height 300ms;
        height: 0;
    }
    .sectionQuestionsArea.opened{
        height: inherit;
    }
    #idSurveyQuestionManager > div > .mCustomScrollbar .mCSB_container{
         transition: all 300ms;
        -webkit-transition: all 300ms;

    }

    .sectionCreateDivLeft{
        width:100%;
        height:100%;
        /* padding-right: 20px;
        border-right: 1px solid #e5e5e5; */
    }
    .sectionCreateDivRight hr{
        margin-top: 25px;
        border-color: #e5e5e5;
    }
    .survey-section-textarea {
        width: 100%;
        /* border: 0px none; */
        line-height: 1.4;
        /* padding: 2px 10px; */
        margin-bottom: 5px;
        resize: none;
        overflow: auto;
        height: 125px;
        /* outline: none; */
        }
        .survey-section-question-textarea {
        width: 100%;
        /* border: 0px none; */
        line-height: 1.4;
        /* padding: 2px 10px; */
        /* margin-bottom: 5px; */
        resize: none;
        overflow: auto;
        height: 65px;
        /* outline: none; */
        }
    .bv-survey-section-details-option-section1 {
        width: 100%;
        height: calc(100% - 30px);
        padding-bottom:20px;
    }

    .bv-survey-section-details-option-section2 {
        width: 100%;
        height: 30px;
    }
    .section-table{
        float: right;
        width: initial;
        overflow: visible;
    }
    .bv-survey-question-details-option-section2 .bv-input-btn-box {
        padding: 0px 0px 20px 20px;
    }
    .bv-survey-question-details-option-section2 .bv-input-btn-box button{
        transition: 200ms;
        -webki-transition: 200ms;
    }
    .bv-survey-question-details-option-section2 .bv-input-btn-box .meeting-notice  {
        left:0px;
    }
    .form-group-survey-more-margin {
        margin-bottom: 15px;
        }

      .dropdown-menu-survey{
        left: 9px;
        margin-left: 23px;
        min-width: 185px;
        top: 23px !important;
        border-radius: 0px;}

        .disableStyle{
        background:#d6d6d6 !important;
        }
        .inactive-Style{
        background:#d6d6d6 !important;
        }
        .disabled-Style{
        background:#d6d6d6 !important;
        }
        #idSurveySummary{
            height:100%;
            width:100%;
        }
       .summaryLeft{
            width:700px;
            float:left;
            padding:20px;
            height:100%;
            border-right: 1px solid #e5e5e5;
       }
       .summaryRight{
            width:calc(100% - 700px);
            float:left;
            padding:20px;
            height: calc(100% - 25px);
            /* border-left: 1px solid #e5e5e5; */
       }
       .summaryLeftTop{
            height:265px;
            padding:10px;
       }
       .summaryLeftBottom{
            height:calc(100% - 265px);
            padding-left:10px;
            padding-right: 10px;
            padding-bottom: 10px;
       }
       .surveyDescriptionSummary{
            padding:10px;
            /* height: 100px; */
            text-align: left;
            ul{
              list-style-type: initial;
    		  margin-left: 20px;
              
            }
       }
          .surveyDescriptionSummaryStatus{
            padding:10px;
            height: 100px;
            /* height:100%; */
            text-align: left;
       }
       .adminPreviewDIV{
            height:550px;
       }
       .statusTop{
            height:250px;
       }
       .statusBottom{
            height: calc(100% - 300px);
            padding: 20px;
       }
       .statusButtons{
        height: 50px;
        padding: 0 20px 0px 20px;
    }

       .participantsTable{
            /* position:fixed; */
            max-width: 100%;
            /* display:block; */
            /* max-height: 200px; */
            /* overflow: scroll;	 */
       }
       .participantsTableBody{
           display:block;
          /* height:200px; */
          /* overflow:auto; */
          width:100%;
       }
       .statusBottomFirst{
        width:225px;
        padding:5px 15px 5px 5px;
        border-right: 1px solid #e5e5e5;
        cursor: pointer;
       }
          .statusBottomFirstAnonymous{
        width:700px;
        padding:5px 15px 5px 5px;
        border-right: 1px solid #e5e5e5;
        cursor: pointer;
       }
        .statusBottomSecond{
          width:175px;
          padding:5px 15px 5px 15px;
          border-right: 1px solid #e5e5e5;
       }
        .statusBottomThird{
          width:300px;
          padding:5px 15px 5px 15px;
          border-right: 1px solid #e5e5e5;

       }

       .statusBottomFourth{
          width:170px;
          padding:5px 0px 5px 15px;
          padding-left:8px;
          & span{
			&.bv-heading-label{
				width: 80%;
				 padding-right: 5px;
			    white-space: nowrap;
			    overflow: hidden;
			    text-overflow: ellipsis;    
			    max-width: 170px;
			}
          }
       }
        .statusBottomFourthAnonymous{
        width:200px;
        padding:5px 0px 5px 5px;
        /* border-right: 1px solid #e5e5e5; */
        cursor: pointer;
       }
       .statusBottomFirstData{
        width:225px;
       }
          .statusBottomFirstDataAnonymous{
        width:700px;
       }
       .addSectionHeader .sectionBox{
        cursor:pointer;
       }
       .btn-remove-surveySection > svg {
        fill: #aeaeae;
    }
    .btn-remove-surveySection:hover > svg{
        fill: #231f20;
    }

    .bv-survey-openSurveys-icon-div
    {
        float:right;
    }

    .survey-status-not-submitted{
        font-style: italic;
        color: #da353b;
        font-weight: 500;
        font-size:12px;
        padding-left: 4px;
    }

    .survey-status-submitted{
        color: #96979c;   
        font-weight: normal;
        font-size: 14px;
    }

    .bv-survey-openSurvey-icon-div
    {
        width: 20%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        white-space: nowrap;
        position: relative;
        padding: 15px 15px 5px 0;

        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1px;
        color: #00bce3;

        &.not-started{
            color: #b6b7ba;    
        }

        [icon]{
            color: #00bce3;
        }

        .progress{
            display: block;
            height: 6px;
            border-radius: 3px;
            position: relative;
            top: 5px;
            background-color: #eaeaeb;
            box-shadow: none;

            .bar{
                height: 6px;
                border-radius: 3px;
                background-color: #00bce3;
            }
        }
    }

    .bv-survey-next-button
    {
        position:relative;
        left: -19%;
        top:21px;
    }
    .bv-survey-multipleChoice-list-item
    {
        padding-bottom:5px;
        padding-top:5px;
    }
    .bv-survey-multipleChoice-span
    {
        position: relative;
        top: -3px;
        margin-left: 25px;
        display: block;
    }
    .bv-survey-yesno-span
    {
        position: relative;
        top: -3px;
        margin-left: 23px;
        display: block;
    }
    .bv-survey-question-center-div
    {
        position:relative;

    }
    .bv-question-center-yesno-div
    {
        position:relative;
        margin:0 auto;
        width:25%;
        padding-top: 55px;
    }
    .bv-question-center-meetsdoesnotmeet-div
    {
        position:relative;
        margin:0 auto;
        width:35%;
        padding-top: 50px;
    }
    .bv-question-center-truefalse-div
    {
        position:relative;
        margin:0 auto;
        width:25%;
        padding-top: 50px;
    }
    .bv-survey-question-yes-item
    {
        padding-right:100px;
    }
    .bv-survey-question-yesno-label
    {
        font-size:13px;
        padding-left:5px;
    }
    .bv-survey-question-likert-scale-div
    {
        position:relative;
        width:90%;
        height: 155px;
        overflow-x: auto;
        margin:0 auto;
    }
    .bv-survey-likert-scale-itemName-span
    {
        padding-left:5px;
    }
    .bv-survey-openAnswer-div
    {
        margin:0 auto;
        padding-left:10%;
        padding-right:10%;
    }
    .bv-question-detail-div
    {
        height: 150px;
        overflow-x: auto;
        width: 629px;
        margin: 0 auto;
        padding-top:10px;
    }
    .bv-question-detail-div2
    {
        height:100%;
    }
    .bv-question-bottom-footer
    {
        position: absolute;
        right: 20px;
        bottom: -6px;
    }
    .bv-question-bottom-line
    {
        position:relative;
        top:8px;
        padding-bottom:20px;
    }
    .bv-section-bottom-footer
    {
        /* position: absolute;
        bottom: 22px; */
    }
    .bottom-overview-footer
    {
        /* position: absolute;
        bottom: 40px;
        width: 93%;
        padding-left:10px; */
        /* padding-top:15px; */
        padding-top:15px;
    }
    .bottom-overview-footer .bottom-overview-footer-hr
    {
        width:100%;
        margin:0;
        padding-bottom: 5px;
        border-top: 1px solid #D0C9C9;
    }
    .bv-section-bottom-right-arrow
    {
        width: 17px;
        position: absolute;
        margin-left: 70px;
        margin-top: 5px;
        cursor:pointer;
    }
    .bv-question-comments-icon
    {
        fill:#2574A7;
        width:20px;
        height:20px;
    }

    .progressValueList{
        padding-left:215px;
        color:#808080 !important;
    }
    .progress-bar-openSurvey-box .progressValue0{
        position:absolute;
        left:50%;
        color:#808080 !important;
    }

    .progressPaddingPreview {
        padding-left:300px;
        color: #808080 !important;
    }
    .signatureModal-signature-outer {
        /* width: calc(100% - 20px); */
        height: 90px;
        /*background: #AC2738;*/
        /* margin: 0px 0px 7px 0px; */
        position: absolute;
        bottom: 55px;
        margin-left:15px;
        width:83%;
    }

    .signatureModal-signature-inside {
        width: 80%;
        height: 80px;
        background: #FFFFFF;
        margin: 5px 1% 5px 18%;
        position: absolute;
        text-align: center;
    }

    .signatureModal-signature-image {
        height: 60px;
    }

    .signatureModal-signature-meta {
      position: absolute;
      bottom: 5px;
      width: calc(100% - 83px);
      right: 15px;
    }
    .signatureModal-pen {
     /*  width: 16%; */
      height: 75px;
      float: left;
      margin: 10px 0 0 1%;
    }
    .bv-signature-dialog .modal-dialog{
        width: 400px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50.1%) !important;
        -webkit-transform: translate(-50%,-50.1%) !important;
        -ms-transform:  translate(-50%,-50.1%) !important;
        margin: 0;
    }
    .signatureModal-sign-here-tooltip {
      position: absolute;
      left: 200px;
      top: 17px;
    }
    .userPreviewTop{
        height:200px;
    }
    .userPreviewBottom{
        height:calc(100% - 220px);
    }
    .userPreviewTopReport{
        height:200px;
    }
    .userPreviewBottomReport{
        height:calc(100% - 290px);
        padding:0px 20px 20px 20px;
    }
    .userPreviewTopLeft{
        width:450px;
        padding-top: 10px;
        height:100%;
    }
    .userPreviewTopRight{
        width:calc(100% - 450px);
        height:100%;
            /* padding-top: 20px; */
    }
    .userPreviewSectionBoxLeft {
        width: calc(100% - 200px);
        min-width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .userPreviewSectionBoxRight {
        width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .userPreviewSectionBox {
        background: #e5e5e5;
        width: 100%;
        padding-top: 10px;
        min-height: 40px;
        margin-top: 15px;
        border: 1px solid rgb(218, 216, 216);
    }
    .userPreviewSectionDetailsLeft{
        width:427px;
    }
    .userPreviewSectionDetailsRight{
        width:calc(100% - 427px);
        height:175px;
    }
    .userPreviewQuestionDetailsLeft{
        width:500px;
    }
    .userPreviewQuestionDetailsRight{
        width:calc(100% - 500px);
        text-align: right;
    }
    .userPreviewQuestionDetailsRight:hover{
        text-decoration:underline;
        cursor:pointer;
    }
    .userPreviewResponseLeft{
        width: 427px;
        text-align: left;
        word-wrap: break-word;
    }
    .userPreviewResponseRight{
        width:calc(100% - 427px);
        cursor:pointer;
        height:175px;
    }

    .userPreviewSectionDetails{
        min-height: 100px;
        padding: 15px;
        text-align: left;
        overflow: hidden;
    }
    .userPreviewQuestionDetails{
        min-height: 40px;
        padding: 10px;
    }
    .userPreviewResponse{
        min-height: 65px;
        padding: 15px 15px 25px 15px;
        background:white;
        overflow:hidden;
    }
    .participantTableBody .progressValue0 {
        padding-left: 150px;
        color: #808080 !important;
    }
    .lockIcon{
        width: 12px;
        height: 11px;
        top: 10px;
        position: relative;
        margin-right: -12px;
    }
    .horizontalBar .bv-show-remove-btn-surveys{
        display:list-item !important;
    }
    .statusBottomFirst .bv-default-fill-color-blue-plusIcon{
        width:100%;
        height:100%;
    }
    .bv-survey-manage-surveys-modal .modal-dialog{
        width:920px;
        height:500px;
    }
    .bv-cbe-create-preview-modal .modal-dialog{
        width:920px;
        height:200px;
    }
    .manage-survey-item{
        height:50px;width:100%;
        /* border-bottom: 1px solid #DCDCDC; */
    }
    .manage-surveys-surveyDue {
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .manage-surveys-name{
        overflow:hidden;text-overflow: ellipsis;
        white-space: nowrap;
    }
    .manage-surveys-name:hover{
        text-decoration:underline;
        cursor:pointer;
    }
    #newSurveyCreation .middleBarIconSvg{
        fill: #fff;
    }
    .form-group-survey-questionAndSection-margin {
        margin-bottom: 5px;
    }
    .bv-survey-question-multipleChoice-plusIcon
    {
        padding-left:10px;
    }
    .bv-survey-greyoutLabel
    {
        opacity:0.2;
    }
    .bv-survey-label-14
    {
        font-size:14px;
    }
    .profileCountryName .bv-dropdown .dropdown-menu > li > a
    {
        font-size:14px;
         color: #000;
    }
    .bv-survey-heading-label
    {
        font-size:14px;
        font-weight: 500;
    }
    .bv-survey-section-create-attachment-img,
    .bv-survey-section-create-attachment-img img,
    .bv-survey-section-create-attachment-img > div
    {
        width:27px;
        height:34px;
    }
    .bv-survey-section-center-label-attachment
    {
        margin: 6px 0px 0px 0px;
        cursor: pointer;
    }

    .bv-survey-attachment-section-li
    {
        position: relative;
        height: 40px;
        margin-bottom: 15px;
    }
    .bv-survey-back-button-label
    {
        min-width: 100px;
        height: 28px;
        border: none;
        margin: 0px 0px 0px 7.5px;
        /* font-family: Arial; */
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 0.6px;
        line-height: 22px;
        color: #fff;
        outline: none !important;
        white-space: nowrap;
        overflow: hidden;
        text-align: end;
        padding: 0px 26px;
    }
    .bv-survey-arrow-back-create-questionManager
    {
        width: 17px;
        position: absolute;
        margin-left: 22px;
        margin-top: 5px;
    }
    .bv-survey-arrow-next-button-label
    {
        min-width: 100px;
        height: 28px;
        border: none;
        margin: 0px 0px 0px 7.5px;
        /* font-family: Arial; */
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 0.6px;
        line-height: 22px;
        color: #fff;
        outline: none !important;
        white-space: nowrap;
        overflow: hidden;
        text-align: left;
        padding: 0 29px;
    }
    .bv-survey-next-arrow-questionManager
    {
        width: 17px;
        position: absolute;
        margin-left: -37px;
        margin-top: 5px;
    }
    .bv-survey-question-back-button-label
    {
        min-width: 100px;
        height: 28px;
        border: none;
        margin: 0px 0px 0px 7.5px;
        /* font-family: Arial; */
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 0.6px;
        line-height: 22px;
        color: #fff;
        outline: none !important;
        white-space: nowrap;
        overflow: hidden;
        text-align: end;
        padding: 0 30px;

    }
    .bv-survey-question-back-arrow
    {
        width: 17px;
        position: absolute;
        margin-left: 24px;
        margin-top: 5px;
        cursor:pointer;
    }
    .bv-survey-question-next-label
    {
        min-width: 100px;
        height: 28px;
        border: none;
        margin: 0px 0px 0px 7.5px;
        /* font-family: Arial; */
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 0.6px;
        line-height: 22px;
        color: #fff;
        outline: none !important;
        white-space: nowrap;
        overflow: hidden;
        text-align: left;
        padding: 0px 30px;
    }
    .create-question-replyOptionsDiv-likertScaleon
    {
        margin-top:100px;
    }
    .create-question-typeofquestion-likertScaleon
    {
        position:relative;
        top: 0px;

    }
    .create-question-typeofquestion-likertScaleoff
    {
        position:relative;


    }

    .bv-likertscaleormultipleChoiceOn
    {
        position: relative;
        padding-bottom: 20px;
    }
    .sectionCreateDivLeft > div > .mCustomScrollbar .mCSB_container
    {
        height:400px;
    }
    .divforScroll-on
    {
        height:320px;
        width:100%;
    }
    .divforScroll-off
    {
        height:0px;
        width:100%;
    }
    .typeOfQuestionLabel
    {
        padding-right:10px;
    }
    .yesnocheckbox
    {
        margin:5px 0 20px;
        line-height: 16px;
    }
    .14pxFont .bv-survey-itemlist li
    {
        font-size:14px !important;
    }
    .survey-main-preview{
        width: 100%;
        height: 100%;
        //min-width: 1000px;
        min-height: 520px;
        position: relative;
       /*  overflow: auto; */
    }
    .survey-second-section-preview{
        width: 100%;
        height: 295px;
        overflow: hidden;
        position: relative;
        /* padding: 20px; */
    }
    .sliderHeightPreviewMode{
        height:300px;
    }

    .survey-report-section-box {
        width: 100%;
        min-width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .survey-report-top{
        padding: 20px 20px 20px 20px;
    }
    .survey-report-top-left{
        width:85%;
    }
    .survey-report-top-left > div{
        font-size:13px;
    }
    .survey-report-top-left > div >div{
        font-size:13px;
    }
    .survey-report-top-right{
        width:7%;
    }
    .surveyBarChart .nvd3 text{
        font: 400 11px Arial;
    }
    .surveyPieChart .nvd3 text{
        font: 400 11px Arial;
    }
    .surveyPieChart .nvd3-pie-chart svg{
        height:50%;
        width:50%;
    }
    .surveyBarChart .nvd3-pie-chart svg{
        height:50%;
        width:50%;
    }
    .survey-report-respondents-arrow{
        float:left;
        padding-right:5px;
        height:15px;
        width:15px;
    }
    .survey-report-horizontal-title {
      border-bottom: 8px solid #0A3F6E;
      /* margin: 5px 0px 5px 5px; */
      /* padding-left: 25px; */
      float:left;
      width:100%;
      margin-bottom: 15px;
    }
    .showHideSurveyPieChart{
        width: 20px;
        height: 20px;
        /* top: 25px;
        left: 16px; */
        cursor: pointer;
       /*  z-index: 2; */
    }
    .survey-response-sign {
      height: 80px;
    }
    .sectionNameFont{
        font-size:20px;
        font-weight:500;
    }
    .sectionAttachmentsFont {
        font-size:16px;
        font-weight:500;
    }
    .sectionCreateDivLeft .caret{
            top: 5px !important;
    }
    .survey-admin-status-remove-usr-left{
        width: 195px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
       }

    .survey-deactivated-user{
        color: #6b6d75;
        font-weight: 300;
        font-style: italic;
        }

       .survey-admin-status-remove-usr-leftAnonymous{
        width: 670px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
       }
      .survey-admin-status-remove-usr-right{
        width:30px;
        padding-top:3px;
      }
      .bv-survey-removeAttendeesDiv1 { float:left; display:inline-block;height:100%; width:55%; padding: 0px 0px 0px 0px; border-right:1px solid #E5E5E5;}
      .bv-survey-removeAttendeesDiv2 { float:right; display:inline-block;height:100%; width:45%; padding: 0px 0px 0px 0px; border-right:1px gray;}

      .bv-survey-manage-surveys-add-scroll { position: fixed;overflow: visible;height:85%; }
      .bv-survey-manage-surveys-remove-scroll { position: fixed;overflow: visible;height:85%;padding-left: 4px; }

      .bv-survey-addAttendeesDiv1 { float:left; display:inline-block;height:100%; width:55%; padding: 0px 0px 0px 0px; border-right:1px solid #E5E5E5;}
      .bv-survey-addAttendeesDiv2 { float:right; display:inline-block;height:100%; width:45%; padding: 0px 0px 0px 0px; }

      .survey-published-bar{
        background: #aeaeae;
        float: left;
        width: 6px;
        position: absolute;
        margin-top: -16px;
        height: 30px;
    }
    .bv-survey-addAttendeesDiv2-users {width:100%; height:100%;overflow-x: visible;/*overflow:hidden;*/font-weight:normal;}
    .bv-survey-addAttendeesDiv2-users {width:100%; height:100%;overflow-x: visible;/*overflow:hidden;*/font-weight:normal;}
    .bv-survey-addAttendeesDiv2-users .bv-item-list-main { padding: 0px 0px 0px 20px;}
    /**change as part of MEETX-21717*/
    .bv-survey-addAttendeesDiv2-users .bv-item-list-main > div { height:100%; width:100%; }
    .bv-survey-addAttendeesDiv2-users .bv-item-list-grid { height:414px;}
    .bv-survey-addAttendeesDiv2-users .bv-item-list-ul1 { width: 273px;height:375px;}
    .bv-survey-addAttendeesDiv2-users .bv-item-list-ul1 .bv-item-list-ul2-li-checkbox     { line-height:40px; height:40px !important; display:block; position:absolute; width:50px !important; left:216px !important; }

    .bv-survey-addAttendeesDiv2-users .bv-item-list-ul1 .bv-item-list-ul2-li-checkbox .bv-item-small-close-svg { width: 22px; height: 22px; margin: 0px 0px 10px 10px; }
    .bv-survey-attachment-section-li:hover .bv-survey-removeBtn
    {
        visibility: visible;

    }
    .bv-survey-removeBtn
    {
        opacity:0.2;
        width: 21px;
        float: right;
        display: inline;
        padding-top: 8px;
        visibility:hidden;
    }
    .bv-survey-removeBtn:hover
    {
        width: 21px;
        float: right;
        display: inline;
        padding-top: 8px;
        opacity:1;
        visibility:visible;
    }
    #idSurveyQuestionManager{
        min-width:1000px;
    }
    .surveyConsumerSectionDIVTop{
        width:100%;
        height:calc(100% - 125px);
        padding: 20px;
    }
    .surveyConsumerSectionDIVTop-preview{
        width:100%;
        height:calc(100% - 120px);
        padding: 20px;
    }
    .surveyConsumerSectionDIVBottom{
        width:100%;
        padding-top: 20px;
    }
    .surveyConsumerSectionDIVBottom-preview{
        width:100%;
        height:120px;
        padding-top: 23px;
    }
    .surveyName > svg{
        height: 10px;
    width: 11px;
    position: relative;
    fill: rgb(0, 0, 0);
    margin: 0px 0px 0 0px;
    display: none;
    cursor: pointer;
    }
    .disableEditSurveyItems {
        opacity: 0.5;
        pointer-events: none;
    }

    #idSurveyQuestionManager{
        overflow-x: hidden;
    }

    #idSurveyQuestionManager .bv-survey-question-details-options-left-panel{
        width: 100%;
        transition: width 125ms, opacity 92ms 22ms;
        -webkit-transition: width 125ms, opacity 92ms 22ms;
        height: 100%;
        opacity: 1;
    }
    #idSurveyQuestionManager.editing .bv-survey-question-details-options-left-panel{
        width: calc(50% - 10px);
    }
    #idSurveyQuestionManager.editing.first-creation .bv-survey-question-details-options-left-panel{
        width: calc(50% - 10px);
        opacity: 0;
    }

    #idSurveyQuestionManager .bv-survey-question-details-options-right-panel{
        transition: left 0ms 200ms, width 0ms 200ms, opacity 200ms, transform 200ms;
        -webkit-transition: left 0ms 200ms, width 0ms 200ms, opacity 200ms, transform 200ms;
        margin-top: 15px;
        height: calc(100% - 40px);
        position: relative;
        overflow: hidden;
        transform: translateX(120%);
        -webkit-transform: translateX(120%);
        opacity: 0;
        top: -100%;
        left: 0;
        width: 0;
    }
    #idSurveyQuestionManager.editing .bv-survey-question-details-options-right-panel{
        transition: left 0ms 10ms, width 0ms 10ms, opacity 200ms, transform 200ms;
        -webkit-transition: left 0ms 10ms, width 0ms 10ms, opacity 200ms, transform 200ms;
        transform: translateX(0);
        -webkit-transform: translateX(0);
        opacity: 1;
        width: calc(50% - 10px);
        left: calc(50% + 10px);
    }
    #idSurveyQuestionManager.editing.first-creation .bv-survey-question-details-options-right-panel{
        width: 100%;
        left: 0;
    }
    #idSurveyQuestionManager .bv-survey-question-details-options-right-panel .hidden{
        transform: translateX(110%);
        -webkit-transform: translateX(110%);
        opacity: 0;
    }

    #idSurveyQuestionManager .bv-survey-question-details-options-right-panel .survey-section-div,
    #idSurveyQuestionManager .bv-survey-question-details-options-right-panel .survey-section-div .bv-survey-section-details{
        height: 100%;
    }

    #idSurveyQuestionManager .bv-survey-question-details-options-right-panel #sectionCreateDiv,
    #idSurveyQuestionManager .bv-survey-question-details-options-right-panel #questionShowDiv,
    #idSurveyQuestionManager .bv-survey-question-details-options-right-panel #sectionShowDiv,
    #idSurveyQuestionManager .bv-survey-question-details-options-right-panel #sectionCreateDivOnClickHeader{
        transition: 300ms all;
        -webkit-transition: 300ms all;
        position: absolute;
        opacity: 0;
    }
    #idSurveyQuestionManager .bv-survey-question-details-options-right-panel #sectionCreateDiv.opened,
    #idSurveyQuestionManager .bv-survey-question-details-options-right-panel #questionShowDiv.opened,
    #idSurveyQuestionManager .bv-survey-question-details-options-right-panel #sectionShowDiv.opened,
    #idSurveyQuestionManager .bv-survey-question-details-options-right-panel #sectionCreateDivOnClickHeader.opened{
        opacity: 1;
        z-index: 1;
    }

    #idSurveyQuestionManager #questionShowDiv .create-question-typeofquestion-likertScaleoff{
        margin: 10px 0;
    }
    #idSurveyQuestionManager #questionShowDiv .typeofquestion-box{
        height: 26px;
        line-height: 24px;
        margin-bottom: 10px;
    }
    #idSurveyQuestionManager #questionShowDiv .default-title{
        height: 24px;
        text-align: right;
        line-height: 22px;
    }

    #idSurveyQuestionManager #questionShowDiv .yesnocheckbox hr{
        margin: 8px 0;
    }
    #idSurveyQuestionManager #questionShowDiv .reply-options-box{
        margin: 5px 0 20px;
    }
    #idSurveyQuestionManager #questionShowDiv .reply-options-box div{
        display: inline-block;
        margin: 0 2px 0 10px;
        top: 2px;
        position: relative;
    }
    #idSurveyQuestionManager #questionShowDiv .reply-options-box div:first-child{
        margin-left: 0px;
    }

    #idSurveyQuestionManager .bv-survey-question-details-options-left-panel .questionBox:hover #inner_circle{
        fill: #ebeef0;
    }
    #idSurveyQuestionManager .bv-survey-question-details-options-left-panel .sectionBox #inner_circle{
        fill: #e5e5e5;
    }
    #idSurveyQuestionManager .bv-survey-question-details-options-left-panel .sectionBox:hover #inner_circle{
        fill: #DEDEDE;
    }

    .inviteUserSelectionDialog.survey .modal-dialog{
        transform: translate(-50%, -47%) !important;
        -webkit-transform: translate(-50%, -47%) !important;
        -ms-transform: translate(-50%, -47%) !important;
        top: 50%;
        margin-top: 0 !important;
    }

    .survey-main{

        .bv-input-btn{
            font-size: 13px;
            font-weight: 500;
            width: 100px;
            padding: 5px 11px 6px;
            transition: none;
            -webkit-transition: none;
        }

        .survey-cards-button{
            bottom: 0;
            position: absolute;
            width: 100%;
            height: 40px;
            background: $grayLighter;
            text-align: center;
            line-height: 22px;
            font-size: 13px;
            color: $nasdaqBlue;
        }

        .bv-surveyProgress-left{
            button{
                background-color: $nasdaqBlue;
            }
        }
        .bv-surveyProgress-right{
            button{
                background-color: $green;

                &.next{
                    background-color: $nasdaqBlue;
                }
            }
        }
    }

    > .sortableItem{
        font-size: 14px;
        letter-spacing: -0.1px;
        border-top: solid 1px #eaeaeb;
        border-bottom: solid 1px #eaeaeb;
        padding-left: 43px;
        position: relative;
        list-style: none;
        z-index: 100;
    }

    .mutiyesno-response{
      span{
        font-weight: bold;
      }
    }
    
    create-new-survey-react,
    open-new-survey-react{
        .richtext{
            ul{
                list-style: disc; 
                padding-left: 40px;
                margin: 1em 0;
                ul{
	                list-style: circle;
	                ul{
		                list-style: square;
		            }
	            }
            }
        }
    }
    .cbeTypeBadge{
	    font-size: 14px;
	    font-weight: 400;
	    background: #eaeaeb;
	    border: 1px solid #eaeaeb;
	    color: #6b6d75;
	    width: 75px;
	    height: 21px;
	    text-align: center;
	    line-height: 21px;
	    border-radius: 22px;
	    box-shadow: 0 0 1px #333;
	    float:left;
    }
    .cbeType{
    	float:left;
    	width:calc(100% - 81px);
    	padding-left:10px;
    	font-size: 14px;
	    font-weight: 300;
	    color: #6b6d75;
	    display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .cbeRespondentViewFont{
    	font-size: 15px;
	    display: inline-block;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    max-width: 700px;
	    line-height: 48px;
	    float:left;
    }
    .cbeRespondentViewWrapper{
    	height: 200px;
    	width: 100%;
    	padding: 20px;
    }
    .cbeLink{
    	padding-top: 14px;
    	float: left;
   	 	padding-left: 10px;
    }
    .bv-cbe-stutas-preview-modal .modal-dialog{
        width:750px;
        height:536px;
    }
    .cbeAdminPreviewWrapper{
    	height: 536px;
    	width: 100%;
    }
    .cbeAdminPreviewWrapper .cbename
    {
    	height: 25px;
    	width: 100%; 
    	font-size:18px;
    	overflow: hidden;
    	text-overflow: ellipsis;
   		white-space: nowrap;
    }
    .cbeAdminPreviewWrapper .description
    {
    	min-height:75px;
    }
    .cbeAdminPreviewWrapper .dueDate
    {
    	min:75px;
    }
    .cbeAdminPreviewWrapper .users
    {
    	min-height:75px;
    }
    .cbeAdminPreviewWrapper .user
    {
    	height:50px;
    	width:100%;
    	padding:5px;
    	border-top: solid 1px #eaeaeb;
    }
    .cbeAdminPreviewWrapper .userTop
    {
    	height:50px;
    	width:100%;
    	padding:5px;
    }
    .cbeAdminPreviewWrapper .userTop .name
    {
    	width: calc(100% - 210px);
    	float: left;
    	height: 100%;
    	padding: 7px;
    	padding-left:13px;
    }
    .cbeAdminPreviewWrapper .userTop .response
    {
    	width: 161px;
    	float: left;
    	height: 100%;
    	padding: 7px;
    }
    .cbeAdminPreviewWrapper .userTop .image
    {
    	width: 44px;
	    height: 44px;
	    border-radius: 50%;
	    float: left;
	    padding-left:5px;
    }
    .cbeAdminPreviewWrapper .user .name
    {
    	width: calc(100% - 210px);
    	float: left;
    	height: 100%;
    	padding: 7px;
    	padding-left:13px;
    }
    .cbeAdminPreviewWrapper .user .response
    {
    	width: 161px;
    	float: left;
    	height: 100%;
    	padding: 7px;
    }
    .cbeAdminPreviewWrapper .user .image
    {
    	width: 40px;
	    height: 40px;
	    border-radius: 50%;
	    float: left;
    }
    .cbeAdminPreviewWrapper .user .labelFont{
    	font-size: 16px;
    }
    .cbeAdminPreviewWrapper .user .imageLetter{
        color: white;
	    font-weight: normal;
	    font-size: 24px;
	    letter-spacing: 1px;
	    position: absolute;
	    margin-top: 4px;
	    margin-left: 12px;
    }
    .cbeAdminPreviewWrapper .user .imageLetterWrapper{
    	float:left;
    	width: 40px;
	    height: 40px;
	    border-radius: 50%;
	    background: #9e9fa3;
    }
    .cbeAdminPreviewWrapper .bottom{
    	width:100%;
    	height:50px;
        border-top: 1px solid #eaeaeb;
    }
    .cbeAdminPreviewWrapper .top{
    	padding: 20px;
    	height: calc(100% - 51px);
    }
    .cbeAdminPreviewWrapper .bv-input-btn-red-font{
    	color: red;
    }
}


.bv-mobile.bv-surface{
    &  .surveys-top-panel{
        .top-panel{
            titl:nth-child(2){
                width: 24%;
            }
            titl:nth-child(3){
                width: 13%;
            }
            titl:nth-child(4){
                width: 23%;
            }
        }
    }  
    & .surveyDue{
        width: 13%;
    }
    & .surveyType{
        width: 24%;
    }
    & .bv-survey-openSurvey-icon-div{
        width: 23%;
    }
}